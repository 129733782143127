/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from 'react'
import { isNotEmpty } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import axios from 'axios'
import { toast } from 'react-toastify'
import { ADD_SERVICES, SERVICE_CATEGORY_DATA } from '../../../../../modules/auth/core/_requests'
import Tagify from '@yaireo/tagify'
import "@yaireo/tagify/src/tagify.scss";
type Props = {
  isUserLoading: boolean
  user: any
}

interface Option {
  id: string;
  name: string;
}
type Service = {
  id: number;
  title: string;
  key_points: string;
  price: number;
  offer: number;
  description: string;
  category_id: string;
  category_name: string;
}
const UserEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()


  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const [options, setOptions] = useState<Option[]>([]);
  const [title, setTitle] = useState(user.title ? user.title : '');
  const [keyPoints, setKeyPoints] = useState(user.key_points ? user.key_points : '');
  const [inclusive, setInclusive] = useState(user.inclusive ? user.inclusive : '');
  const [exclusive, setExclusive] = useState(user.exclusive ? user.exclusive : '');
  const [type, setType] = useState(user.type ? user.type : '');
  const [price, setPrice] = useState(user.price ? user.price : '');
  const [offer, setOffer] = useState(user.offer ? user.offer : '');
  const [checkpointsArray, setChecPointArray] = useState(user.check_points ? user.check_points : '');
  const [image, setImage] = useState<File | null>(null);
  const [previousImage, setPreviousImage] = useState(user.image ? user.image : null);
  const [category, setCategory] = useState(user.category ? user.category : '');
  const [description, setDescription] = useState(user.description ? user.description : 'hhdhhs,sdfdf,sdfsdfddd,erer,er34');
  const [status, setStatus] = useState('')
  const checkpoints: any = useRef(null);
  const keypoints: any = useRef(null);

  new Tagify(checkpoints.current);
  new Tagify(keypoints.current);
  // const resetForm = () => {
  //   setTitle('');
  //   setKeyPoints('');
  //   setImage(null);
  //   setInclusive('');
  //   setExclusive('');
  //   setType('Select');
  //   setPrice('');
  //   setOffer('');
  //   setCategory('');
  //   setDescription('');
  // };

  // const [Updatetitle, setUpdateTitle] = useState('');
  // const [UpdatekeyPoints, setUpdateKeyPoints] = useState('');
  // const [Updateinclusive, setUpdateInclusive] = useState('');
  // const [Updateexclusive, setUpdateExclusive] = useState('');
  // const [Updateprice, setUpdatePrice] = useState('');
  // const [Updateoffer, setUpdateOffer] = useState('');
  // const [Updatedescription, setUpdateDescription] = useState('');

  // const handleUpdateSubmit = (event: any) => {
  //   event.preventDefault();

  //   const updatedData = {
  //     title: Updatetitle,
  //     key_points: UpdatekeyPoints,
  //     inclusive: Updateinclusive,
  //     exclusive: Updateexclusive,
  //     price: Updateprice,
  //     offer: Updateoffer,
  //     description: Updatedescription,
  //   };

  //   axios
  //     .put(UPDATE_SERVICES, updatedData)
  //     .then((response) => {
  //       toast.success('Data updated successfully:', response.data);
  //     })
  //     .catch((error) => {
  //       toast.error('Error updating data:', error);
  //     });
  // };

  useEffect(() => {
    // Fetch data from servicecategory table and render it as a select option dropdown
    axios.get(SERVICE_CATEGORY_DATA)
      .then(response => {
        setOptions(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {

      const formData = new FormData();
      formData.append('title', title);
      formData.append('key_points', keyPoints);

      if (image) {
        formData.append('image', image);
      }

      formData.append('inclusive', inclusive);
      formData.append('exclusive', exclusive);
      formData.append('type', type);
      formData.append('check_points', checkpointsArray);
      formData.append('price', price);
      formData.append('offer', offer);
      formData.append('category', category);
      formData.append('description', description);
      if (isNotEmpty(user.id)) {
        formData.append('id', `${user.id}`);
      }

      const response = await axios.post(ADD_SERVICES, formData);

      if (response.data.status == 1) {
        toast.success(response.data.message);
        cancel(true)
      }
      else {
        toast.error(response.data.message);
      }

    } catch (error) {

    }
  };

  // const [service, setServices] = useState([]);


  // const fetchServices = async () => {
  //   try {
  //     const url = GET_SERVICES;
  //     const response = await axios.get(url);
  //     setServices(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // useEffect(() => {

  //   fetchServices();
  // }, []);

  // const handleTypeChange = (event: any) => {
  //   setType(event.target.value);
  // };




  return (
    <>
      <form onSubmit={handleSubmit} className='row justify-content-center ' method="POST" encType="multipart/form-data">

        <div className="form-group mb-4">
          <label className='fw-semibold required fs-6' htmlFor='title'>Title: </label>

          <input type="text" id='title' className='form-control' value={title} onChange={(event) => setTitle(event.target.value)} required />

        </div>

        <div className="form-group mb-4">
          <label className='fw-semibold fs-6' htmlFor='image'>
            Image:
          </label>
          <input id='image' type="file" className='form-control' onChange={(event) => setImage(event.target.files?.[0] || null)} />
          {previousImage && <img src={previousImage} className='rounded' height={100} alt="" />}
        </div>
        <div className="form-group mb-4">
          <label className='fw-semibold fs-6'>
            Key Points:
          </label>
          <input ref={keypoints} type="text" className='form-control' value={keyPoints} onChange={(event) => setKeyPoints(event.target.value)} />
        </div>

        <div className="form-group mb-4">
          <label className='fw-semibold fs-6'>
            Inclusion:
          </label>
          <input type="text" className='form-control' value={inclusive} onChange={(event) => setInclusive(event.target.value)} />
        </div>

        <div className="form-group mb-4">
          <label className='fw-semibold fs-6'>
            Exclusion:
          </label>
          <input type="text" className='form-control' value={exclusive} onChange={(event) => setExclusive(event.target.value)} />
        </div>

        <div className="form-group mb-4 ">
          <label className='fw-semibold required fs-6'>
            Type Select:
          </label>
          <select className="form-control" name="" id="" value={type} onChange={(event) => setType(event.target.value)}>
            <option value="Select">Select any type</option>
            <option value="Service">Service</option>
            <option value="Product">Product</option>
          </select>
        </div>

        <div className="form-group mb-4 col-sm-6">
          <label className='fw-semibold fs-6'>
            Price :
          </label>
          <input type="number" className='form-control' value={price} onChange={(event) => setPrice(event.target.value)} required />
        </div>
        <div className="form-group mb-4 col-sm-6">
          <label className='fw-semibold fs-6'>
            Offered Price :
          </label>
          <input type="number" className='form-control' value={offer} onChange={(event) => setOffer(event.target.value)} required />
        </div>
        <div className="form-group mb-4">
          <label className='fw-semibold fs-6'>
            Parent Category:
          </label>
          <select className='form-control' value={category} onChange={(event) => setCategory(event.target.value)} required>
            <option className='text-center ' value="">--Select any category( *if required* )--</option>
            {options.map(option => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))}
          </select>
        </div>

        <div className="form-group mb-4">
          <label className='fw-semibold fs-6' htmlFor="exampleFormControlTextarea1">Example textarea</label>
          <textarea value={description} onChange={(event) => setDescription(event.target.value)} className="form-control" id="exampleFormControlTextarea1" rows={3}></textarea>
        </div>
        <div className="form-group mb-4">
          <label className='fw-semibold fs-6' htmlFor="checkpoints">Check Points</label>
          <textarea ref={checkpoints} onChange={(event) => setChecPointArray(event.target.value)} className="form-control" id="checkpoints" value={checkpointsArray} rows={3}></textarea>
        </div>
        <button type="submit" className='mt-4 btn btn-primary col-5'>Submit</button>
      </form>
      {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
      {/* <ToastContainer /> */}
    </>
  )
}

export { UserEditModalForm }
