/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { User } from '../core/_models'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import axios from 'axios'
import { toast } from 'react-toastify'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import { useAuth } from '../../../../../modules/auth'
import clsx from 'clsx'

type Props = {
  isUserLoading: boolean
  user: User
}


const UserEditModalForm: FC<Props> = ({ user }) => {

  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [permissions, setPermission] = useState<any>([]);
  
  const [permissionloaded, setPermissionLoaded] = useState(true);
  const getPermissions = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-permission`);
    setPermission(response.data);
   
  }

  useEffect(() => {
    if (permissionloaded) {
      getPermissions();
      setPermissionLoaded(false);
    }

  }, [permissionloaded]);



  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      
      refetch()
    }
    setItemIdForUpdate(undefined)
  }


  const formik = useFormik({
    initialValues: {
      role: user && user.id ? user.id : '',
      role_name: user && user.id ? user.name : '',
      permissions: user && user.id ? user.permissions.map((obj : any) => obj.id) : [],
      new_permission: ''
    },
    validationSchema: Yup.object({
      
      role_name: Yup.string()
        .required('Role name required'),
    }),
    onSubmit: async (values) => {

      const token = localStorage.getItem("token");

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/save-permission`,
        { ...values },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.status == 1) {
        toast.success(response.data.message);
        cancel(true);
      } else {
        toast.error(response.data.message);
      }
    },
  });

   const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleSelectAllChange = (e : any) => {
    const { checked } = e.target;
    if(checked)
      {
         setSelectAllChecked(checked);
        formik.setFieldValue('permissions',permissions.map((obj :any) => obj.id));
      }
      else
      {
        formik.setFieldValue('permissions',[]);
        setSelectAllChecked(checked);
      }
  };

  const handleCheckboxChange = (e : any,permissionItemcheck : any) => {
    const { checked } = e.target;
     if(checked)
      {
         
           formik.setFieldValue('permissions',[...formik.values.permissions,permissionItemcheck.id]);
      }
      else
      {
           formik.setFieldValue('permissions',formik.values.permissions.filter((filterItem : any) => filterItem !== permissionItemcheck.id));
      }
  };



  return (
    <>
      {/*begin::Form*/}
      <form id="kt_modal_add_role_form" onSubmit={formik.handleSubmit} className="form" action="#">
        {/*begin::Scroll*/}
        <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_role_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_role_header" data-kt-scroll-wrappers="#kt_modal_add_role_scroll" data-kt-scroll-offset="300px">
          {/*begin::Input group*/}
          <div className="fv-row mb-10">
            {/*begin::Label*/}
            <label className="fs-5 fw-bold form-label mb-2">
              <span className="required">Role name</span>
            </label>
            {/*end::Label*/}
            {/*begin::Input*/}
            <input  
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.role_name && formik.errors.role_name},
            {
              'is-valid': formik.touched.role_name && !formik.errors.role_name,
            }
          )} onChange={(e) => {
              if(user && user.id)
                formik.setFieldValue('role_name', e.target.value)
              else
              {
                   formik.setFieldValue('role', e.target.value);
                   formik.setFieldValue('role_name', e.target.value);
              }
               
            }} placeholder="Enter a role name" value={formik.values.role_name} name="role_name" />
             {formik.touched.role_name && formik.errors.role_name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.role_name}</span>
            </div>
          </div>
        )}
          </div>

          {/*end::Input group*/}
          {/*begin::Permissions*/}
          <div className="fv-row">
            {/*begin::Label*/}
            <label className="fs-5 fw-bold form-label mb-2">Role Permissions</label>
            {/*end::Label*/}
            {/*begin::Table wrapper*/}

            {/*begin::Table*/}

               {formik.touched.permissions && formik.errors.permissions && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{`${formik.errors.permissions}`}</span>
            </div>
          </div>
        )}
            <div className="row">
              <div className="col-sm-12">
                <label className="form-check form-check-custom form-check-solid me-9">
                  <input className="form-check-input" checked={selectAllChecked} onChange={(e)=>handleSelectAllChange(e)} type="checkbox" id="kt_roles_select_all" />
                  <span className="form-check-label text-gray-800">
                    Select all
                  </span>
                </label>
              </div>
            </div>


            <div className="row my-3">
              {/*begin::Checkbox*/}


              {permissions && permissions.map((permItem: any, indx: number) => {
                return (
                  <div key={indx} className="col-sm-3 my-3">
                    <label className="form-check form-check-custom form-check-solid me-9">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked = {formik.values.permissions?.some((permitemcheck : any) => permitemcheck === permItem.id) }
                        onChange={(e) => handleCheckboxChange(e,permItem) }
                        id={`kt_roles_select_all${indx}`}
                        value={permItem.name}
                      />
                      <span className="form-check-label text-gray-800">
                        {permItem.name}
                      </span>
                    </label>
                  </div>
                )
              })}



            </div>



          </div>

        </div>

        <div className="text-center pt-15">
          <button type="reset" className="btn btn-light me-3" data-kt-roles-modal-action="cancel">
            Discard
          </button>
          <button type="submit" className="btn btn-primary" data-kt-roles-modal-action="submit">
            <span className="indicator-label">
              Submit
            </span>
            <span className="indicator-progress">
              Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          </button>
        </div>
        {/*end::Actions*/}
      </form>
      {/*end::Form*/}


      {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
    </>
  )
}

export { UserEditModalForm }
