/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import axios from 'axios';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { OrderEstimateSave } from '../../../modules/auth/core/_requests';
import ServiceModel from '../modals/ServiceModel'
import { Modal } from 'react-bootstrap';
import { useAuth } from '../../../modules/auth';

const OrderEstimate = ({ id, getOrderEstimate, user_id }: any) => {

    const [estimates, setEstimates] = useState<any>([]);
    const [showmodel, setShowModel] = useState(false);
    const { currentUser } = useAuth();
    const [order, setOrder] = useState<any>();
    const [orderloaded, setorderloaded] = useState(true);
    const [taxmodel, settaxmodel] = useState(false);
    const [singleestimate, setsingleestimate] = useState<any>();
    const [disabledSubmit, setdisabledSubmit] = useState(false);
  
    // const [startDate, setStartDate] = useState(new Date());

    // const [total,setTotal] = useState(0);

    const formik = useFormik({
        initialValues: {
            estimates_array: [],
            order_id: id,
            user_id: user_id
        },
        // validationSchema: Yup.object({
        //   brand: Yup.string()
        //     .required("Select brand"),
        //   service: Yup.string()
        //     .required("Select service"),
        //   model: Yup.string()
        //     .required("Select model"),
        //   price_type: Yup.string()
        //     .required("Select price type"),
        //   car_type: Yup.string()
        //     .required("Enter car type"),
        //   fuel_type: Yup.string()
        //     .required("Enter car fuel type"),
        //   percentage: Yup.string()
        //     .required("Enter car percentage"),

        // }),
        onSubmit: async (values) => {
            const token = localStorage.getItem("token");
            setdisabledSubmit(true);
            const response = await axios.post(OrderEstimateSave,
                { ...values },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data.status == 1) {
                toast.success("Estimate Updated");
                setdisabledSubmit(false)
                getOrderEstimate(values.estimates_array);
            } else {
                toast.error(response.data.message);
                setdisabledSubmit(false)
            }
        }
    });

    useEffect(() => {

        if (orderloaded) {
            getOrder();
            setorderloaded(false);
        }

    }, [])


    const getOrder = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-order-estimate/${id}`);

        formik.setFieldValue('estimates_array', response.data.map((est: any) => {
            return {
                ...est,
                total_amount: est.discount != null && est.discount > 0 ? ((parseFloat(est.rate) * parseInt(est.quantity)) - ((parseFloat(est.rate) * parseInt(est.quantity)) * est.discount/100 )):(parseFloat(est.rate) * parseInt(est.quantity)),
                price: est.rate,
                id: est.product_id
            }
        }));
        getOrderEstimate(response.data.map((est: any) => {
            return {
                ...est,
                total_amount: est.discount != null && est.discount > 0 ? ((parseFloat(est.rate) * parseInt(est.quantity)) - ((parseFloat(est.rate) * parseInt(est.quantity)) * est.discount/100 )):(parseFloat(est.rate) * parseInt(est.quantity)),
                price: est.rate,
                id: est.product_id
            }
        }));
    }

    const isJsonString = (str:string) => {
    try {

        if(str == null)
            return false;
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
    const handleaddservice = (service: any) => {

        // console.log('type of the service key points',service.key_points == null)
        const found = formik.values.estimates_array.some((el: any) => el.product_id === service.id);
        if (!found) {
            service.quantity = 1;
            service.total_amount = parseFloat(service.price) * parseInt(service.quantity);
            // setEstimates([...estimates, service]);
            formik.setFieldValue('estimates_array', [...formik.values.estimates_array, {

                order_id: id,
                product_id: service.id,
                quantity: service.quantity,
                workdone: service.workdone,
                brand_name: undefined,
                discount: service.discount,
                discount_type: service.discount_type,
                tax: service.tax,
                title: service.title,
                total_amount: service.total_amount,
                price: service.price,
                rate: service.price,
                check_points: service.check_points ? JSON.parse(service.check_points) : [],
                exclusive: service.exclusive ? service.exclusive : [],
                key_points: isJsonString(service.key_points) == true ? JSON.parse(service.key_points).map(({ value } : any) => value).join(' | ') : service.key_points

            }])
        }
        else {

            formik.setFieldValue('estimates_array', formik.values.estimates_array.map((shape1: any) => {
                if (shape1.product_id != service.id) {
                    // No change
                    return shape1;
                } else {
                    // Return a new circle 50px below
                    return {
                        ...shape1,
                        quantity: parseInt(shape1.quantity) + 1,
                        total_amount: parseFloat(shape1.total_amount) + parseFloat(shape1.price),

                    };
                }
            }));
        }
    }


    const handlequantityupdate = (service: any, value: any) => {
        formik.setFieldValue('estimates_array', formik.values.estimates_array.map((shape1: any) => {
            console.log('quantity',shape1.discount != '');
            value = value ? parseFloat(value) : 0;
            if (shape1.product_id != service) {
                // No change
                return shape1;
            } else {
                // Return a new circle 50px below
                return {
                    ...shape1,
                    quantity: value != 0 ? value : 1,
                    total_amount: (parseFloat(shape1.price) * value) - ((value * parseFloat(shape1.price) * ((shape1.discount != '' && shape1.discount != null ? parseFloat(shape1.discount) : 0)/100))),
                    // order_id: id,
                    // product_id: service.id,

                    // brand_name : undefined,
                    // discount: shape1.discount,
                    // discount_type: shape1.discount_type,
                    // tax: shape1.tax,
                    // title: shape1.title,

                    // price: service.price,
                    // rate: service.price
                };
            }
        }));

        setsingleestimate({ ...singleestimate, quantity: value, total_amount: (parseFloat(singleestimate.price) * value) - ((value * parseFloat(singleestimate.price) * ((singleestimate.discount != '' ? parseFloat(singleestimate.discount) : 0)/100))) });
    }


    const handlebrandupdate = (service: any, value: any) => {
        formik.setFieldValue('estimates_array', formik.values.estimates_array.map((shape1: any) => {

            if (shape1.product_id != service) {
                // No change
                return shape1;
            } else {
                // Return a new circle 50px below
                return {
                    ...shape1,
                    brand_name: value,
                };
            }
        }));

        setsingleestimate({ ...singleestimate, brand_name: value });
    }



    const handleremoveservice = (serviceId: any) => {
        // const s = estimates.filter((item: any, index: any) => item.id !== serviceId);
        const p = formik.values.estimates_array.filter((item1: any, index1: any) => item1.product_id !== serviceId);
        // setEstimates(s);
        formik.setFieldValue('estimates_array', p);

    }

    const handlepriceupdate = (service: any, value: any) => {
        formik.setFieldValue('estimates_array', formik.values.estimates_array.map((shape1: any) => {

            if (shape1.product_id != service) {
                // No change
                return shape1;
            } else {
                // Return a new circle 50px below
                return {
                    ...shape1,
                    price: value,
                    total_amount: (parseFloat(shape1.quantity) * parseFloat(value)) - ((parseFloat(shape1.quantity) * parseFloat(value) * ((shape1.discount != '' ? parseFloat(shape1.discount) : 0)/100))),

                };
            }
        }));

        setsingleestimate({ ...singleestimate, price: value, total_amount: (parseFloat(singleestimate.quantity) * parseFloat(value)) - ((parseFloat(singleestimate.quantity) * parseFloat(value)) * ((singleestimate.discount != '' ? parseFloat(singleestimate.discount) : 0)/100)) });
    }

    const handlediscountupdate = (service: any, value: any) => {
       
        formik.setFieldValue('estimates_array', formik.values.estimates_array.map((shape1: any) => {

            if (shape1.product_id != service) {
                // No change
                return shape1;
            } else {
                // Return a new circle 50px below
                return {
                    ...shape1,
                    discount: value != '' ? parseFloat(value) : 0,
                    total_amount: (parseFloat(shape1.quantity) * parseFloat(shape1.price)) - ((parseFloat(shape1.quantity) * parseFloat(shape1.price)) * ((value != '' ? parseFloat(value) : 0)/100)),

                };
            }
        }));

        setsingleestimate({ ...singleestimate, discount: value != '' ? parseFloat(value) : 0, total_amount: (parseFloat(singleestimate.quantity) * parseFloat(singleestimate.price)) - ((parseFloat(singleestimate.quantity) * parseFloat(singleestimate.price)) * ((value != '' ? parseFloat(value) : 0)/100))});
        console.log('single estimate',singleestimate);
    }


    const handleGst = (estimatecard: any) => {
        // alert("hello how are you");

        setsingleestimate(estimatecard);
        settaxmodel(true);
    }
    function scrollToElementAbove(elementId: string) {
        const element = document.getElementById(elementId);
        if (element) {
            const offset = -100; // Adjust this value according to your preference
            const elementTop = element.getBoundingClientRect().top;
            window.scrollTo({
                top: window.pageYOffset + elementTop + offset,
                behavior: 'smooth'
            });
        } else {
            console.log(`Element with id '${elementId}' not found`);
        }
    }

    const handleScrollError = () => {
        const errorKeys = Object.keys(formik.errors);
        if (errorKeys.length > 0) {
            const firstErrorKey = errorKeys[0];
            scrollToElementAbove(firstErrorKey);
        }
    }


    return (
        <>
            <div className='px-3'>
                <div className="row">
                    <div className="col-sm-6">
                        {currentUser?.permissions.find((item: any) => item.name === "update_orders") ?
                            <><button className='btn btn-dark btn-sm' data-bs-toggle="modal" data-bs-target="#exampleModal"> <i className="bi bi-plus-circle-fill"></i> Add Parts / Services / Packages </button> &nbsp;</> : null}
                        <button className='btn btn-danger btn-sm'>  <i className="bi bi-plus-circle-fill"></i> Add Miles Membership</button>
                        <ServiceModel totaladded={formik.values.estimates_array} handleaddservice={handleaddservice} />
                    </div>
                </div>

                <form className='form' onSubmit={formik.handleSubmit} noValidate>
                    <div className="row" style={{ overflow: 'auto', height: '400px' }}>
                        {formik.values.estimates_array && formik.values.estimates_array.map((estimatecard: any, key: any) => (
                            <div key={key} className="col-sm-6">
                                {/* */}
                                <div className="card my-5 p-0 rounded-0" style={{ cursor: 'pointer' }}>
                                    <div className="card-header ps-5">
                                        <div className="card-title">
                                            <i className="bi bi-gear" style={{ fontSize: '28px', fontWeight: '900' }}></i> &nbsp;<h3> {estimatecard.title.toUpperCase()}</h3> &nbsp;&nbsp;
                                            {estimatecard.workdone && <h6>({estimatecard.workdone})</h6>}
                                        </div>

                                        <div className="card-tools">
                                            <button type="button" onClick={() => handleremoveservice(estimatecard.product_id)} className="btn btn-tool" data-card-widget="remove">
                                                <i style={{ fontSize: '28px', fontWeight: '900' }} className="fas mt-3 fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body p-1" onClick={() => handleGst(estimatecard)}>
                                        <table className='table m-0 text-center w-100'>
                                            <thead>
                                                <tr>
                                                    <td>Price Per Item</td>
                                                    {estimatecard.brand_name && (
                                                        <td>Brand</td>
                                                    )}
                                                    <td>Quantity</td>
                                                    <td>Total</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{parseFloat(estimatecard.price).toLocaleString('en-IN', {
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}</td>
                                                    {estimatecard.brand_name && (
                                                        <td>{estimatecard.brand_name}</td>
                                                    )}
                                                    <td>{estimatecard.quantity}</td>
                                                    <td>{parseFloat(estimatecard.total_amount).toLocaleString('en-IN', {
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {currentUser?.permissions.find((item: any) => item.name === "update_orders") ?
                        <button type='submit' onClick={handleScrollError} disabled={disabledSubmit} className='btn btn-primary btn-sm'>
                            {disabledSubmit ? (<span>
                                Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>) : 'Update'}
                        </button> : null}
                </form>
                {currentUser?.permissions.find((item: any) => item.name === "update_orders") ?
                    <Modal
                        size="lg"
                        show={taxmodel}
                        onHide={() => settaxmodel(false)}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                {singleestimate && singleestimate?.title.toUpperCase()}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='row'>
                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="quantity">Quantity</label>
                                        <input
                                            className="form-control"
                                            id="quantity"
                                            type="text"
                                            placeholder="Enter quantity"
                                            onChange={(e) => handlequantityupdate(singleestimate && singleestimate?.product_id, e.target.value)}
                                            onBlur={formik.handleBlur}
                                            value={singleestimate && singleestimate?.quantity}
                                        />

                                    </div>
                                </div>
                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="brand">Brand</label>
                                        <input
                                            className="form-control"
                                            id="brand"
                                            type="text"
                                            placeholder="Enter brand"
                                            onChange={(e) => handlebrandupdate(singleestimate && singleestimate?.product_id, e.target.value)}
                                            onBlur={formik.handleBlur}
                                            value={singleestimate && singleestimate?.brand_name ? singleestimate?.brand_name : '' }
                                        />

                                    </div>
                                </div>
                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="price">Price</label>
                                        <input
                                            className="form-control"
                                            id="price"
                                            type="text"
                                            placeholder="Enter price"
                                            onChange={(e) => handlepriceupdate(singleestimate && singleestimate?.product_id, e.target.value)}
                                            onBlur={formik.handleBlur}
                                            value={singleestimate && singleestimate?.price}
                                        />

                                    </div>
                                </div>
                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="discount">Discount (%)</label>
                                        <input
                                            className="form-control"
                                            id="discount"
                                            type="text"
                                            placeholder="Enter discount"
                                            onChange={(e) => handlediscountupdate(singleestimate && singleestimate?.product_id, e.target.value)}
                                            onBlur={formik.handleBlur}
                                            value={singleestimate && singleestimate?.discount ? singleestimate?.discount : 0 }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="calculation"> Total </label>
                                        <input type="text" className='form-control' readOnly value={typeof singleestimate?.total_amount != 'undefined' ? `₹${singleestimate.total_amount.toFixed(2)}` : 0} />
                                    </div>
                                </div>

                                {/* <div className="col-md-4 mt-5"> 
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            className="form-control"
                                            id="email"
                                            type="text"
                                            placeholder="Enter email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                        />

                                    </div>
                                </div>

                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="contact">Contact</label>
                                        <input
                                            className="form-control"
                                            id="contact"
                                            type="text"
                                            placeholder="Enter contact"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.contact}
                                        />

                                    </div>
                                </div> */}
                            </div>
                            {singleestimate && singleestimate.check_points &&
                                (
                                    <div className="row">
                                        <h2 className='mt-3'>Check Points</h2>
                                        <hr />
                                        {singleestimate.check_points.map(({ value }: any) => (
                                            <div className="col-md-4 mt-5">
                                                <div className="form-group">
                                                    <label htmlFor="price">{value}</label>
                                                    <input
                                                        className="form-control"
                                                        id="price"
                                                        type="text"
                                                        placeholder="Enter price"
                                                        onChange={(e) => handlepriceupdate(singleestimate && singleestimate?.product_id, e.target.value)}
                                                        onBlur={formik.handleBlur}
                                                        value={singleestimate && singleestimate?.price}
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                )
                            }

                        </Modal.Body>
                        <Modal.Footer>
                            <button onClick={() => settaxmodel(false)} className='btn btn-primary'>Update</button>
                        </Modal.Footer>
                    </Modal> : null}
            </div>
        </>
    )
}

export default OrderEstimate