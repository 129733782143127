import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth';

import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
// "use client";

function fallbackRender({ error, resetErrorBoundary } :any) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong: </p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}
const App = () => {
  return (
    <ErrorBoundary
  fallbackRender={fallbackRender}
  onReset={(details) => {
    // Reset the state of your app so the error doesn't happen again
  }}
>
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
            <ToastContainer 
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
    </ErrorBoundary>
  )
}

export {App}
