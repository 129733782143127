/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import { FC, useEffect, useState } from 'react'
import { QUERIES } from '../../../../../../../_metronic/helpers'
import Select from 'react-select'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { useQueryClient } from 'react-query'

type Props = {
  brand: any
}

const OrderPickStatus: FC<Props> = ({ brand }) => {

  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const PickupOption = [
    {id:'',name:'Select Status'},
    {id:'open',name:'Link Generated'},
    {id:'closed',name:'Picked Up'},
    {id:'canceled',name:'Link Canceled'},
];
  const changeStatus = async (selectedoption: any) => {
    const remark = window.confirm('Are you sure to change pickup status of order!');
    if (remark) {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/update-pickup-by-Id`, { id: brand.id, garage_id: selectedoption });
      response.data.status == 1 ? toast.success(response.data.message) : toast.error(response.data.message);
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    }
  }

  
  return (
    <>
    {brand.pickup && brand.order_status != 'complete' ? <Select 
      onChange={(selectedOption) => {
        changeStatus(selectedOption?.value1);
      }}
      options={PickupOption.map((garage:any) => ({value1:garage.id,label:garage.name}))}
      value={brand.pickup ? { value1: brand.pickup, label: PickupOption.find((el: any) => el.id === brand.pickup)?.name } : {value1:'',label:'Not generated'} }
    /> : 
    <>
    {brand.order_status == 'complete' ? <span className='badge badge-success fs-5'>Order Completed</span> : <span className='badge badge-danger fs-5'>Order Initiated</span>}
     
    </>
    }
    
    </>
   
  )
}

export { OrderPickStatus }
