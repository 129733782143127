/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { QUERIES } from '../../../../../../../_metronic/helpers'
import { Brand } from '../../core/_models'
import Select from 'react-select'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { useQueryClient } from 'react-query'

type Props = {
  brand: Brand
}

const UserInfoLogo: FC<Props> = ({ brand }) => {

  const leadstatus = [
    { value1: 1, label: 'Initiated' },
    { value1: 2, label: 'Assigned' },
    { value1: 4, label: 'Cancelled' }
  ];
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const changeStatus = async (selectedoption: any) => {
    const remark = window.prompt('Enter the remark for change status of lead');
    if (remark) {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/update-lead-by-Id`, { id: brand.id, status: selectedoption, remark: remark });
      parseInt(response.data.status) == 1 ? toast.success(response.data.message) : toast.error(response.data.message);
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    }
  }

  
  return (
    <> 
    {brand.status == 3 || brand.status == 4 ? brand.status == 3 ? "Converted" : "Cancelled" : <Select isClearable={true}
      onChange={(selectedOption) => {
        changeStatus(selectedOption?.value1);
      }}
      options={leadstatus}
      value={{ value1: brand.status, label: leadstatus.find((el: any) => el.value1 == brand.status)?.label }}
    />}
    
    </>
   
  )
}

export { UserInfoLogo }
