import { FC } from 'react'
import * as Yup from 'yup'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { isNotEmpty } from '../../../../../../_metronic/helpers'
import { FAQ } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { UsersListLoading } from '../components/loading/UsersListLoading'
import { createUser, updateUser } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'

type Props = {
  isUserLoading: boolean
  user: FAQ
}

const editUserSchema = Yup.object().shape({

  title: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  // questions: Yup.array()
  // .of(
  //   Yup.object().shape({
  //     question: Yup.string().required('Enter question new'),
  //   })
  // )
})

const UserEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }


  const formik = useFormik({
    initialValues: { ...user },
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {

          await updateUser(values)
        } else {

          await createUser(values);
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <FormikProvider value={formik}>
        <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >

            {/* <div className='fv-row mb-7'>
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{backgroundImage: `url('${blankImg}')`}}
            >
            </div>
          </div> */}

           
            <FieldArray name="questions" >
              {(arrayHelpers) => {

                return (
                  <>
                    {formik.values.questions && formik.values.questions.map((friend: any, index) => {

                      return (
                        <div key={index}>

                          <div className="row mb-6">

                            <label className="col-lg-3 col-form-label required fw-semibold fs-6">Question {index + 1}</label>

                            <div className="col-lg-7">

                              <div className="row">

                                <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                  <textarea
                                    placeholder='Enter Question'
                                    onChange={(e) => {
                                      formik.setFieldValue(`questions[question]`, e.target.value);
                                    }
                                    }

                                    value={friend.question}
                                    // {...formik.getFieldProps(`questions[${index}]`)}
                                    rows={4}
                                    name={`questions[question]`}
                                    className={clsx(
                                      'form-control form-control-sm  mb-3 mb-lg-0',

                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting || isUserLoading}
                                  ></textarea>


                                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" /></div>

                              </div>

                            </div>

                            <label className="col-lg-3 col-form-label required fw-semibold fs-6">Answer {index + 1}</label>

                            <div className="col-lg-7">

                              <div className="row">

                                <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                  <textarea
                                    placeholder='Enter Answer'
                                    onChange={(e) => {
                                      formik.setFieldValue(`questions[answer]`, e.target.value);
                                    }
                                    }

                                    value={friend.question}
                                    // {...formik.getFieldProps(`questions[${index}]`)}
                                    rows={4}
                                    name={`questions[answer]`}
                                    className={clsx(
                                      'form-control form-control-sm  mb-3 mb-lg-0',

                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting || isUserLoading}
                                  ></textarea>


                                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" /></div>

                              </div>

                            </div>
                            <div className="col-lg-2">

                              <div className="row">
                                <button className='btn btn-danger btn-sm' type="button" onClick={() => arrayHelpers.remove(index)}>
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <button
                      type="button"
                      className='btn col-2 btn-primary btn-sm'
                      onClick={() => arrayHelpers.push({ question: '' })}
                    >
                      Add
                    </button>


                  </>
                )
              }}
            </FieldArray>
          </div>
          {/* end::Scroll */}

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting || isUserLoading}
            >
              Discard
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Submit</span>
              {(formik.isSubmitting || isUserLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </form>
        {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
      </FormikProvider>
    </>
  )
}

export { UserEditModalForm }
