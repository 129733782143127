/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import clsx from 'clsx'
import {FC} from 'react'
import {Brand} from '../../core/_models'

type Props = {
  brand: Brand
}

const UserInfoLogo: FC<Props> = ({brand}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {brand.logo ? (
          <div className='symbol-label'>
            <img src={`${brand.logo}`} alt={brand.name} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-success`,
              `text-danger`
            )}
          >
            No Image
          </div>
        )}
      </a>
    </div>
  </div>
)

export {UserInfoLogo}
