/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../_metronic/layout/core'
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { GET_BRAND_NAME, GET_MODEL_NAME_BY_BRAND, GET_VARIENT_NAME_BY_MODEL } from '../modules/auth/core/_requests';
import React from 'react'
import Select from 'react-select'
import { ListViewProvider } from './leads/leads-management/users-list/core/ListViewProvider';
import { QueryRequestProvider } from './leads/leads-management/users-list/core/QueryRequestProvider';
import { QueryResponseProvider } from './leads/leads-management/users-list/core/QueryResponseProvider';
import { isNotEmpty } from '../../_metronic/helpers';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]
const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const LeadGenerationForm1 = () => {

  const [lead, setLead] = useState<any>();
  const [leadloaded, setLeadloaded] = useState(false);
  const [brands, setBrands] = useState([]);

  const fetchBrands = async () => {
    try {
      const response = await axios.get(GET_BRAND_NAME)
      setBrands(response.data)
      console.log('brand from brand',response);
    } catch (error) {
      console.error(error)
    }
  };

  const [models, setModels] = useState([]);

  const fetchModels = async (id: any) => {
    try {
      const response = await axios.get(`${GET_MODEL_NAME_BY_BRAND}/${id}`);
      setModels(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [cr, setcr] = useState([]);

  const fetchcr = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-cr`);
      setcr(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [garage, setgarage] = useState([]);

  const fetchgarage = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-garage`);
      setgarage(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [varient, setVarient] = useState([]);

  const fetchVarient = async (id: any) => {
    try {
      const response = await axios.get(`${GET_VARIENT_NAME_BY_MODEL}/${id}`);
      setVarient(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchBrands();
    fetchgarage();
    fetchcr();
  }, []);




  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      brand: "",
      email: "",
      contact: "",
      model: "",
      lead_type: "",
      cr: "",
      registration_no: "",
      odometer_reading: "",
      address: "",
      source: "",
      garage: "",
      car_type: "",
      price_type: "",
      petrol_price: "",
      ev_price: "",
      diesel_price: "",
      percentage: "",
    },
    validationSchema: Yup.object({

      name: Yup.string()
        .required("Enter name"),
      contact: Yup.string()
        .matches(/^[6-9][0-9]{9}$/, 'Invailid contact')
        .required('Enter contact'),
      // registration_no: Yup.string()
      //   .required("Enter registration no"),
      // odometer_reading: Yup.string()
      //   .required("Enter odometer reading"),
      address: Yup.string()
        .required("Enter address"),
     
      // service: Yup.string()
      //   .required("Select service"),
      // source: Yup.string()
      //   .required("Select source"),
      // garage: Yup.string()
      //   .required("Select garage"),
      // cr: Yup.string()
      //   .required("Select cr"),
      // lead_type: Yup.string()
      //   .required("Select lead type"),
      // model: Yup.string()
      //   .required("Select model"),
      // price_type: Yup.string()
      //   .required("Select price type"),
      // car_type: Yup.string()
      //   .required("Enter car type"),
      // fuel_type: Yup.string()
      //   .required("Enter car fuel type"),
      // percentage: Yup.string()
      //   .required("Enter car percentage"),

    }),
    onSubmit: async (values) => {
     
      const token = localStorage.getItem("token");
     
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/lead-store`,
        { ...values },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.status == 1) {
        toast.success("Lead added!");
        navigate('/admin/lead-management/leads');
      } else {
        toast.error(response.data.message);
      }
    },
  });

  useEffect(() => {
    if (leadloaded) {
      fetcholddata(formik.values.contact);
      setLeadloaded(false);
    }

  }, [formik.values.contact])

  useEffect(() => {
      if(Object.keys(formik.touched).length > 0 && Object.keys(formik.errors).length > 0)
        toast.error('Check all the fields');
  }, [formik.touched,formik.errors])

  const fetcholddata = async (contact: any) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-lead-by-contact/${contact}`)
      setLead(response.data)
      if (response.data) {
        Object.entries(response.data).map((item, index) => {

          if (index > 0) {
            formik.setFieldValue(item[0], item[1]);
          }
        });
      }
      else if (isNotEmpty(formik.values.name && lead)) {
        Object.entries(formik.values).map((item, index) => {

          if (item[0] === 'contact') {
            formik.setFieldValue(item[0], item[1]);
          }
          else {
            formik.setFieldValue(item[0], '');
          }
        });
      }

    } catch (error) {
      console.error(error)
    }
  };


  const Modelref: any = useRef(null);
  const Varientref: any = useRef(null);
  const Typeref: any = useRef(null);
  const Fuelref: any = useRef(null);
 
      
  return (
    <>

      <PageTitle breadcrumbs={usersBreadcrumbs}>Lead Generation</PageTitle>
      {/* <UsersListWrapper /> */}
      <div className="card">
        <form onSubmit={formik.handleSubmit}>
          <div className="card-header p-5">
            <h3 className='p-0 m-0'>Lead generation</h3>
          </div>

          <div className="card-body">

            <div>
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Customer details <span className='badge ms-3 badge-danger'> 1 </span></button>
                  <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Car details <span className='badge ms-3 badge-warning'> 2 </span></button>
                  <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Lead Details <span className='badge ms-3 badge-success'> 3 </span></button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div className='row'>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="contact">Contact</label>
                        <input

                          className="form-control"
                          id="contact"
                          type="text"
                          placeholder="Enter contact"
                          onChange={(e) => {
                            formik.setFieldValue('contact', e.target.value);
                            if (e.target.value.length === 10) {
                              setLeadloaded(true);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact}
                        />
                        {formik.touched.contact && formik.errors.contact ? (
                          <span className="text-danger">
                            {formik.errors.contact}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                          disabled={formik.values.contact && !formik.errors.contact ? false : true}
                          className="form-control"
                          id="name"
                          type="text"
                          placeholder="Enter name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <span className="text-danger">
                            {formik.errors.name}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          disabled={formik.values.contact && !formik.errors.contact ? false : true}
                          className="form-control"
                          id="email"
                          type="text"
                          placeholder="Enter email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <span className="text-danger">
                            {formik.errors.email}
                          </span>
                        ) : null}
                      </div>
                    </div>


                    <div className="col-md-12 mt-5">
                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <textarea
                          disabled={formik.values.contact && !formik.errors.contact ? false : true}
                          className="form-control"
                          id="address"
                          placeholder="Enter Address"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                        ></textarea>
                        {formik.touched.address && formik.errors.address ? (
                          <span className='text-danger' >
                            {formik.errors.address}
                          </span>
                        ) : null}
                      </div>
                    </div>

                  </div>

                </div>
                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <div className='row'>
                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="name">Brand</label>
                        <Select
                          isDisabled={formik.values.contact && !formik.errors.contact ? false : true}
                          isClearable={true}
                          onChange={(selectedOption) => {
                            Modelref.current.clearValue();
                            formik.setFieldValue('brand', selectedOption?.value);
                            formik.setFieldValue('model', '');
                            formik.setFieldValue('varient', '');
                            fetchModels(selectedOption?.value);
                          }
                          }
                          options={
                            brands && brands.map((itemBrand: any) => (
                              { value: itemBrand?.id, label: itemBrand?.name }
                            ))
                          } />
                        {formik.touched.brand && formik.errors.brand ? (
                          <span className="text-danger">
                            {formik.errors.brand}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="model">Model</label>
                        <Select
                          isDisabled={formik.values.contact && !formik.errors.contact ? false : true}
                          ref={Modelref}
                          isClearable={true}
                          onChange={(selectedOption) => {

                            formik.setFieldValue('model', selectedOption?.value);
                            formik.setFieldValue('varient', '');
                            fetchVarient(selectedOption?.value);
                          }
                          }
                          options={
                            models && models.map((itemModel: any) => (
                              { value: itemModel?.id, label: itemModel?.model }
                            ))
                          } />
                        {formik.touched.model && formik.errors.model ? (
                          <span className="text-danger">
                            {formik.errors.model}
                          </span>
                        ) : null}
                      </div>
                    </div>




                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="registration_no">Registration No.</label>
                        <input
                          disabled={formik.values.contact && !formik.errors.contact ? false : true}
                          className="form-control"
                          id="registration_no"
                          type="text"
                          placeholder="Enter Registration No"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.registration_no}
                        />
                        {formik.touched.registration_no && formik.errors.registration_no ? (
                          <span className='text-danger' >
                            {formik.errors.registration_no}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="odometer_reading">Odometer Reading</label>
                        <input
                          disabled={formik.values.contact && !formik.errors.contact ? false : true}
                          className="form-control"
                          id="odometer_reading"
                          type="text"
                          placeholder="Enter Odometer Reading"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.odometer_reading}
                        />
                        {formik.touched.odometer_reading && formik.errors.odometer_reading ? (
                          <span className='text-danger' >
                            {formik.errors.odometer_reading}
                          </span>
                        ) : null}
                      </div>
                    </div>

                  </div>


                </div>
                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">


                  <div className='row mb-5'>
                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="lead_type">Lead Type</label>
                        <Select
                          isDisabled={formik.values.contact && !formik.errors.contact ? false : true}
                          isClearable={true}
                          onChange={selectedOption =>
                            formik.setFieldValue('lead_type', selectedOption?.value)
                          }
                          options={[
                            { value: 'general', label: 'Genral' },
                            { value: 'insurance', label: 'Insurance' },
                            { value: 'commercial', label: 'Commercial' },
                            { value: 'accessories_retail', label: 'Accessories Retail' },
                            { value: 'sos', label: 'SOS' },
                            { value: 'luxury', label: 'Luxury' },
                          ]} />
                        {formik.touched.lead_type && formik.errors.lead_type ? (
                          <span className="text-danger">
                            {formik.errors.lead_type}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="cr">CR</label>
                        <Select
                          isDisabled={formik.values.contact && !formik.errors.contact ? false : true}
                          isClearable={true}
                          onChange={(selectedOption) => {
                            formik.setFieldValue('cr', selectedOption?.value);
                          }
                          }
                          options={
                            cr && cr.map((crItem: any) => (
                              { value: crItem?.id, label: crItem?.name }
                            ))
                          } />
                        {formik.touched.cr && formik.errors.cr ? (
                          <span className="text-danger">
                            {formik.errors.cr}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="garage">Garage</label>
                        <Select
                          isDisabled={formik.values.contact && !formik.errors.contact ? false : true}
                          isClearable={true}
                          onChange={(selectedOption) => {
                            formik.setFieldValue('garage', selectedOption?.value);
                          }
                          }
                          options={
                            garage && garage.map((garageItem: any) => (
                              { value: garageItem?.id, label: garageItem?.name }
                            ))
                          } />
                        {formik.touched.garage && formik.errors.garage ? (
                          <span className="text-danger">
                            {formik.errors.garage}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* </div> */}
                    {/* <div className="row"> */}
                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="source">Source</label>
                        <Select
                          isDisabled={formik.values.contact && !formik.errors.contact ? false : true}
                          onChange={(selectedOption) => {
                            formik.setFieldValue('source', selectedOption?.value);
                          }
                          }
                          options={[
                            { value: 'refferal_client', label: 'Refferal Client' },
                            { value: 'organic', label: 'Organic' },
                            { value: 'sms', label: 'SMS' },
                            { value: 'repeat', label: 'Repeat' },
                            { value: 'b2b', label: 'B2B' },
                            { value: 'insurance_agent', label: 'Insurance Agent' },
                            { value: 'accessories', label: 'Accessories' },
                            { value: 'sos', label: 'SOS' },
                            { value: 'flagship', label: 'Flagship' },
                            { value: 'agent_app', label: 'Agent App' },
                          ]} />
                        {formik.touched.source && formik.errors.source ? (
                          <span className="text-danger">
                            {formik.errors.source}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                     <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </div>


          </div>
         
        </form>
      </div>
      {/* <ToastContainer /> */}
    </>
  )
}


const LeadGenerationForm = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <LeadGenerationForm1 />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { LeadGenerationForm }

