// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import {Column} from 'react-table'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
 
const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Title' className='min-w-125px' />,
    id: 'title',
    accessor:'title',
    Cell: ({...props}) => {return props.data[props.row.index].title},
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='banner' className='min-w-125px' />,
    id: 'banner',
    accessor:'banner',
    Cell: ({...props}) => {
      return props.data[props.row.index].banner != null ? <img src={props.data[props.row.index].banner} height={100} /> : '';
    },
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Category' className='min-w-125px' />,
    id: 'category',
    accessor:'category',
    Cell: ({...props}) => {
      return props.data[props.row.index].category != null ? props.data[props.row.index].category.name : '-';
    },
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Keywords' className='min-w-125px' />,
    id: 'meta_keywords',
    accessor:'meta_keywords',
    Cell: ({...props}) => {

        try {
          if(props.data[props.row.index].meta_keywords == null)
            return '';

          JSON.parse(props.data[props.row.index].meta_keywords);
        } catch (error) {
          return '';
        }

      return props.data[props.row.index].meta_keywords && JSON.parse(props.data[props.row.index].meta_keywords).map(({ value,key }) => <span className='badge badge-danger me-2' key={key}> {value} </span>);
    },
  },
  
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
