import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { UsersListWrapper } from './car-management/users-list/UsersList'
import { ModelListWrapper } from './model-management/users-list/UsersList'
import { useAuth } from '../../modules/auth'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Cars Brands',
    path: '/admin/cars/brands',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const carmodelBreadcrum: Array<PageLink> = [
  {
    title: 'Cars Model',
    path: '/admin/cars/models',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CarBrand = () => {
  const {currentUser} = useAuth();
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='brands'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Brand Listing</PageTitle>
              {currentUser?.permissions.find((item: any) => item.name === "view_car_brand") ?
              <UsersListWrapper /> : <h1>You Are Not Allowed</h1> }
              {/* <TablesWidget10 className={''} /> */}
            </>
          }
        />
        <Route
          path='models'
          element={
            <>
              <PageTitle breadcrumbs={carmodelBreadcrum}>Model Listing</PageTitle>
              {currentUser?.permissions.find((item: any) => item.name === "view_car_model") ?
              <ModelListWrapper /> : <h1>You Are Not Allowed</h1> }
              {/* <TablesWidget10 className={''} /> */}
            </>
          } 
        /> 
       
      </Route>
      <Route index element={<Navigate to='/admin/cars/brands' />} />
    </Routes>
  )
}

export default CarBrand
