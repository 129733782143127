/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from 'axios'
import { Link } from 'react-router-dom'

const DashboardPage: FC = () => {

  const [count_data,setcount_data] = useState<any>();
  const [dataloaded,setdataloaded] = useState(true);
  useEffect(()=>{
      if(dataloaded)
      { 
        getCount();
        setdataloaded(false);
      }
},[dataloaded])

const getCount = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-count-dashboard`);
    setcount_data(response.data);
    
}

 
  return (

  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className="col-sm-6 col-xl-2 mb-xl-10">


        {/*begin::Card widget 2*/}
        <Link to={'/admin/manage-orders/orders'} >
        <div className="card h-lg-100">
          {/*begin::Body*/}
          <div className="card-body d-flex justify-content-between align-items-start flex-column">
            {/*begin::Icon*/}
            <div className="m-0">
              {/*begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-09-15-053640/core/html/src/media/icons/duotune/general/gen005.svg*/}
              <span className="svg-icon svg-icon-muted svg-icon-2hx"><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor" />
                <rect x={7} y={17} width={6} height={2} rx={1} fill="currentColor" />
                <rect x={7} y={12} width={10} height={2} rx={1} fill="currentColor" />
                <rect x={7} y={7} width={6} height={2} rx={1} fill="currentColor" />
                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
              </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Icon*/}
            {/*begin::Section*/}
            <div className="d-flex flex-column my-7">
              {/*begin::Number*/}
              <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{count_data?.order_count}</span>
              {/*end::Number*/}
              {/*begin::Follower*/}
              <div className="m-0">
                <span className="fw-semibold fs-6 text-gray-400">Invoices</span>
              </div>
              {/*end::Follower*/}
            </div>
            {/*end::Section*/}
            {/*begin::Badge*/}
            <span className="badge badge-light-success fs-base">
              {/*end::Svg Icon*/}</span>
            {/*end::Badge*/}
          </div>
          {/*end::Body*/}
        </div>
        </Link>
        {/*end::Card widget 2*/}

      </div>
      {/* begin::Col */}
      <div className="col-sm-6 col-xl-2 mb-xl-10">


        {/*begin::Card widget 2*/}
         <Link to={'/admin/manage-orders/orders'} >
        <div className="card h-lg-100">
          {/*begin::Body*/}
          <div className="card-body d-flex justify-content-between align-items-start flex-column">
            {/*begin::Icon*/}
            <div className="m-0">
              {/*begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-09-15-053640/core/html/src/media/icons/duotune/general/gen005.svg*/}
              <span className="svg-icon svg-icon-muted svg-icon-2hx"><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor" />
                <rect x={7} y={17} width={6} height={2} rx={1} fill="currentColor" />
                <rect x={7} y={12} width={10} height={2} rx={1} fill="currentColor" />
                <rect x={7} y={7} width={6} height={2} rx={1} fill="currentColor" />
                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
              </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Icon*/}
            {/*begin::Section*/}
            <div className="d-flex flex-column my-7">
              {/*begin::Number*/}
              <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{count_data?.order_count}</span>
              {/*end::Number*/}
              {/*begin::Follower*/}
              <div className="m-0">
                <span className="fw-semibold fs-6 text-gray-400">Orders</span>
              </div>
              {/*end::Follower*/}
            </div>
            {/*end::Section*/}
            {/*begin::Badge*/}
            <span className="badge badge-light-success fs-base">
              {/*end::Svg Icon*/}</span>
            {/*end::Badge*/}
          </div>
          {/*end::Body*/}
        </div>
        </Link>
        {/*end::Card widget 2*/}

      </div>
      {/* begin::Col */}
      <div className="col-sm-6 col-xl-2 mb-xl-10">


        {/*begin::Card widget 2*/}
        <Link to={'/admin/user-management/users'} >
        <div className="card h-lg-100">
          {/*begin::Body*/}
          <div className="card-body d-flex justify-content-between align-items-start flex-column">
            {/*begin::Icon*/}
            <div className="m-0">
              {/*begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-09-15-053640/core/html/src/media/icons/duotune/general/gen005.svg*/}
              <span className="svg-icon svg-icon-muted svg-icon-2hx"><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor" />
                <rect x={7} y={17} width={6} height={2} rx={1} fill="currentColor" />
                <rect x={7} y={12} width={10} height={2} rx={1} fill="currentColor" />
                <rect x={7} y={7} width={6} height={2} rx={1} fill="currentColor" />
                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
              </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Icon*/}
            {/*begin::Section*/}
            <div className="d-flex flex-column my-7">
              {/*begin::Number*/}
              <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{count_data?.customer_count}</span>
              {/*end::Number*/}
              {/*begin::Follower*/}
              <div className="m-0">
                <span className="fw-semibold fs-6 text-gray-400">Customers</span>
              </div>
              {/*end::Follower*/}
            </div>
            {/*end::Section*/}
            {/*begin::Badge*/}
            <span className="badge badge-light-success fs-base">
              {/*end::Svg Icon*/}</span>
            {/*end::Badge*/}
          </div>
          {/*end::Body*/}
        </div>
        </Link>
      </div>
      {/* begin::Col */}
      <div className="col-sm-6 col-xl-2 mb-xl-10">


        {/*begin::Card widget 2*/}
         <Link to={'/admin/lead-management/leads'} >
        <div className="card h-lg-100">
          {/*begin::Body*/}
          <div className="card-body d-flex justify-content-between align-items-start flex-column">
            {/*begin::Icon*/}
            <div className="m-0">
              {/*begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-09-15-053640/core/html/src/media/icons/duotune/general/gen005.svg*/}
              <span className="svg-icon svg-icon-muted svg-icon-2hx"><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor" />
                <rect x={7} y={17} width={6} height={2} rx={1} fill="currentColor" />
                <rect x={7} y={12} width={10} height={2} rx={1} fill="currentColor" />
                <rect x={7} y={7} width={6} height={2} rx={1} fill="currentColor" />
                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
              </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Icon*/}
            {/*begin::Section*/}
            <div className="d-flex flex-column my-7">
              {/*begin::Number*/}
              <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{count_data?.lead_count}</span>
              {/*end::Number*/}
              {/*begin::Follower*/}
              <div className="m-0">
                <span className="fw-semibold fs-6 text-gray-400">Leads</span>
              </div>
              {/*end::Follower*/}
            </div>
            {/*end::Section*/}
            {/*begin::Badge*/}
            <span className="badge badge-light-success fs-base">
              {/*end::Svg Icon*/}</span>
            {/*end::Badge*/}
          </div>
          {/*end::Body*/}
        </div>
        {/*end::Card widget 2*/}
        </Link>
      </div>
      {/* begin::Col */}
      <div className="col-sm-6 col-xl-2 mb-xl-10">


        {/*begin::Card widget 2*/}
         <Link to={'/admin/cars/brands'} >
        <div className="card h-lg-100">
          {/*begin::Body*/}
          <div className="card-body d-flex justify-content-between align-items-start flex-column">
            {/*begin::Icon*/}
            <div className="m-0">
              {/*begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-09-15-053640/core/html/src/media/icons/duotune/general/gen005.svg*/}
              <span className="svg-icon svg-icon-muted svg-icon-2hx"><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor" />
                <rect x={7} y={17} width={6} height={2} rx={1} fill="currentColor" />
                <rect x={7} y={12} width={10} height={2} rx={1} fill="currentColor" />
                <rect x={7} y={7} width={6} height={2} rx={1} fill="currentColor" />
                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
              </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Icon*/}
            {/*begin::Section*/}
            <div className="d-flex flex-column my-7">
              {/*begin::Number*/}
              <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{count_data?.car_count}</span>
              {/*end::Number*/}
              {/*begin::Follower*/}
              <div className="m-0">
                <span className="fw-semibold fs-6 text-gray-400">Cars</span>
              </div>
              {/*end::Follower*/}
            </div>
            {/*end::Section*/}
            {/*begin::Badge*/}
            <span className="badge badge-light-success fs-base">
              {/*end::Svg Icon*/}</span>
            {/*end::Badge*/}
          </div>
          {/*end::Body*/}
        </div>
        {/*end::Card widget 2*/}
        </Link>
      </div>
    </div>
  </>
)}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
