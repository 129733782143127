/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, BrandsQueryResponse} from './_models'
import { toast } from 'react-toastify'
const USER_URL = `${process.env.REACT_APP_API_URL}/api/admin/delete-blog-category`
const GET_USERS_URL = `${process.env.REACT_APP_API_URL}/api/admin/get-blog-category`

const getUsers = (query: string): Promise<BrandsQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<BrandsQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then((response) => {
    response.data.status == 1 ? toast.success(response.data.message) : toast.error(response.data.message);
  })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then((response) => {
    const notdeleted = response.filter((res)=> res.data.status === 2).length;
    if(notdeleted > 0)
    {
      toast.error('Items used somewhere is not deleted');
    }
    else
    {
      toast.success("Selected deleted successfully");
    }
    
  })
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser}
