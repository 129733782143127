/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { UsersListWrapper } from './cities-management/users-list/UsersList'
import { useAuth } from '../../modules/auth'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CitiesListing = () => {

  const { currentUser } = useAuth();

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Cities Listing</PageTitle>
      {currentUser?.permissions.find((item: any) => item.name === "view_cities") ?
      <UsersListWrapper />
      : <h1>You Are Not Allowed</h1> }
      {/* <TablesWidget10 className={''} /> */}
    </>
  )
}

export default CitiesListing
