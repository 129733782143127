/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import { isNotEmpty } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { GET_BRAND_NAME, POST_MODEL_NAME } from '../../../../../modules/auth/core/_requests'
import axios from 'axios'
import { toast } from 'react-toastify'
import Select from 'react-select'

type Props = {
  isUserLoading: boolean
  user: any
}


const UserEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  // const [userForEdit] = useState<User>({
  //   ...user,
  //   avatar: user.avatar || initialUser.avatar,
  //   role: user.role || initialUser.role,
  //   position: user.position || initialUser.position,
  //   name: user.name || initialUser.name,
  //   email: user.email || initialUser.email,
  // })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  // const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  // const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)

  // const formik = useFormik({
  //   initialValues: userForEdit,
  //   validationSchema: editUserSchema,
  //   onSubmit: async (values, {setSubmitting}) => {
  //     setSubmitting(true)
  //     try {
  //       if (isNotEmpty(values.id)) {
  //         await updateUser(values)
  //       } else {
  //         await createUser(values)
  //       }
  //     } catch (ex) {
  //       console.error(ex)
  //     } finally {
  //       setSubmitting(true)
  //       cancel(true)
  //     }
  //   },
  // })

  const [name, setName] = useState(user.model? user.model : '');
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState(user.car_image ? user.car_image : null);
  
  const [brands, setBrands] = useState<any[]>([]);


  const [selectedBrand, setSelectedBrand] = useState(user.brand_id ? user.brand_id : '');
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(GET_BRAND_NAME);
        setBrands(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBrands();
  }, []);
  const handleBrandChange = (brand: any) => {
    setSelectedBrand(brand);
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }
  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setImage(event.target.files[0])
    }
  }
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append('model', name);
      if (image) {
        formData.append('carImage', image, image.name);
      }
      formData.append('brand_id', selectedBrand);
      if (isNotEmpty(user.id)) {
        formData.append('id', `${user.id}`);
      }

      const response = await axios.post(`${POST_MODEL_NAME}`, formData);
      
      if (response.data.status == 1) {
        toast.success(response.data.message);
        setName('');
        setImage(null);
        setSelectedBrand('');
        cancel(true)
      }
      else {
        toast.error(response.data.message);
      }

    } catch (ex) {
      console.error(ex)
    }

  };

  return (
    <>
      <form onSubmit={handleSubmit} className='row justify-content-center'>

        <label className='col-7 mb-5' htmlFor="">
          Car Model
          <input type="text" className='form-control' value={name} onChange={handleNameChange} />
        </label>
        <label htmlFor="" className="col-7 mb-5">
          Select Brand
          {user.brand_id ?  <Select
            isClearable={true}
            onChange={selectedOption =>
              handleBrandChange(selectedOption?.value)
            }
            options={
              brands && brands.map((item: any) => (
                { value: item?.id, label: item?.name }
              ))
            } 
         
            value={{value:selectedBrand ? selectedBrand : '' , label: brands.find(x => x.id === selectedBrand) ? brands.find(x => x.id === selectedBrand).name : '' }}
            /> :  <Select
            isClearable={true}
            onChange={selectedOption =>
              handleBrandChange(selectedOption?.value)
            }
            options={
              brands && brands.map((item: any) => (
                { value: item?.id, label: item?.name }
              ))
            } 
            /> }
         
          {selectedBrand === '' ? (
            <span className="text-danger">
              Select Brand
            </span>
          ) : null}

        </label>
        <label htmlFor="" className='col-7 mb-5'>
          Upload Car Logo
          <input type="file" className='form-control mb-5' onChange={handleLogoChange} />
          {imagePreview && (
            <img src={imagePreview} height={100} alt="" />
          )}
        </label>
          <button className='btn btn-primary col-7 mb-5' disabled={selectedBrand != '' && name != '' ? false : true} type='submit'>Submit</button>
      </form>
      {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
    </>
  )
}


export { UserEditModalForm }
