/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { UsersListWrapper } from './leads-management/users-list/UsersList'
import { LeadGenerationForm } from '../LeadGenerationForm'
import { LeadUpdate } from '../LeadUpdate'
import { useAuth } from '../../modules/auth'



const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LeadsListing = () => {

  const {currentUser} = useAuth();

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='leads'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Lead Listing</PageTitle>
              {currentUser?.permissions.find((item: any) => item.name === "view_lead") ?
              <UsersListWrapper />  : <h1>You Are Not Allowed</h1> }
              {/* <TablesWidget10 className={''} /> */}
            </>
          }
        />
        <Route
          path='add-lead'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Add Lead</PageTitle>
              {currentUser?.permissions.find((item: any) => item.name === "add_lead") ?
              <LeadGenerationForm /> : <h1>You Are Not Allowed</h1> }
              {/* <TablesWidget10 className={''} /> */}
            </>
          }
        />
       
      </Route>
      <Route index element={<Navigate to='/admin/Lead-management/leads' />} />
    </Routes>
  )
}

export default LeadsListing
