/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { OrderDetailsByCr } from '../orders-management/users-list/core/_models';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Select from 'react-select'
import { GET_BRAND_NAME, GET_MODEL_NAME_BY_BRAND, GET_VARIENT_NAME_BY_MODEL, OrderDetailsSave } from '../../../modules/auth/core/_requests';
import { useQuery } from 'react-query';
import { useAuth } from '../../../modules/auth';



const OrderDetails = ({ id, user_id, refetch, getOrderDetails }: any) => {

    const [selectedColor, setSelectedColor] = useState(null);
    const [disabledSubmit, setdisabledSubmit] = useState(false);


    const [cr, setcr] = useState<any>([]);

    const fetchcr = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-cr`);
            setcr(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const [garage, setgarage] = useState<any>([]);

    const fetchgarage = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-garage`);
            setgarage(response.data);
        } catch (error) {
            console.error(error);
        }
    };


    const colors = [
        'Black', 'White', 'Silver', 'Gray', 'Red', 'Blue', 'Green', 'Yellow', 'Orange', 'Brown'
    ];

    const { currentUser } = useAuth();
    const getOrder = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-order-details/${id}`);
        
        setOrder(response.data);
        getOrderDetails(response.data);
        fetchModels(response.data.brand_id);
        return response.data;
    }



    //     const Styles = `.dot {
    //     height: 25px;
    //     width: 25px;
    //     background-color: #bbb;
    //     border-radius: 50%;
    //     margin-left : 10px;
    //     display: inline-block;
    //   }
    //   .button-33 {
    //     background-color: #c2fbd7;
    //     border-radius: 100px;
    //     box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
    //     color: green;
    //     cursor: pointer;
    //     display: inline-block;
    //     font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
    //     padding: 7px 20px;
    //     text-align: center;
    //     text-decoration: none;
    //     transition: all 250ms;
    //     border: 0;
    //     font-size: 16px;
    //     user-select: none;
    //     -webkit-user-select: none;
    //     touch-action: manipulation;
    //   }

    //   .button-33:hover {
    //     box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
    //     transform: scale(1.05) rotate(-1deg);
    //   }
    //   `;

    type T = {
        name: string
    }

    const lead_types = [
        { value: 'general', label: 'Genral' },
        { value: 'insurance', label: 'Insurance' },
        { value: 'commercial', label: 'Commercial' },
        { value: 'accessories_retail', label: 'Accessories Retail' },
        { value: 'sos', label: 'SOS' },
        { value: 'luxury', label: 'Luxury' },
    ];

    const crvalues = [
        { value: 'anurag_shrivastava', label: 'Anurag Shrivastava' },
        { value: 'rahul_dubey', label: 'Rahul Dubey' }
    ];

    const sourceoptions = [
        { value: 'refferal_client', label: 'Refferal Client' },
        { value: 'organic', label: 'Organic' },
        { value: 'sms', label: 'SMS' },
        { value: 'repeat', label: 'Repeat' },
        { value: 'b2b', label: 'B2B' },
        { value: 'insurance_agent', label: 'Insurance Agent' },
        { value: 'accessories', label: 'Accessories' },
        { value: 'sos', label: 'SOS' },
        { value: 'flagship', label: 'Flagship' },
        { value: 'agent_app', label: 'Agent App' },
    ];
    const params = useParams();
    const [arrival_type, setArrival_type] = useState('pickup');
    const [tax_type, setTax_type] = useState('GST');
    const [color, setColor] = useState('');

    const [order, setOrder] = useState<OrderDetailsByCr>();
    const [orderloaded, setorderloaded] = useState(true);
    const [brands, setBrands] = useState<any>([]);
    // const [startDate, setStartDate] = useState(new Date());

    // const [total,setTotal] = useState(0);
    useEffect(() => {

        if (orderloaded) {
            fetchBrands();
            // getOrder();
            fetchcr();
            fetchgarage();
            setorderloaded(false);
        }

    }, [])
    const [leadstatus, setLeadstatus] = useState<any>([]);
    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-garage`);
                setLeadstatus(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchBrands();
    }, []);
    const { data, status } = useQuery("users", getOrder);
    const fetchBrands = async () => {
        try {
            const response = await axios.get(GET_BRAND_NAME)
            setBrands(response.data)
        } catch (error) {
            console.error(error)
        }
    };


    const [models, setModels] = useState<any>([]);

    const fetchModels = async (id: any) => {
        try {
            const response = await axios.get(`${GET_MODEL_NAME_BY_BRAND}/${id}`);
            setModels(response.data);
        } catch (error) {
            console.error(error);
        }
    };



    // const [total,setTotal] = useState(0);


    const formik = useFormik({
        initialValues: { ...order, order_id: id, user_id: user_id, garage_id: order?.garage_id ? order?.garage_id : null, color: order?.color ? order?.color : selectedColor, cr: order?.cr ? order?.cr : null },
        validationSchema: Yup.object({

            brand_model: Yup.string()
                .required("Select model"),
            customer_gender: Yup.string()
                .required("Select gender"),
            odometer_reading: Yup.string()
                .required("Enter car odometer reading"),
            manufacture_year: Yup.string()
                .required("Enter year"),
            customer_contact: Yup.string()
                .required("Enter contact"),
            fuel_type: Yup.string()
                .required("Enter fuel type"),

        }),
        enableReinitialize: true,
        onSubmit: async (values) => {

            setdisabledSubmit(true);
           
            const token = localStorage.getItem("token");

            const response = await axios.post(OrderDetailsSave,
                { ...values },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data.status == 1) {
                setdisabledSubmit(false)
                toast.success("Order Details updated successfully");
                refetch();
               
                getOrderDetails(values);
            } else {
                setdisabledSubmit(false)
                toast.error(response.data.message);
            }
        },
    });
    const handleColorClick = (color: any) => {
        setSelectedColor(color);
        formik.setFieldValue('color', color);
    };

    const Modelref: any = useRef(null);
    function scrollToElementAbove(elementId: string) {
        const element = document.getElementById(elementId);
        if (element) {
            const offset = -100; // Adjust this value according to your preference
            const elementTop = element.getBoundingClientRect().top;
            window.scrollTo({
                top: window.pageYOffset + elementTop + offset,
                behavior: 'smooth'
            });
        } else {
            console.log(`Element with id '${elementId}' not found`);
        }
    } 

    const handleScrollError = () => {
         const errorKeys = Object.keys(formik.errors);
            if (errorKeys.length > 0) {
                const firstErrorKey = errorKeys[0];
                scrollToElementAbove(firstErrorKey);
            }
    }
    
   
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="card my-5 mx-3 p-2">
                    <div className="row">
                        <h3>Car Details</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="registration_no">Registration No <span className='text-danger'>*</span></label>

                                <input
                                    className="form-control"
                                    id="registration_no"
                                    type="text"
                                    placeholder="Enter registration no"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.registration_no ? formik.values.registration_no : ''}
                                />
                                {formik.touched.registration_no && formik.errors.registration_no ? (
                                    <span className="text-danger">
                                        {formik.errors.registration_no}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="odometer_reading">Odometer Reading</label>

                                <input
                                    className="form-control"
                                    id="odometer_reading"
                                    type="text"
                                    placeholder="Enter Odometer reading"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.odometer_reading ? formik.values.odometer_reading : ''}
                                />
                                {formik.touched.odometer_reading && formik.errors.odometer_reading ? (
                                    <span className="text-danger">
                                        {formik.errors.odometer_reading}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="brand_model">Brand <span className='text-danger'></span></label>

                                {/* <input
                                    className="form-control"
                                    id="brand_model"
                                    type="text"
                                    placeholder="Enter Car brand models"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.brand_model ? formik.values.brand_model : ''}
                                /> */}
                                {formik.values.brand_id ?
                                    <Select
                                        isClearable={true}

                                        onChange={(selectedOption) => {
                                            Modelref.current.clearValue();
                                            formik.setFieldValue('brand_id', selectedOption?.value1);
                                            formik.setFieldValue('brand_model', '');

                                            fetchModels(selectedOption?.value1);
                                        }
                                        }
                                        options={
                                            brands && brands.map((itemBrand: any) => (
                                                { value1: itemBrand?.id, label: itemBrand?.name }
                                            ))
                                        }
                                        value={{ value1: formik.values.brand_id, label: brands ? brands.find((el: any) => el.id === formik.values.brand_id)?.name : '' }}
                                    />
                                    :
                                    <Select
                                        isClearable={true}

                                        onChange={(selectedOption: any) => {
                                            Modelref.current.clearValue();
                                            formik.setFieldValue('brand_id', selectedOption?.value1);
                                            formik.setFieldValue('brand_model', '');

                                            fetchModels(selectedOption?.value1);
                                        }
                                        }
                                        options={
                                            brands && brands.map((itemBrand: any) => (
                                                { value1: itemBrand?.id, label: itemBrand?.name }
                                            ))
                                        }

                                    />

                                }
                                {formik.touched.brand_model && formik.errors.brand_model ? (
                                    <span className="text-danger">
                                        {formik.errors.brand_model}
                                    </span>
                                ) : null}
                            </div>
                        </div>


                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="model">Model</label>
                                {formik.values.brand_model ? <Select
                                    ref={Modelref}
                                    isClearable={true}
                                    onChange={(selectedOption: any) => {

                                        formik.setFieldValue('brand_model', selectedOption?.value);


                                    }
                                    }
                                    options={
                                        models && models.map((itemModel: any) => (
                                            { value: itemModel?.id, label: itemModel?.model }
                                        ))
                                    }
                                    value={{ value1: order?.brand_model, label: models ? models.find((el: any) => el.id === formik.values.brand_model)?.model : '' }}
                                /> : <Select
                                    ref={Modelref}
                                    isClearable={true}
                                    onChange={(selectedOption: any) => {

                                        formik.setFieldValue('brand_model', selectedOption?.value);
                                    }
                                    }
                                    options={
                                        models && models.map((itemModel: any) => (
                                            { value: itemModel?.id, label: itemModel?.model }
                                        ))
                                    }

                                />}

                                {formik.touched.brand_model && formik.errors.brand_model ? (
                                    <span className="text-danger">
                                        {formik.errors.brand_model}
                                    </span>
                                ) : null}
                            </div>
                        </div>



                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="manufacture_year">Year of Manufacture <span className='text-danger'></span></label>

                                <input
                                    className="form-control"
                                    id="manufacture_year"
                                    type="text"
                                    placeholder="Enter Year of manufacture"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.manufacture_year ? formik.values.manufacture_year : ''}
                                />
                                {formik.touched.manufacture_year && formik.errors.manufacture_year ? (
                                    <span className="text-danger">
                                        {formik.errors.manufacture_year}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="fuel_type">Fuel Type <span className='text-danger'></span></label>

                                <input
                                    className="form-control"
                                    id="fuel_type"
                                    type="text"
                                    placeholder="Enter Year of manufacture"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.fuel_type ? formik.values.fuel_type : ''}
                                />
                                {formik.touched.fuel_type && formik.errors.fuel_type ? (
                                    <span className="text-danger">
                                        {formik.errors.fuel_type}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                    </div>

                </div>
                {/* Car details */}
                <div className="card my-5 mx-3 p-2">
                    <div className="row">
                        <h3>Select Car Color (Optional)</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <div>
                                {colors.map((color, index) => (
                                    <button type='button'
                                        key={index}
                                        style={{
                                            backgroundColor: color,
                                            width: '40px',
                                            height: '40px',
                                            margin: '5px',
                                            border: formik.values.color === color ? '2px solid gray' : '2px solid black',
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                            boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)'
                                        }}
                                        onClick={() => handleColorClick(color)}
                                    ></button>
                                ))}
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="fvcolor" className='mb-4'>Color : </label>
                                <input type="color" readOnly={true} value={color} />
                                <input type="color" id="favcolor" onChange={(e) => setColor(e.target.value)} name="favcolor" />
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* Customer details */}
                <div className="card my-5 mx-3 p-2">
                    <div className="row">
                        <h3>Customer Detail</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="customer_name">Name <span className='text-danger'>*</span></label>

                                <input
                                    className="form-control"
                                    id="customer_name"
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.customer_name ? formik.values.customer_name : ''}
                                />
                                {formik.touched.customer_name && formik.errors.customer_name ? (
                                    <span className="text-danger">
                                        {formik.errors.customer_name}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="customer_email">Email </label>

                                <input
                                    className="form-control"
                                    id="customer_email"
                                    type="text"
                                    placeholder="Enter Email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.customer_email ? formik.values.customer_email : ''}
                                />
                                {formik.touched.customer_email && formik.errors.customer_email ? (
                                    <span className="text-danger">
                                        {formik.errors.customer_email}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="customer_contact">Phone Number </label>

                                <input
                                    className="form-control"
                                    id="customer_contact"
                                    type="text"
                                    placeholder="Enter Phone Number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.customer_contact ? formik.values.customer_contact : ''}
                                />
                                {formik.touched.customer_contact && formik.errors.customer_contact ? (
                                    <span className="text-danger">
                                        {formik.errors.customer_contact}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="customer_alnumber">Alternate Number </label>

                                <input
                                    className="form-control"
                                    id="customer_alnumber"
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.customer_alnumber ? formik.values.customer_alnumber : ''}
                                />
                                {formik.touched.customer_alnumber && formik.errors.customer_alnumber ? (
                                    <span className="text-danger">
                                        {formik.errors.customer_alnumber}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                    </div>

                    <div className="col-md-12 mt-5">
                        <div className="form-group">
                            <label htmlFor="customer_address">Address</label>

                            <input
                                className="form-control"
                                id="customer_address"
                                type="text"
                                placeholder="Enter Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.customer_address ? formik.values.customer_address : ''}
                            />
                            {formik.touched.customer_address && formik.errors.customer_address ? (
                                <span className="text-danger">
                                    {formik.errors.customer_address}
                                </span>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-12 mt-5">
                        <div className="form-group ">
                            <label htmlFor="customer_gender"> Gender <span className='text-danger'>*</span></label>

                            <div className="form-inline">
                                <input
                                    id="male"
                                    type="radio"
                                    className='ms-5 me-2'
                                    name="customer_gender"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'male'}
                                    checked={formik.values.customer_gender === 'male'}
                                />

                                <label htmlFor="male" className='mx-auto'> Male </label>

                                <input

                                    id="female"
                                    type="radio"
                                    name="customer_gender"
                                    className='ms-5 me-2'
                                    // placeholder="Enter Name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'female'}
                                    checked={formik.values.customer_gender === 'female'}
                                />
                                <label htmlFor="female">Female </label>
                            </div>

                            {formik.touched.customer_gender && formik.errors.customer_gender ? (
                                <span className="text-danger">
                                    {formik.errors.customer_gender}
                                </span>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-12 mt-5">
                        <div className="form-group">
                            <label htmlFor="customer_gst">Customer GST</label>
                            <input
                                className="form-control"
                                id="customer_gst"
                                type="text"
                                placeholder="Enter gst"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.customer_gst ? formik.values.customer_gst : ''}
                            />
                            {formik.touched.customer_gst && formik.errors.customer_gst ? (
                                <span className="text-danger">
                                    {formik.errors.customer_gst}
                                </span>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-12 mt-5">
                        <div className="form-group">
                            <label htmlFor="arrival_mode">Arraival Mode</label>

                            <div className="form-inline">
                                <input
                                    id="pickup"
                                    type="radio"
                                    className='ms-5 d-none me-2'
                                    name="arrival_mode"
                                    onChange={(e) => {

                                        setArrival_type('pickup');
                                        formik.setFieldValue('arrival_mode', 'pickup');

                                    }}
                                    onBlur={formik.handleBlur}
                                    value={'pickup'}
                                />

                                <label htmlFor="pickup" className={`btn btn-${formik.values.arrival_mode === 'pickup' ? 'primary' : 'secondary'} mx-3 rounded btn-sm`}> Pickup </label>

                                <input

                                    id="walkin"
                                    type="radio"
                                    name="arrival_mode"
                                    className='ms-5 d-none me-2'
                                    // placeholder="Enter Name"
                                    onChange={(e) => {
                                        setArrival_type('walkin');
                                        formik.setFieldValue('arrival_mode', 'walkin');
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={'walkin'}
                                />
                                <label htmlFor="walkin" className={`btn btn-${formik.values.arrival_mode === 'walkin' ? 'primary' : 'secondary'}  mx-3 rounded btn-sm`}> Walkin </label>

                                {formik.touched.arrival_mode && formik.errors.arrival_mode ? (
                                    <span className="text-danger">
                                        {formik.errors.arrival_mode}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>


                </div>
                {/* Order details */}

                <div className="card my-5 mx-3 p-2">
                    <div className="row">
                        <h3>Order Detail</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="order_type">Order Type </label>
                                <Select

                                    onChange={(selectedOption) => {
                                        formik.setFieldValue('order_type', selectedOption?.value);
                                    }
                                    }
                                    options={[
                                        { value: 'yes', label: 'Yes' },
                                        { value: 'no', label: 'No' }
                                    ]}
                                    value={{ value: formik.values.order_type, label: formik.values.order_type === 'no' ? 'No' : 'Yes' }}

                                />

                                {formik.touched.order_type && formik.errors.order_type ? (
                                    <span className="text-danger">
                                        {formik.errors.order_type}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="cr">CR</label>
                                {formik.values.cr ? <Select
                                    isClearable={true}
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue('cr', selectedOption?.value);
                                    }
                                    }
                                    options={cr && cr.map((crItem: any) => {

                                        return { value: crItem?.id, label: crItem?.name }
                                    })}
                                    value={{ value: formik.values.cr, label: cr.find((el: any) => el.id === formik.values.cr)?.name }}
                                /> : <Select
                                    isClearable={true}
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue('cr', selectedOption?.value);
                                    }
                                    }
                                    options={cr && cr.map((crItem: any) => (
                                        { value: crItem?.id, label: crItem?.name }
                                    ))
                                    } />}

                                {formik.touched.cr && formik.errors.cr ? (
                                    <span className="text-danger">
                                        {formik.errors.cr}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="garage">Garage</label>
                                {formik.values.garage_id ? <Select
                                    isClearable={true}
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue('garage_id', selectedOption?.value);
                                    }
                                    }
                                    options={garage && garage.map((garageItem: any) => (
                                        { value: garageItem?.id, label: garageItem?.name }
                                    ))}
                                    value={{ value: formik.values.garage_id, label: garage.find((el: any) => el.id === formik.values.garage_id)?.name }}
                                /> :
                                    <Select

                                        isClearable={true}
                                        onChange={(selectedOption: any) => {
                                            formik.setFieldValue('garage_id', selectedOption?.value);
                                        }
                                        }
                                        options={
                                            garage && garage.map((garageItem: any) => (
                                                { value: garageItem?.id, label: garageItem?.name }
                                            ))
                                        } />}
                                {formik.touched.garage && formik.errors.garage ? (
                                    <span className="text-danger">
                                        {formik.errors.garage}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="fleet">Fleet </label>

                                <input
                                    className="form-control"
                                    id="fleet"
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.fleet ? formik.values.fleet : ''}
                                />
                                {formik.touched.fleet && formik.errors.fleet ? (
                                    <span className="text-danger">
                                        {formik.errors.fleet}
                                    </span>
                                ) : null}
                            </div>
                        </div>


                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="pickup_date">Deliver date </label>

                                <input
                                    className="form-control"
                                    id="pickup_date"
                                    type="date"
                                    placeholder="Select Deliver Date"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.pickup_date ? formik.values.pickup_date : ''}
                                />
                                {formik.touched.pickup_date && formik.errors.pickup_date ? (
                                    <span className="text-danger">
                                        {formik.errors.pickup_date}
                                    </span>
                                ) : null}
                            </div>
                        </div>


                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="pickup_time">Deliver time </label>

                                <input
                                    className="form-control"
                                    id="pickup_time"
                                    type="time"
                                    placeholder="Select deliver time"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.pickup_time ? formik.values.pickup_time : ''}
                                />
                                {formik.touched.pickup_time && formik.errors.pickup_time ? (
                                    <span className="text-danger">
                                        {formik.errors.pickup_time}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-12 mt-5">
                            <div className="form-group">
                                <label htmlFor="tax_mode">Tax Mode <span className='text-danger'></span></label>

                                <div className="form-inline">


                                    <label htmlFor="IGST" className={`btn btn-${formik.values.tax_mode === 'IGST' ? 'primary' : 'secondary'} mx-3 rounded btn-sm`}>  <input
                                        id="IGST"
                                        type="radio"
                                        className='ms-5 d-none me-2'
                                        name="tax_mode"
                                        onChange={(e) => {

                                            setTax_type('IGST');
                                            formik.setFieldValue('tax_mode', 'IGST');


                                        }}
                                        onBlur={formik.handleBlur}
                                        value={'IGST'}
                                    /> IGST </label>


                                    <label htmlFor="GST" className={`btn btn-${formik.values.tax_mode === 'GST' ? 'primary' : 'secondary'}  mx-3 rounded btn-sm`}>
                                        <input

                                            id="GST"
                                            type="radio"
                                            name="tax_mode"
                                            className='ms-5 d-none me-2'
                                            // placeholder="Enter Name"
                                            onChange={(e) => {
                                                setTax_type('GST');
                                                formik.setFieldValue('tax_mode', 'GST');

                                            }}
                                            onBlur={formik.handleBlur}
                                            value={'GST'}
                                        />
                                        GST </label>

                                    {formik.touched.tax_mode && formik.errors.tax_mode ? (
                                        <span className="text-danger">
                                            {formik.errors.tax_mode}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>


                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="GSTIN_display">GSTIN display</label>
                                <Select

                                    onChange={(selectedOption) => {
                                        formik.setFieldValue('GSTIN_display', selectedOption?.value);
                                    }
                                    }
                                    options={[
                                        { value: 'yes', label: 'Yes' },
                                        { value: 'no', label: 'No' }
                                    ]}
                                    value={{ value: formik.values.GSTIN_display, label: formik.values.GSTIN_display === 'no' ? 'No' : 'Yes' }}
                                />
                                {formik.touched.GSTIN_display && formik.errors.GSTIN_display ? (
                                    <span className="text-danger">
                                        {formik.errors.GSTIN_display}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="source">Lead Source</label>
                                {formik.values.lead_source ? <Select

                                    onChange={(selectedOption) => {
                                        formik.setFieldValue('lead_source', selectedOption?.value);
                                    }
                                    }
                                    options={sourceoptions}
                                    value={{ value: formik.values.lead_source, label: sourceoptions.find((el) => el.value === formik.values.lead_source)?.label }}
                                /> :
                                    <Select

                                        onChange={(selectedOption) => {
                                            formik.setFieldValue('lead_source', selectedOption?.value);
                                        }
                                        }
                                        options={sourceoptions} />}

                                {formik.touched.lead_source && formik.errors.lead_source ? (
                                    <span className="text-danger">
                                        {formik.errors.lead_source}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="leadID">Lead Id</label>

                                <input
                                    className="form-control"
                                    id="leadID"
                                    type="text"
                                    placeholder="Select Pickup Date"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.leadID ? formik.values.leadID : ''}
                                />
                                {formik.touched.leadID && formik.errors.leadID ? (
                                    <span className="text-danger">
                                        {formik.errors.leadID}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="card my-5 mx-3 p-2">
                    <div className="row">
                        <h3>Order Detail</h3>
                    </div>
                    <div className="row">

                        <div className="col-md-12 mt-5">
                            <div className="form-group">
                                <label htmlFor="remark">Remark </label>

                                <textarea
                                    className="form-control"
                                    id="remark"
                                    rows={6}
                                    placeholder="Enter remark"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.remark ? formik.values.remark : ''}
                                >{formik.values.remark}</textarea>
                                {formik.touched.remark && formik.errors.remark ? (
                                    <span className="text-danger">
                                        {formik.errors.remark}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                {currentUser?.permissions.find((item: any) => item.name === "update_orders") ?
                    <button type='submit' onClick={handleScrollError} disabled={disabledSubmit} className='btn btn-primary btn-sm'>
                        {disabledSubmit ? (<span>
                            Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>) : 'Update'}
                    </button>
                    : null}
            </form>
        </>
    )
}

export default OrderDetails

