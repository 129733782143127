/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react'
import * as Yup from 'yup'
import { initialUser, User } from '../core/_models'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import axios from 'axios'
import { toast } from 'react-toastify'
import { POST_BRAND_NAME } from '../../../../../modules/auth/core/_requests'

type Props = {
  isUserLoading: boolean 
  user: User
}

const editUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const UserEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [userForEdit] = useState<User>({
    ...user,
    avatar: user.avatar || initialUser.avatar,
    role: user.role || initialUser.role,
    position: user.position || initialUser.position,
    name: user.name || initialUser.name,
    email: user.email || initialUser.email,
  })

  // const cancel = (withRefresh?: boolean) => {
  //   if (withRefresh) {
  //     refetch()
  //   }
  //   setItemIdForUpdate(undefined)
  // }

  // const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  // const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)

  // const formik = useFormik({
  //   initialValues: userForEdit,
  //   validationSchema: editUserSchema,
  //   onSubmit: async (values, {setSubmitting}) => {
  //     setSubmitting(true)
  //     try {
  //       if (isNotEmpty(values.id)) {
  //         await updateUser(values)
  //       } else {
  //         await createUser(values)
  //       }
  //     } catch (ex) {
  //       console.error(ex)
  //     } finally {
  //       setSubmitting(true)
  //       cancel(true)
  //     }
  //   },
  // })

  const [name, setName] = useState('');
  const [logo, setLogo] = useState<File | null>(null);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setLogo(event.target.files[0]);
    }
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    if (logo) {
      formData.append('logo', logo);
    }

    try {
      const response = await axios.post(`${POST_BRAND_NAME}`, formData);

      toast.success('Car brand added successfully!', {
      });

      setName('');
      setLogo(null);
    } catch (error) {
      console.error(error);
      // Show error toast
      toast.error('Failed to add car brand. Please try again!', {
        // ... (toast options)
      });
    }
  };


  return (
    <>
      <form onSubmit={handleSubmit} className='row justify-content-center' action="">
        <h1 className='text-center'>Car Brand Form</h1>
        <label className='col-7 mt-5' htmlFor="">
          Car Brand
          <input type="text" className='form-control' value={name} onChange={handleNameChange} />
        </label>
        <label htmlFor="" className='col-7 mt-5'>
          Upload Car Logo
          <input type="file" className='form-control' onChange={handleLogoChange} />
          <button className='btn btn-primary mt-5' type='submit'>Submit</button>
        </label>
      </form>

      {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
    </>
  )
}

export { UserEditModalForm }
