import { useLayout } from '../../../core'

const ToolbarClassic = () => {
  const { config } = useLayout()

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      {config.app?.toolbar?.secondaryButton && (
        <button type='button' className='btn btn-sm btn-flex btn-light fw-bold'>
          Filter
        </button>
      )}
    </div>
  )
}

export { ToolbarClassic }
