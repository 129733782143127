/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from 'react'
import { isNotEmpty } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import axios from 'axios'
import { toast } from 'react-toastify'
import { ADD_SEOS, GET_BRAND_NAME } from '../../../../../modules/auth/core/_requests'
import Tagify from '@yaireo/tagify'
// import "@yaireo/tagify/src/tagify.scss";
import "./seos.css";
type Props = {
  isUserLoading: boolean
  user: any
}

const UserEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()


  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const handleImageChange = (e: any) => {
    const selectedImage = e.target.files[0];
    const id = e.target.getAttribute("id");
    if (id == 'imageog') {
      setImageog(selectedImage);
    }
    else {
      setImagetwitter(selectedImage);
    }
    if (selectedImage) {
      const reader: any = new FileReader();
      reader.onloadend = () => {

        if (id == 'imageog') {
          setPreviewUrl(reader.result);
          setPreviousImageog(reader.result);
        }
        else {
          setPreviousImagetwitter(reader.result);
        }
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setPreviewUrl(null);
    }
  };

  const [module_id, setmodule_id] = useState(user.module_id ? user.module_id : '');
  const [module_name, setmodule_name] = useState(user.module_name ? user.module_name : '');
  const [module_type, setmodule_type] = useState(user.module_type ? user.module_type : '');
  const [title, settitle] = useState(user.title ? user.title : '');
  const [description, setdescription] = useState(user.description ? user.description : '');
  const [og_title, setog_title] = useState(user.og_title ? user.og_title : '');
  const [og_description, setog_description] = useState(user.og_description ? user.og_description : '');
  const [keywords, setkeywords] = useState(user.keywords ? user.keywords : '');
  const [author, setauthor] = useState(user.author ? user.author : '');
  const [robots, setrobots] = useState(user.robots ? user.robots : '');
  const [og_url, setog_url] = useState(user.og_url ? user.og_url : '');
  const [og_type, setog_type] = useState(user.og_type ? user.og_type : '');
  const [twitter_card, settwitter_card] = useState(user.twitter_card ? user.twitter_card : '');
  const [twitter_title, settwitter_title] = useState(user.twitter_title ? user.twitter_title : '');
  const [twitter_description, settwitter_description] = useState(user.twitter_description ? user.twitter_description : '');
  const [location, setlocation] = useState(user.location ? user.location : '');
  const [previousImageog, setPreviousImageog] = useState(user.og_image ? user.og_image : null);
  const [previousImagetwitter, setPreviousImagetwitter] = useState(user.twitter_image ? user.twitter_image : null);
  const [cities, setcities] = useState<any>([]);
  const [brands, setbrand] = useState<any>([]);
  const [areas, setareas] = useState<any>([]);
  const [imageog, setImageog] = useState<File | null>(null);
  const [imagetwitter, setImagetwitter] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState(user.og_image ? user.og_image : null);
  const [mainUrl, setmainUrl] = useState('');
  const [titlewidth, settitlewidth] = useState(0);
  const h3Ref: any = useRef(null);
  const keywords_input: any = useRef(null);
  new Tagify(keywords_input.current);
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(GET_BRAND_NAME)
        setbrand(response.data)
      } catch (error) {
        console.error(error)
      }
    };
    const fetchCities = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-cities-front`)
        setcities(response.data);
      } catch (error) {
        console.error(error)
      }
    };
    
    fetchBrands();
    fetchCities();
    if(user.location)
      {
        fetchareabycity(user.location);
      }
  }, []);

  const fetchareabycity = async (city_id:any) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-areas-by-city/${city_id}`)
        setareas(response.data);
      } catch (error) {
        console.error(error)
      }
    };

  useEffect(() => {
    const mainUrltext = window.location.origin;
    setmainUrl(mainUrltext);


    // Do whatever you need with the main URL
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {

      const formData = new FormData();

      formData.append('module_id', module_id);
      formData.append('module_name', module_name);
      formData.append('module_type', module_type);
      formData.append('title', title);
      formData.append('description', description);
      formData.append('og_title', og_title);
      formData.append('og_description', og_description);
      formData.append('keywords', keywords);
      formData.append('author', author);
      formData.append('robots', robots);

      formData.append('og_url', og_url);
      formData.append('og_type', og_type);
      formData.append('twitter_card', twitter_card);
      formData.append('twitter_title', twitter_title);
      formData.append('twitter_description', twitter_description);

      formData.append('location', location);
      if (imageog) {
        formData.append('og_image', imageog);
      }
      if (imagetwitter) {
        formData.append('twitter_image', imagetwitter);
      }

      if (isNotEmpty(user.id)) {
        formData.append('seo_id', `${user.id}`);
      }

      const response = await axios.post(ADD_SEOS, formData);

      if (response.data.status == 1) {

        toast.success(response.data.message);
        cancel(true)
      }
      else {
        toast.error(response.data.message);
      }

    } catch (error) {

    }
  };

  useEffect(() => {
       const checkOldData = async () => {
         const formDataOld = new FormData();
      if (isNotEmpty(user.id)) {
        formDataOld.append('seo_id', `${user.id}`);
      }
      else
      {
        formDataOld.append('seo_id', '0');
      }
    if (location != '' && module_name != '') {
          formDataOld.append('location', location);
          formDataOld.append('module_name', module_name);
    }
    else if(module_type != '')
      {
         formDataOld.append('module_type', module_type);
      }
      else
      {
         return false;
      }
     
      
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/check_old_data`, formDataOld);

      if (response.data.status == 1) {

        toast.success(response.data.message);
        cancel(true)
      }
      else {
        toast.error(response.data.message);
      }
  }
      checkOldData();
  },[module_name,location,module_type])
 


  return (
    <>
      <div className={"seo-general-preview"}>
        <h1>Preview</h1>
        {previewUrl && (
          <img
            src={previewUrl}
            alt="Preview"
            style={{ 'width': '100%' }}
          />
        )}
        {module_type == 'Page' ? <h5>{`https://mechanicnow.in/${location ? location.toLocaleLowerCase() : 'location'}/services/${module_name.toLocaleLowerCase()}`}</h5> : (module_type == 'Home' ? <h5>{`https://mechanicnow.in/`}</h5> : <h5>{`https://mechanicnow.in/${module_type.toLocaleLowerCase()}`}</h5>)}

        <h3 style={{ 'fontSize': '20px', 'lineHeight': '1.3' }}>{title}</h3>
        <p>{description}</p>
      </div>
      <form onSubmit={handleSubmit} className='row justify-content-center' method="POST" encType="multipart/form-data">
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item" role="presentation">
            <a className="nav-link active" id="main-seo-tab" data-bs-toggle="tab" href="#main-seo-fields" role="tab" aria-controls="main-seo-fields" aria-selected="true">Main SEO Fields</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id="social-media-tab" data-bs-toggle="tab" href="#social-media-fields" role="tab" aria-controls="social-media-fields" aria-selected="false">Social Media Fields</a>
          </li>
        </ul>
        <div className="tab-content">
          {/* Tab 1: Main SEO Fields */}
          <div className="tab-pane fade show active" id="main-seo-fields" role="tabpanel">
            <div className="form-group mb-4">
              <label className='fw-semibold  fs-6' htmlFor='module_type'>Module Type: </label>
              <select value={module_type} onChange={(event) => {
                setmodule_type(event.target.value);
                console.log(module_type, typeof module_type);
              }} id='module_type' className="form-control" name="module_type">
                <option value="">Select</option>
                <option value="Page">Service</option>
                <option value="Home">Home Page</option>
                <option value="Blog">Blogs</option>
                <option value="Contact">Contact Page</option>
                <option value="Faqs">FAQ Page</option>
                <option value="Careers">Careers Page</option>
                <option value="Terms-&-Conditions">Terms&Conditions Page</option>
                <option value="Become-Partner">BecomePartner Page</option>
                <option value="Privacy-Policy">PrivacyPolicy Page</option>
                <option value="Offers">Offers Page</option>
                <option value="Reviews">Reviews Page</option>
                <option value="Garage">Garage Page</option>
                <option value="About">About Page</option>
                <option value="ALL-Brands">All Brand Page</option>
              </select>
            </div>

            {(module_type === "Page" || module_type === "Garage") &&  (
              <>
                <div className="form-group mb-4">
                  <label className='fw-semibold  fs-6' htmlFor='location'>City: </label>
                  <select value={location} onChange={(event) => {setlocation(event.target.value);fetchareabycity(event.target.value);}} id='location' className="form-control" name="location">
                    <option value="">Select location</option>
                    {cities.length > 0 && cities.map((city: any) => (
                      <option key={city.id} value={`${city.name}`}>{`${city.name}`}</option>
                    ))}
                  </select>
                </div>
                {module_type === "Garage" ? (<div className="form-group mb-4">
                  <label className='fw-semibold  fs-6' htmlFor='module_name'>Areas : </label>
                  <select value={module_name} onChange={(event) => {
                    const selectedValue = event.target.value.replace(/\s+/g, '-'); // Replace spaces with hyphens
                    setmodule_name(selectedValue); // Update the state with the selected value
                  }} id='module_name' className="form-control" name="module_name">
                    <option value="">Select Brand</option>
                    {areas.length > 0 && areas.map((branditem: any) => (
                      <option key={branditem.id} value={`${branditem.name.replace(/\s+/g, '-')}`}>{`${branditem.name}`}</option>
                    ))}
                  </select>
                </div>): (<div className="form-group mb-4">
                  <label className='fw-semibold  fs-6' htmlFor='module_name'>Car Brand : </label>
                  <select value={module_name} onChange={(event) => {
                    const selectedValue = event.target.value.replace(/\s+/g, '-'); // Replace spaces with hyphens
                    setmodule_name(selectedValue); // Update the state with the selected value
                  }} id='module_name' className="form-control" name="module_name">
                    <option value="">Select Brand</option>
                    {brands.length > 0 && brands.map((branditem: any) => (
                      <option key={branditem.id} value={`${branditem.name.replace(/\s+/g, '-')}`}>{`${branditem.name}`}</option>
                    ))}
                  </select>
                </div>)}
                
              </>
            )}

            <div className="form-group mb-4">
              <label className='fw-semibold  fs-6' htmlFor='title'> Title: </label>
              &nbsp;&nbsp; {title.length} / 60
              <input type="text" id='title' placeholder='Set title' className={`form-control ${title.length > 60 ? 'is-invalid' : ''}`} value={title} onChange={(event) => {

                settitle(event.target.value);

              }} />
              {title.length > 60 ? <span className='text-danger'>Title is greater than 60 character</span> : null}
            </div>

            <div className="form-group mb-4">
              <label className='fw-semibold  fs-6' htmlFor='description'> Description: </label>
              &nbsp;&nbsp; {description.length} / 160
              <textarea id='description' rows={10} placeholder='Set description' className={`form-control ${description.length > 160 ? 'is-invalid' : ''}`} value={description} onChange={(event) => setdescription(event.target.value)}  > </textarea>
              {description.length > 160 ? <span className='text-danger'>Description is greater than 160 character</span> : null}
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold  fs-6' htmlFor='keywords'> Keywords: </label>
              <input type="text" ref={keywords_input} id='keywords' placeholder='Set keywords' className='form-control' value={keywords} onChange={(event) => setkeywords(event.target.value)} />
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='author'> Author: </label>
              <input type="text" id='author' placeholder='Set author' className='form-control' value={author} onChange={(event) => setauthor(event.target.value)} />
            </div>
          </div>
          {/* Tab 2: Social Media Fields */}
          <div className="tab-pane fade" id="social-media-fields" role="tabpanel">
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='robots'> Twitter card: </label>
              <input type="text" id='robots' placeholder='Set robots' className='form-control' value={robots} onChange={(event) => setrobots(event.target.value)} />
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='twitter_title'> Twitter title: </label>
              <input type="text" id='twitter_title' placeholder='Set twitter_title' className='form-control' value={twitter_title} onChange={(event) => settwitter_title(event.target.value)} />
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='twitter_description'> Twitter description: </label>
              <input type="text" id='twitter_description' placeholder='Set twitter_description' className='form-control' value={twitter_description} onChange={(event) => settwitter_description(event.target.value)} />
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='imagetwitter'>
                Twitter Image:
              </label>
              <input id='imagetwitter' type="file" className='form-control' onChange={handleImageChange} />
              {previousImagetwitter && <img src={previousImagetwitter} className='rounded' height={100} alt="" />}
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='og_title'> Og title: </label>
              <input type="text" id='og_title' placeholder='Set og_title' className='form-control' value={og_title} onChange={(event) => setog_title(event.target.value)} />
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='og_description'> Og description: </label>
              <input type="text" id='og_description' placeholder='Set Og description' className='form-control' value={og_description} onChange={(event) => setog_description(event.target.value)} />
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='imageog'>
                Og Image:
              </label>
              <input id='imageog' type="file" className='form-control' onChange={handleImageChange} />
              {previousImageog && <img src={previousImageog} className='rounded' height={100} alt="" />}
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='og_url'> Og url: </label>
              <input type="text" id='og_url' placeholder='Set Og url' className='form-control' value={og_url} onChange={(event) => setog_url(event.target.value)} />
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='og_type'> Og type: </label>
              <input type="text" id='og_type' placeholder='Set Og url' className='form-control' value={og_type} onChange={(event) => setog_type(event.target.value)} />
            </div>
          </div>
        </div>

        <button type="submit" className='mt-4 btn btn-primary col-5'>Submit</button>
      </form>

      {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
      {/* <ToastContainer /> */}
    </>
  )
}

export { UserEditModalForm }
