/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// import "./orders.css";
import { useFormik } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputSpinner from 'react-bootstrap-input-spinner';
import { initialOderInventory, OrderInventory1 } from '../orders-management/users-list/core/_models';
import { InventorySave } from '../../../modules/auth/core/_requests';
import { useAuth } from '../../../modules/auth';

const OrderInventory = ({ id }: any) => {
  const params = useParams();
  const { currentUser } = useAuth();
  const [battery_info, setBattery_info] = useState('excide');
  const [inventory_check, setInventory_check] = useState(['idol']);
  const [car_document, setCar_document] = useState(['no_document']);
  const [exterior_photos, setExteriorPhotos] = useState([]);
  const [interior_photos, setInteriorPhotos] = useState([]);
  const battery_info_array = ['exide', 'amaron', 'sf_sonic', 'tata_green', 'ac_delco', 'varta', 'other'];
  const inventory_check_array = ['perfume', 'engine_shield', 'idol', 'fog_lamp', 'usb', 'lighter', 'speaker', 'jack_set', 'chargers', 'tool_kit', 'spare_wheel', 'woofers'];
  const car_document_array = ['RC', 'PUC', 'insurance', 'road_tax', 'passenger_tax', 'no_document'];

  // const [startDate, setStartDate] = useState(new Date());

  // const [total,setTotal] = useState(0);

  function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here 
    // is better than directly setting `setValue(value + 1)`
  }
  const navigate = useNavigate();
  const forceupdate = useForceUpdate();

  const [quantity, setQuantity] = useState(1); // Initial quantity set to 1

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const [order, setOrder] = useState<OrderInventory1>();
  const [orderloaded, setorderloaded] = useState(true);
  const [disabledSubmit, setdisabledSubmit] = useState(false);

  // const [startDate, setStartDate] = useState(new Date());

  // const [total,setTotal] = useState(0);
  useEffect(() => {

    if (orderloaded) {
      getOrder();
      setorderloaded(false);
    }

  }, [])


  const getOrder = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-order-inventory/${id}`);
    
    if (response.data) {
      setOrder({ ...response.data, car_document: response.data.car_documents ? response.data.car_documents.split(',') : [], interior_photos: response.data.interior_photos ? response.data.interior_photos.split(',') : [], exterior_photos: response.data.exterior_photos ? response.data.exterior_photos.split(',') : [] });
      setInventory_check(response.data.inventory_check ? response.data.inventory_check.split(',') : []);
      setBattery_info(response.data.battery_info);
      setInterior(response.data.interior_photos ? response.data.interior_photos.split(',') : []);
      setExterior(response.data.exterior_photos ? response.data.exterior_photos.split(',') : []);
      setCar_document(response.data.car_documents ? response.data.car_documents.split(',') : []);
      forceupdate();
    }

    // formik.setFieldValue('car_document', [response.data.car_documents.split(',')])
  }


  // const [total,setTotal] = useState(0);



  const formik = useFormik({
    initialValues: { ...order, order_id: id, fuel_meter: order?.fuel_meter ? order.fuel_meter : 0, transmission: order?.transmission ? order.transmission : 'NA' },

    enableReinitialize: true,
    onSubmit: async (values) => {
      setdisabledSubmit(true);
      const token = localStorage.getItem("token");
      let formData = new FormData();

      [...exterior_photos].forEach(image => {
        formData.append("exterior_photos[]", image);
      });

      [...interior_photos].forEach(image => {
        formData.append("interior_photos[]", image);
      });

      Object.entries(values).forEach(([key, value]) => {
        formData.append(`${key}`, `${value}`);

      });

      const response = await axios.post(InventorySave,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.status == 1) {
        toast.success("Inventory Updated");
         setdisabledSubmit(false)
      } else {
        toast.error(response.data.message);
         setdisabledSubmit(false)
      }
    },
  });



  const handleChange = (e: any) => {
    // Destructuring

    const { value, checked } = e.target;
    // Case 1 : The user checks the box
    if (checked) {
      setInventory_check([...inventory_check, value]);
      formik.setFieldValue('inventory_check', [...inventory_check, value]);

      // alert(formik.values.inventory_check);
    }

    // Case 2  : The user unchecks the box
    else {
      setInventory_check(
        inventory_check.filter((e: any) => e !== value)
      );
      formik.setFieldValue('inventory_check', inventory_check.filter((e: any) => e !== value));


    }
  };


  const handleChangeDocument = (e: any) => {
    // Destructuring

    const { value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      if (value === 'no_document') {
        setCar_document(['no_document']);
        formik.setFieldValue('car_document', ['no_document']);
      }
      else {
        setCar_document([...car_document, value]);
        formik.setFieldValue('car_document', [...car_document, value]);
        // setCar_document(
        //   car_document.filter((e: any) => e !== 'no_document')
        // );
        // formik.setFieldValue('car_document', car_document.filter((e: any) => e !== 'no_document'));
      }
      // alert(formik.values.car_document);

    }

    // Case 2  : The user unchecks the box
    else {

      setCar_document(
        car_document.filter((e: any) => e !== value)
      );
      formik.setFieldValue('car_document', car_document.filter((e: any) => e !== value));
    }
  };



  // const [selectedFile, setSelectedFile] = useState()
  // const [preview, setPreview] = useState()

  // create a preview as a side effect, whenever selected file is changed
  // useEffect(() => {
  //     if (!selectedFile) {
  //         setPreview(undefined)
  //         return
  //     }

  //     const objectUrl : any = URL.createObjectURL(selectedFile)
  //     setPreview(objectUrl)

  //     // free memory when ever this component is unmounted
  //     return () => URL.revokeObjectURL(objectUrl)
  // }, [selectedFile])

  // const onSelectFile = (e:any) => {
  //     if (!e.target.files || e.target.files.length === 0) {
  //         setSelectedFile(undefined)
  //         return
  //     }
  
  //     // I've kept this example simple by using the first image instead of multiple
  //     setSelectedFile(e.target.files[1])
  // }

  const [exterior, setExterior] = useState<any>([]);

  const uploadSingleExterior = (e: any) => {
    let ImagesArray = Object.entries(e.target.files).map((e: any) =>
      URL.createObjectURL(e[1])
    );

    setExterior([...exterior, ...ImagesArray]);
    setExteriorPhotos(e.target.files);
    // formik.setFieldValue('exterior_photos',[e.target.files]);

  }

  const exteriorUpload = (e: any) => {
    e.preventDefault();

  }

  const deleteExterior = (e: any) => {
    const s = exterior.filter((item: any, index: any) => index !== e);
    setExterior(s);
    // formik.setFieldValue('exterior_photos',[...s]);
  }


  const [interior, setInterior] = useState<any>([]);

  const uploadSingleInterior = (e: any) => {
    let ImagesArray = Object.entries(e.target.files).map((e: any) =>
      URL.createObjectURL(e[1])
    );

    setInterior([...interior, ...ImagesArray]);
    setInteriorPhotos(e.target.files);
    // formik.setFieldValue('interior_photos',[e.target.files]);
  }

  const interiorUpload = (e: any) => {
    e.preventDefault();

  }

  const deleteInterior = (e: any) => {
    const s = interior.filter((item: any, index: any) => index !== e);
    setInterior(s);
    // formik.setFieldValue('interior_photos',[...s]);
  }

 function scrollToElementAbove(elementId: string) {
        const element = document.getElementById(elementId);
        if (element) {
            const offset = -100; // Adjust this value according to your preference
            const elementTop = element.getBoundingClientRect().top;
            window.scrollTo({
                top: window.pageYOffset + elementTop + offset,
                behavior: 'smooth'
            });
        } else {
            console.log(`Element with id '${elementId}' not found`);
        }
    }

    const handleScrollError = () => {
         const errorKeys = Object.keys(formik.errors);
            if (errorKeys.length > 0) {
                const firstErrorKey = errorKeys[0];
                scrollToElementAbove(firstErrorKey);
            }
    }
    

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card my-5 mx-3 p-2">
          <div className="row">
            <h3>Inventory Count</h3>
          </div>
          <div className="row">

            <div className="col-sm-6 my-2">
              <div className="row">
                <div className="col-6 mx-sm-auto col-sm-4">
                  <div className="form-group">
                    <label htmlFor="">Head Rest</label>
                    <div className="d-flex">
                      <button type='button' className='btn btn-dark btn-sm' onClick={()=>{
                           formik.setFieldValue('head_rest',formik.values.head_rest ? formik.values.head_rest + 1 : 1);
                        }}>+</button>
                      <input type="text" onChange={(e) => formik.setFieldValue('head_rest',e.target.value)} className='form-control form-control-sm w-25' value={formik.values.head_rest ? formik.values.head_rest : 0 } />
                      <button type='button' className='btn btn-dark btn-sm' onClick={()=>{
                           formik.setFieldValue('head_rest',formik.values.head_rest ? formik.values.head_rest - 1 : 1);
                        }}>-</button>
                    </div>
                  </div>
                </div>

                <div className="col-6 mx-sm-auto col-sm-4">
                  <div className="form-group">
                    <label htmlFor="">Floor Mats</label>
                    <div className="d-flex">
                      <button type='button' className='btn btn-dark btn-sm' onClick={()=>{
                           formik.setFieldValue('floor_mats',formik.values.floor_mats ? formik.values.floor_mats + 1 : 1);
                        }}>+</button>
                      <input type="text" onChange={(e) => formik.setFieldValue('floor_mats',e.target.value)} className='form-control form-control-sm w-25' value={formik.values.floor_mats ? formik.values.floor_mats : 0 } />
                      <button type='button' className='btn btn-dark btn-sm' onClick={()=>{
                           formik.setFieldValue('floor_mats',formik.values.floor_mats ? formik.values.floor_mats - 1 : 1);
                        }}>-</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 my-2">
              <div className="row">
                <div className="col-6 mx-sm-auto col-sm-4">
                  <div className="form-group">
                    <label htmlFor="">Wheel Cap</label>
                    <div className="d-flex">
                      <button type='button' className='btn btn-dark btn-sm' onClick={()=>{
                           formik.setFieldValue('wheel_cap',formik.values.wheel_cap ? formik.values.wheel_cap + 1 : 1);
                        }}>+</button>
                      <input type="text" onChange={(e) => formik.setFieldValue('wheel_cap',e.target.value)} className='form-control form-control-sm w-25' value={formik.values.wheel_cap ? formik.values.wheel_cap : 0 } />
                      <button type='button' className='btn btn-dark btn-sm' onClick={()=>{
                           formik.setFieldValue('wheel_cap',formik.values.wheel_cap ? formik.values.wheel_cap - 1 : 1);
                        }}>-</button>
                    </div>
                  </div>
                </div>
                <div className="col-6 mx-sm-auto col-sm-4">
                  <div className="form-group">
                    <label htmlFor="">Mud Flap</label>
                    <div className="d-flex">
                      <button type='button' className='btn btn-dark btn-sm' onClick={()=>{
                           formik.setFieldValue('mud_flap',formik.values.mud_flap ? formik.values.mud_flap + 1 : 1);
                        }}>+</button>
                      <input type="text" onChange={(e) => formik.setFieldValue('mud_flap',e.target.value)} className='form-control form-control-sm w-25' value={formik.values.mud_flap ? formik.values.mud_flap : 0 } />
                      <button type='button' className='btn btn-dark btn-sm' onClick={()=>{
                        
                           formik.setFieldValue('mud_flap',formik.values.mud_flap ? formik.values.mud_flap - 1 : 1);
                        }}>-</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3 ">
            <h3>Battery Info <span className='text-danger'>*</span></h3>
          </div>
          <div className="col-md-12 mt-3">
            <div className="form-inline">
              {battery_info_array.map((battery_item) => (
                <span key={battery_item} className="mx-auto">
                  <>
                    
                    <input
                      key={battery_item}
                      id={battery_item}
                      type="radio"
                      className='ms-5 d-none me-2'
                      name="battery_info"
                      onChange={(e) => {

                        setBattery_info(battery_item);
                        formik.setFieldValue('battery_info', battery_item);
                        
                      }}
                      onBlur={formik.handleBlur}
                      value={battery_item}
                    />

                    <label htmlFor={battery_item} className={`btn btn-${battery_info === battery_item ? 'primary' : 'secondary'} mx-3 rounded btn-sm`}> {battery_item} </label>
                  </>
                </span>
              ))}


              {formik.touched.battery_info && formik.errors.battery_info ? (
                <span className="text-danger">
                  {formik.errors.battery_info}
                </span>
              ) : null}
            </div>
          </div>
          <div className="row mt-3">
            <h3>Inventory Check<span className='text-danger'>*</span></h3>
          </div>
          <div className="col-md-12 mt-3">
            <div className="form-inline">
              {inventory_check_array.map((inventory_item) => (
                <span key={inventory_item} className="mx-auto">
                  <>
                    
                    <input
                      key={inventory_item}
                      id={inventory_item}
                      type="checkbox"
                      checked={inventory_check.includes(inventory_item) ? true : false}
                      className='ms-5 d-none me-2'
                      name="inventory_check[]"
                      onChange={(e) => { handleChange(e); }}
                      onBlur={formik.handleBlur}
                      value={inventory_item}
                    />

                    <label htmlFor={inventory_item} className={`btn my-1 btn-${inventory_check.includes(inventory_item) ? 'primary' : 'secondary'} mx-3 rounded btn-sm`}> {inventory_item} </label>
                  </>
                </span>
              ))}


              {formik.touched.inventory_check && formik.errors.inventory_check ? (
                <span className="text-danger">
                  {formik.errors.inventory_check}
                </span>
              ) : null}
            </div>
          </div>
          <div className="row my-3">
            <h3>Other Inventory</h3>
          </div>
          <div className="row">
            <div className="form-group">
              <textarea name="other_inventory" className='form-control' id="" placeholder='Enter Other Inventory' rows={4} value={formik.values.other_inventory ? formik.values.other_inventory : '' } onChange={(e) => formik.setFieldValue('other_inventory', e.target.value)}></textarea>
            </div>
          </div>
        </div>

        {/* Car photos */}
        <div className="card my-5 mx-3 p-2">
          <div className="row mb-3">
            <h3>Car photos</h3>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <label htmlFor="">Exterior Photos</label>
              <input type="file" name="exterior_photos" multiple={true} id="exterior_photos" className='form-control' onChange={(e) => uploadSingleExterior(e)} />
              {exterior.length > 0 &&
                exterior.map((item: any, index: any) => {
                  return (
                    <div key={index}>
                      <div style={{ position: 'relative', height: '100px', width: '100px', float: 'left', margin: '5px' }}>
                        <img src={item} height={100} width={100} alt="" className='rounded border border-dark shadow' />
                        <button type="button" className='rounded btn btn-dark px-2 py-0' style={{ position: 'absolute', top: '0px', right: '0px' }} onClick={() => deleteExterior(index)}>
                          X
                        </button>
                      </div>

                    </div>
                  );
                })}
            </div>
            <div className="col-sm-6">
              <label htmlFor="">Interior Photos</label>
              <input type="file" name="interior_photos" multiple={true} id="interior_photos" className='form-control' onChange={(e) => uploadSingleInterior(e)} />
              {interior.length > 0 &&
                interior.map((item: any, index: any) => {
                  return (
                    <div key={index}>
                      <div style={{ position: 'relative', height: '100px', width: '100px', float: 'left', margin: '5px' }}>
                        <img src={item} height={100} width={100} alt="" className='rounded border border-dark shadow' />
                        <button type="button" className='rounded btn btn-dark px-2 py-0' style={{ position: 'absolute', top: '0px', right: '0px' }} onClick={() => deleteInterior(index)}>
                          X
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="card my-5 mx-3 p-2">
          <div className="row mt-3">
            <h3>Car Documents<span className='text-danger'>*</span></h3>
          </div>
          <div className="col-md-12 mt-3">
            <div className="form-inline">
              {car_document_array.map((car_document_item) => (
                <span key={car_document_item} className="mx-auto">
                  <>
                    
                    <input
                      key={car_document_item}
                      id={car_document_item}
                      type="checkbox"
                      checked={car_document.includes(car_document_item) ? true : false}
                      className='ms-5 d-none me-2'
                      name="car_document[]"
                      onChange={(e) => { handleChangeDocument(e); }}
                      onBlur={formik.handleBlur}
                      value={car_document_item}
                    />

                    <label htmlFor={car_document_item} className={`btn my-1 btn-${car_document.includes(car_document_item) ? 'primary' : 'secondary'} mx-3 rounded btn-sm`}> {car_document_item} </label>
                  </>
                </span>
              ))}


              {formik.touched.car_documents && formik.errors.car_documents ? (
                <span className="text-danger">
                  {formik.errors.car_documents}
                </span>
              ) : null}
            </div>
          </div>
        </div>

        {currentUser?.permissions.find((item: any) => item.name === "update_orders") ?
           <button type='submit' onClick={handleScrollError} disabled={disabledSubmit} className='btn btn-primary btn-sm'>
                        {disabledSubmit ? (<span>
                            Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>) : 'Update'}
                    </button> : null}
      </form>
    </>
  )
}

export default OrderInventory