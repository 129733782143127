
import React from 'react'

const OrderJobCard = ({ order }: any) => {

    return (
        <>
            <div className='px-3'>
                <div className="row" style={{ overflow: 'auto', height: '400px' }}>
                    {order && order.map((estimatecard: any, key: any) => (
                        <div key={key} className="col-sm-6">
                            {/* */}
                            <div className="card  my-5 p-0 rounded-0">
                                <div className="card-header ps-5">
                                    <div className="card-title">
                                        <i className="bi bi-gear" style={{ fontSize: '28px', fontWeight: '900' }}></i> &nbsp;<h3> {estimatecard.get_product?.title.toUpperCase()}</h3>
                                    </div>
                                </div>
                                <div className="card-body p-1">
                                    <table className='table m-0 text-center w-100'>
                                        <thead>
                                            <tr>
                                                <td>Price Per Item</td>
                                                <td>Quantity</td>
                                                <td>Total</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{parseFloat(estimatecard.rate).toLocaleString('en-IN', {
                                                    style: 'currency',
                                                    currency: 'INR'
                                                })}</td>
                                                <td>{estimatecard.quantity}</td>
                                                <td>{(parseFloat(estimatecard.rate) * parseInt(estimatecard.quantity)).toLocaleString('en-IN', {
                                                    style: 'currency',
                                                    currency: 'INR'
                                                })}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default OrderJobCard