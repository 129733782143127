/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import { useParams } from 'react-router-dom'
import OrderSummury from './components/OrderSummury';
import OrderDetails from './components/OrderDetails';
import OrderInventory from './components/OrderInventory';
import OrderEstimate from './components/OrderEstimate';
import OrderHealthCard from './components/OrderHealthCard';
import { ToastContainer } from 'react-toastify';
import OrderPayments from './components/OrderPayments';
import OrderHistory from './components/OrderHistory';
// import useDocumentTitle from '../../../useDocumentTitle';
import { useAuth } from '../../modules/auth';
// import OrderJobCard from './components/OrderJob';
import { useEffect, useState } from 'react';
import axios from 'axios';
import OrderJobCard from './components/OrderJobCard';
import { OrderDetailsByCr } from './orders-management/users-list/core/_models';
import { useQuery } from 'react-query';

const ViewOrder = () => {

  // useDocumentTitle("View Order");
  const {currentUser, logout} = useAuth();
  
  type T = {
    name: string
  }
  const params = useParams();


  const [order, setOrder] = useState<any>();
  const [orderDetails, setOrderDetails] = useState<any>();
  const [orderloaded, setorderloaded] = useState(true);
  const [estimate, setestimate] = useState<any>();
  const [helthcard,sethelthcardvalue] = useState<any>();
  // const [startDate, setStartDate] = useState(new Date());
  const getOrderDetails = (details:OrderDetailsByCr) => {
    setOrderDetails(details);
  }
  const getOrderEstimate = (details:any) => {
    setestimate(details);
  }

  const setheathcard = (healthcard:any) => {
    sethelthcardvalue(healthcard);
  }
  // const [total,setTotal] = useState(0);
  useEffect(() => {
  
    if (orderloaded) {
      getOrder();
      setorderloaded(false);
    }
  
  }, [])
  
  
  const getOrder = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/view-orders/${params.id}`);
    setOrder(response.data);
     localStorage.setItem('Order_Car_Brand',response.data.get_brand.name);
     localStorage.setItem('Order_Car_Model',response.data.get_model.model);
  }

  

  const { data, refetch, status } = useQuery("users", getOrder);
  console.log('data from order',order);
  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <h1 className='float-right'>Order Summary</h1>
          </div>
        </div>
        <div className="card-body p-1">

          <div>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="pills-summary-tab" data-bs-toggle="pill" data-bs-target="#pills-summary" type="button" role="tab" aria-controls="pills-summary" aria-selected="true">Order Summary</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-order-details-tab" data-bs-toggle="pill" data-bs-target="#pills-order-details" type="button" role="tab" aria-controls="pills-order-details" aria-selected="false">Order Details</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-inventory-tab" data-bs-toggle="pill" data-bs-target="#pills-inventory" type="button" role="tab" aria-controls="pills-inventory" aria-selected="false">Inventory</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-Job-tab" data-bs-toggle="pill" data-bs-target="#pills-Job" type="button" role="tab" aria-controls="pills-Job" aria-selected="false">Job Card</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-Estimate-tab" data-bs-toggle="pill" data-bs-target="#pills-Estimate" type="button" role="tab" aria-controls="pills-Estimate" aria-selected="false">Order Estimate</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-Health-tab" data-bs-toggle="pill" data-bs-target="#pills-Health" type="button" role="tab" aria-controls="pills-Health" aria-selected="false">Health Card</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-Payments-tab" data-bs-toggle="pill" data-bs-target="#pills-Payments" type="button" role="tab" aria-controls="pills-Payments" aria-selected="false">Payments</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-History-tab" data-bs-toggle="pill" data-bs-target="#pills-History" type="button" role="tab" aria-controls="pills-History" aria-selected="false">Order History</button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">

              <div className="tab-pane fade show active" id="pills-summary" role="tabpanel" aria-labelledby="pills-summary-tab">
                <OrderSummury id={params.id} order={{...order,orderDetails:orderDetails,estimate:estimate ? estimate : [],healthcard:helthcard ? helthcard : []}} />
              </div>
              <hr />
              <div className="tab-pane bg-secondary py-3 fade" id="pills-order-details" role="tabpanel" aria-labelledby="pills-order-details-tab">
                <OrderDetails id={params.id} user_id = {currentUser?.id} refetch={refetch} getOrderDetails={getOrderDetails}/>
              </div>
              <div className="tab-pane bg-secondary py-3 fade" id="pills-inventory" role="tabpanel" aria-labelledby="pills-inventory-tab">
                <OrderInventory id={params.id} />
              </div>
              <div className="tab-pane bg-secondary py-3 fade" id="pills-Job" role="tabpanel" aria-labelledby="pills-Job-tab">
                <OrderJobCard  order = {order?.get_job_card} />
              </div>
              <div className="tab-pane bg-secondary py-3 fade" id="pills-Estimate" role="tabpanel" aria-labelledby="pills-Estimate-tab">
                <OrderEstimate  id={params.id} refetch={refetch} getOrderEstimate={getOrderEstimate} user_id = {currentUser?.id} />
              </div>
              <div className="tab-pane bg-secondary py-3 fade" id="pills-Health" role="tabpanel" aria-labelledby="pills-Health-tab">
                <OrderHealthCard id={params.id} user_id = {currentUser?.id} setheathcard={setheathcard} />
              </div>
              <div className="tab-pane  bg-secondary py-3 fade" id="pills-Payments" role="tabpanel" aria-labelledby="pills-Payments-tab">
                  <OrderPayments id={params.id} status={order?.order_status ? order?.order_status : undefined} user = {currentUser} />
              </div>
            
              <div className="tab-pane  bg-secondary py-3 fade" id="pills-History" role="tabpanel" aria-labelledby="pills-History-tab">
                <OrderHistory id={params.id} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  )
}

export default ViewOrder