/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import axios from 'axios';
import React, { useEffect, useState } from 'react'


const OrderHistory = ({ id }: any) => {

    const styles = `.timeline-label:before {
        left : 0px !important;
        bottom:45px;
    }
    `;

    const [order, setOrder] = useState<any>([]);
    const [orderloaded, setorderloaded] = useState(true);

    useEffect(() => {

        if (orderloaded) {
            getOrder();
            setorderloaded(false);
        }

    }, [])


    const getOrder = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/view-orderhistory/${id}`);
        setOrder(response.data);
    }


console.log(order.some((orditem: any) => orditem.title == 'Order Complete'));
    return (
        <>
            <div className="card my-5 mx-3 p-2">
                <div className="row">
                    <h3>Order History</h3>
                </div>
                <style>{styles}</style>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card card-xl-stretch mb-xl-8">
                            <div className="card-body pt-5">

                                <div className='timeline-label'>
                                    {order && order.map((item: any, key: any) => (

                                        <div key={key} className="timeline-item">
                                            <div className="timeline-badge">
                                                <i className="fa fa-genderless text-primary fs-1" />
                                            </div>
                                            <div className="fw-mormal timeline-content text-muted ps-3">
                                                <span className="fw-bold text-gray-800 ps-3">{item.title}</span><br />
                                                <span className='fw-bold ps-3'>{item.date}</span><br />
                                                <span className='fw-bold ps-3'>{item.created_by}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default OrderHistory