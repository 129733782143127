/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {FC} from 'react'
import {QUERIES, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {Brand} from '../../core/_models'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { useQueryClient } from 'react-query'
import axios from 'axios'
import { toast } from 'react-toastify'

type Props = {
  brand: any
}

const StatusCell: FC<Props> = ({brand}) => { 
 
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  
  const changeStatus = async () => {
    const remark = window.confirm(`Are you sure to update status of ${brand.model}`);
    if (remark) {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/update-module-state`, { id: brand.id, status: brand.status === "1" ? 2 : 1, module: 'model' });
      response.data.status == 1 ? toast.success(response.data.message) : toast.error(response.data.message);
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    }
  }

  return (
  <div className='d-flex align-items-center'>
        <div className="form-check form-switch form-check-custom form-check-solid me-10">
        <input onChange={() => changeStatus()} className="form-check-input h-20px w-30px" checked = {brand.status === "1" ? true : false}  type="checkbox" value="flexSwitch20x30" id="flexSwitch20x30" />
        <label className="form-check-label" htmlFor="flexSwitch20x30">
        </label>
      </div>
    {/* begin:: Avatar */}
        {brand.status === "1" ? (
          <div className='symbol-label'>
            <span className='badge badge-success'>Active</span>
          </div>
        ) : (
            <div className='symbol-label'>
            <span className='badge badge-danger'>Inavtive</span>
          </div>
        )}
  </div>
)
        }

export {StatusCell}
