/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
import React, { useRef } from 'react'

// import "./orders.css";

import { useReactToPrint } from 'react-to-print';

import '../../../assets/printEngine.css';


const OrderSummury = ({ order }: any) => {

  const total = order.estimate ? order.estimate.reduce(
    (total: number, currentItem: { rate: string, quantity: string }) => (total = total + (currentItem.rate ? (parseFloat(currentItem.rate) * parseFloat(currentItem.quantity)) : 0)),
    0,
  ) : 0;
  const discount = order.estimate ? order.estimate.reduce(
    (discount: number, currentItem: any) => (discount = discount + (currentItem.discount ? parseFloat(currentItem.discount) : 0)),
    0,
  ) : 0;
  const tax = order.estimate ? order.estimate.reduce(
    (tax: number, currentItem: { tax: string }) => (tax = tax + (currentItem.tax ? parseFloat(currentItem.tax) : 0)),
    0,
  ) : 0;

  console.log('order estimate',order.estimate);
  const tyrearray = order?.healthcard.find((filteritem: any) => filteritem.title === 'tyres');
  const breakarray = order?.healthcard.find((filteritem: any) => filteritem.title === 'breaks');
  const batteryarray = order?.healthcard.find((filteritem: any) => filteritem.title === 'battery');

  const summary_print = useRef(null);



  const handlePrint = useReactToPrint({
    content: () => summary_print.current,
  });

  console.log('orders',order.estimate,tax,discount,total);

  return (
    <>
      <button type='button' id='print_button' onClick={handlePrint} className='btn mb-4 btn-secondary'><i className='fa fa-print'></i> Print </button>

      <div className="printBody" id="printSummary" ref={summary_print}>

        <div className="printContainer">
          <div className="page-footer">
            <div className="row">
              <div className="col-12">
                <div className="footer-container">
                  <div className="company-name">MechanicNow (Astro Mecanico Private Limited)</div>
                  <div className="text-sm-center">(This documents is electronically generated which does not require any signature.)</div>
                </div>
              </div>
            </div>
          </div>
          <table>
            <thead className="main-header">
              <tr>
                <td>
                  <div className="page-header">
                    <div className="row">
                      <div className="col-12 header-topbar">
                        <h1 className="header-topbarh1">ORDER SUMMARY</h1>
                      </div>
                    </div>
                    <div className="row header-branding">
                      <div className="col-8">
                        <div className="d-flex">
                          <div className="col-3">
                            <img src="/assets/img/logo.svg" alt="logo" />
                          </div>
                          <div className="col-9">
                            <div className="company-details">
                              <div className="company-name">
                                MECHANIC NOW
                              </div>
                              <div className="company-address">Lucknow HQ, Gomti Nagar, 226010</div>
                              <div className="company-contact">
                                contact@mechanicnow.in<br />
                                www.mechanicnow.in
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="header-inv-details d-flex justify-content-end">
                          <table className="rounded-border row-border column-border">
                            <tbody>
                              <tr>
                                <td>Date:</td>
                                <td>{order ? order.created_at : '-'}</td>
                              </tr>
                              <tr>
                                <td>Deliver Date:</td>
                                <td>{order.orderDetails && order.orderDetails?.pickup_date ? `${order.orderDetails?.pickup_date} - ${order.orderDetails?.pickup_time}` : '-'}</td>
                              </tr>
                              <tr>
                                <td>Order Id:</td>
                                <td>{`${order.order_id_pre}${order.order_id_post}`}</td>
                              </tr>
                              <tr>
                                <td>Invoice Id:</td>
                                <td>{`${order.order_id_pre}${order.order_id_post}`}</td>
                              </tr>
                            </tbody></table>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="page" style={{ pageBreakBefore: 'always', pageBreakInside: 'avoid' }}>
                    <div className="d-flex flex-wrap data-wrapper">
                      <div className="col-4 customer-details">
                        <div className="title">Customer Details</div>
                        <div className="name">{order.orderDetails ? order.orderDetails?.customer_name.toUpperCase() : (order.get_user?.name ? order.get_user?.name.toUpperCase() : '-')}</div>
                        <div className="address">{order.orderDetails?.customer_address}</div>
                        {/* <div className="mobile-number"><span>Mobile:</span> {order ? order.get_user?.primary_contact : '-'}</div> */}
                        {order.orderDetails?.GSTIN_display === 'yes' && (<div className="customer-gstin"><span>GSTIN:</span> 09ADQFS1178N1Z6</div>)}

                      </div>
                      <div className="col-4 garage-details">
                        <div className="title">Garage Details</div>
                        <div className="name">{order.get_garage?.name ? order.get_garage?.name.toUpperCase() : '-'}</div>
                        <div className="address">{order.get_garage?.address_id ? order.get_garage?.address_id.toUpperCase() : '-'}</div>
                        {order.orderDetails?.GSTIN_display === 'yes' && (<div className="garage-gstin"><span>GSTIN:</span> 09AAPFD9652E1Z0</div>)}
                      </div>
                      <div className="col-4">
                        <div className="row">
                          <div className="col-12 car-details">
                            <div className="title">Vehicle Details</div>
                          </div>
                          <div className="col-6 car-details">
                            <div className="label">Brand</div>
                            <div className="value">{order.get_brand?.name ? order.get_brand?.name.toUpperCase() : '-'}</div>
                          </div>
                          <div className="col-6 car-details">
                            <div className="label">Model</div>
                            <div className="value">{order.get_model?.model ? order.get_model?.model.toUpperCase() : '-'}</div>
                          </div>
                          <div className="col-6 car-details">
                            <div className="label">Vehicle Number</div>
                            <div className="value">{order.orderDetails?.registration_no ? order.orderDetails?.registration_no.toUpperCase() : '-'}</div>
                          </div>
                          <div className="col-6 car-details">
                            <div className="label">Odometer Reading</div>
                            <div className="value">{order.orderDetails?.odometer_reading}</div>
                          </div>
                          <div className="col-6 car-details">
                            <div className="label">Fuel Type</div>
                            <div className="value">{order.orderDetails?.fuel_type ? order.orderDetails?.fuel_type.toUpperCase() : '-'}</div>
                          </div>
                          <div className="col-6 car-details">
                            <div className="label">Color</div>
                            <div className="value">{order.orderDetails?.color ? order.orderDetails?.color.toUpperCase() : '-'}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 invoice-section">
                        <div className="bordered-section">
                          <div className="sub-title">Order Summary</div>
                          <div className="price-summary">
                            <div className="row">
                              <div className="col-4">
                                <div>Gross Amount</div>
                                {discount > 0 && (
                                  <div>Discount (Flat/Coupons)</div>
                                )}
                                {order.orderDetails?.GSTIN_display === 'yes' && (
                                  <>
                                    <div>Total CGST</div>
                                    <div>Total SGST</div>
                                    <div>Total Taxable Amount</div>
                                  </>
                                )
                                }

                                <div>Net Payable (Inclusive Of Tax)</div>
                              </div>
                              <div className="col-2">
                                <div>{total.toLocaleString('en-IN', {
                                  style: 'currency',
                                  currency: 'INR'
                                })}</div>
                                {discount > 0 && (
                                  <div>{discount.toLocaleString('en-IN', {
                                    style: 'currency',
                                    currency: 'INR'
                                  })}</div>
                                )}
                                {order.orderDetails?.GSTIN_display === 'yes' && (
                                  <>
                                    <div>{(tax / 2).toLocaleString('en-IN', {
                                      style: 'currency',
                                      currency: 'INR'
                                    })}</div>
                                    <div>{(tax / 2).toLocaleString('en-IN', {
                                      style: 'currency',
                                      currency: 'INR'
                                    })}</div>
                                    <div>{(total + tax).toLocaleString('en-IN', {
                                      style: 'currency',
                                      currency: 'INR'
                                    })}</div>
                                  </>
                                )
                                }

                                <div>{((total + tax) - discount).toLocaleString('en-IN', {
                                  style: 'currency',
                                  currency: 'INR'
                                })}</div>
                              </div>
                              <div className="col-6">
                                <div>Remarks : </div>
                                <div><strong>{order.orderDetails?.remark ? order.orderDetails?.remark : ''}</strong></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 invoice-section">
                        <div className="bordered-section">
                          <div className="sub-title">Service Summary</div>
                          <div className="service-summary">
                            <table className="row-border">
                              <thead>
                                <tr>
                                  <th>Description Of Work</th>
                                  <th>Discount (%)</th>
                                  <th>Service</th>
                                  <th>Work Done</th>
                                  <th>Quantity</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {order ? order.estimate.map((estimatecard: any, keyes: number) => {

                                  return (
                                    <React.Fragment key={keyes}>
                                      <tr>
                                        <td>{estimatecard.title ? estimatecard.title.toUpperCase() : '-'}
                                          {estimatecard.exclusive ?
                                            <>
                                              <br />
                                              <ul>
                                                {estimatecard.key_points && ( typeof estimatecard.key_points == 'string' ? estimatecard.key_points.split('|').map((exitem: string, inex: number) => <li key={inex}>{exitem}</li>) : estimatecard.key_points.map((exitem: string, inex: number) => <li key={inex}>{exitem}</li>))}
                                                {estimatecard.exclusive && ( typeof estimatecard.exclusive == 'string' ? estimatecard.exclusive.split('|').map((exitem: string, inex: number) => <li key={inex}>{exitem}</li>) : estimatecard.exclusive.map((exitem: string, inex: number) => <li key={inex}>{exitem}</li>))}
                                              </ul>

                                            </>
                                            : null
                                          }

                                        </td>
                                        <td>{estimatecard.discount != null && estimatecard.discount > 0 ? `${estimatecard.discount}%` : '-'}</td>
                                        <td>{estimatecard.brand_name ? estimatecard.brand_name.toUpperCase() : '-'}</td>
                                        <td> {estimatecard.workdone ? estimatecard.workdone.toUpperCase() : '-'}</td>
                                        <td>{estimatecard.quantity}</td>
                                        <td>{estimatecard.discount != null && estimatecard.discount > 0 ?<> <s> {parseFloat(estimatecard.price).toLocaleString('en-IN', {
                                          style: 'currency',
                                          currency: 'INR'
                                        })}</s> <span> {parseFloat(estimatecard.total_amount).toLocaleString('en-IN', {
                                          style: 'currency',
                                          currency: 'INR'
                                        })}</span> </>: parseFloat(estimatecard.total_amount).toLocaleString('en-IN', {
                                          style: 'currency',
                                          currency: 'INR'
                                        })}</td>
                                      </tr>

                                    </React.Fragment>
                                  )
                                }) : null}


                                <tr>
                                  <td colSpan={5}><strong>Grand Total</strong></td>
                                  <td><strong> {total.toLocaleString('en-IN', {
                                    style: 'currency',
                                    currency: 'INR'
                                  })} </strong></td>
                                </tr>
                                {discount > 0 && (
                                  <>
                                   <tr>
                                  <td colSpan={5}><strong>Discount</strong></td>
                                  <td><strong> {discount.toLocaleString('en-IN', {
                                    style: 'currency',
                                    currency: 'INR'
                                  })} </strong></td>
                                </tr>
                                 <tr>
                                  <td colSpan={5}><strong>Net Amount</strong></td>
                                  <td><strong> {(total - discount).toLocaleString('en-IN', {
                                    style: 'currency',
                                    currency: 'INR'
                                  })} </strong></td>
                                </tr>
                                </>
                                )}
                               


                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="page" style={{ pageBreakBefore: 'always', pageBreakInside: 'avoid' }}>
                    <div className="row">
                      <div className="col-12 header-topbar">
                        <h1 className="header-topbarh1">HEALTH CARD</h1>
                      </div>
                    </div>
                    <div className="health-card">
                      <div className="row">
                        <div className="col-4"><span className="dot-danger bg-danger" /> Requires Immediate Attention</div>
                        <div className="col-4"><span className="dot-warning bg-warning" /> Will Require Further Attention</div>
                        <div className="col-4"><span className="dot-success bg-success" /> Everything Seems Good</div>
                      </div>
                      <div className="row gx-1">
                        <div className="col-7">
                          <div className="bordered-section">
                            <div className="sub-title">Service Summary</div>

                            {order ? order.healthcard.map((item: any, keyhl: number) => {
                              return (
                                <React.Fragment key={keyhl}>
                                  {item.title != 'tyres' && item.title != 'breaks' && item.title != 'battery' ?
                                    <div className="row mt-1" >
                                      <div className="col-12">
                                        <table className="row-border column-border rounded-border">
                                          <tbody>
                                            <tr>
                                              <th rowSpan={item.questions.length + 1}><span className="text-vertical">{item.title ? item.title.toUpperCase() : ''}</span></th>
                                              <th colSpan={5}></th>

                                            </tr>
                                            {item.questions && item.questions.map((question_item: any, keyqu: number) => {

                                              return (
                                                <tr key={keyqu}>
                                                  <td width={20}><span className={`dot-danger ${question_item.selected_option === 'attention' ? 'bg-danger' : ''}`} /></td>
                                                  <td width={20}><span className={`dot-warning ${question_item.selected_option === 'further_attention' ? 'bg-warning' : ''}`} /></td>
                                                  <td width={20}><span className={`dot-success ${question_item.selected_option === 'good' ? 'bg-success' : ''}`} /></td>
                                                  <td width={900}>{question_item.question}</td>

                                                </tr>
                                              )
                                            })}

                                          </tbody></table>
                                      </div>
                                    </div> : null}

                                </React.Fragment>
                              )
                            }) : null}
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="bordered-section">
                            <div className="row">
                              <div className="col-12">
                                <div className="sub-title">Check Tyres / Measure Tyre Tread Depth</div>
                                <table className="column-border row-border">
                                  <tbody><tr>
                                    <td>LF</td>
                                    <td><strong className='text-underline'>{tyrearray ? tyrearray?.questions.find((questionfilter: any) => questionfilter.question === 'left front').selected_option : '________'}</strong></td>
                                    <td rowSpan={9}><img src="/assets/img/tyre.png" width="100px" /></td>
                                    <td><strong>{tyrearray ? tyrearray?.questions.find((questionfilter: any) => questionfilter.question === 'right front').selected_option : '________'}</strong></td>
                                    <td>RF</td>
                                  </tr>
                                    <tr>
                                      <td className="bg-success" />
                                      <td>&gt; 6/32"</td>
                                      <td>&gt; 6/32"</td>
                                      <td className="bg-success" />
                                    </tr>
                                    <tr>
                                      <td className="bg-warning" />
                                      <td>4/32"-6/32"</td>
                                      <td>4/32"-6/32"</td>
                                      <td className="bg-warning" />
                                    </tr>
                                    <tr>
                                      <td className="bg-danger" />
                                      <td>≤ 3/32"</td>
                                      <td>≤ 3/32"</td>
                                      <td className="bg-danger" />
                                    </tr>
                                    <tr>
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                    </tr>
                                    <tr>
                                      <td className="bg-success" />
                                      <td>&gt; 6/32"</td>
                                      <td>&gt; 6/32"</td>
                                      <td className="bg-success" />
                                    </tr>
                                    <tr>
                                      <td className="bg-warning" />
                                      <td>4/32"-6/32"</td>
                                      <td>4/32"-6/32"</td>
                                      <td className="bg-warning" />
                                    </tr>
                                    <tr>
                                      <td className="bg-danger" />
                                      <td>≤ 3/32"</td>
                                      <td>≤ 3/32"</td>
                                      <td className="bg-danger" />
                                    </tr>
                                    <tr>
                                      <td>LR</td>
                                      <td><strong>{tyrearray ? tyrearray?.questions.find((questionfilter: any) => questionfilter.question === 'left rear').selected_option : '________'}</strong></td>
                                      <td><strong>{tyrearray ? tyrearray?.questions.find((questionfilter: any) => questionfilter.question === 'right rear').selected_option : '________'}</strong></td>
                                      <td>RR</td>
                                    </tr>
                                    <tr>
                                      <td colSpan={3}>Tire pressure set to factory recommendations</td>
                                      <td colSpan={2}>Declined Tire</td>
                                    </tr>
                                  </tbody></table>
                              </div>
                            </div>
                          </div>
                          <div className="bordered-section">
                            <div className="row">
                              <div className="col-12">
                                <div className="sub-title">Measure Front / Rear Brake Linings</div>
                                <table className="column-border row-border">
                                  <tbody><tr>
                                    <td>LF</td>
                                    <td><strong>{breakarray ? breakarray?.questions.find((questionfilter: any) => questionfilter.question === 'left front').selected_option : '________'}</strong></td>
                                    <td rowSpan={9}><img src="/assets/img/break.png" /></td>
                                    <td><strong>{breakarray ? breakarray?.questions.find((questionfilter: any) => questionfilter.question === 'right front').selected_option : '________'}</strong></td>
                                    <td>RF</td>
                                  </tr>
                                    <tr>
                                      <td className="bg-success" />
                                      <td>50%</td>
                                      <td>50%</td>
                                      <td className="bg-success" />
                                    </tr>
                                    <tr>
                                      <td className="bg-warning" />
                                      <td>20% - 50%</td>
                                      <td>20% - 50%</td>
                                      <td className="bg-warning" />
                                    </tr>
                                    <tr>
                                      <td className="bg-danger" />
                                      <td>0%</td>
                                      <td>0%</td>
                                      <td className="bg-danger" />
                                    </tr>
                                    <tr>
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                    </tr>
                                    <tr>
                                      <td className="bg-success" />
                                      <td>50%</td>
                                      <td>50%</td>
                                      <td className="bg-success" />
                                    </tr>
                                    <tr>
                                      <td className="bg-warning" />
                                      <td>20% - 50%</td>
                                      <td>20% - 50%</td>
                                      <td className="bg-warning" />
                                    </tr>
                                    <tr>
                                      <td className="bg-danger" />
                                      <td>0%</td>
                                      <td>0%</td>
                                      <td className="bg-danger" />
                                    </tr>
                                    <tr>
                                      <td>LR</td>
                                      <td><strong>{breakarray ? breakarray?.questions.find((questionfilter: any) => questionfilter.question === 'right front').selected_option : '________'}</strong></td>
                                      <td><strong>{breakarray ? breakarray?.questions.find((questionfilter: any) => questionfilter.question === 'right rear').selected_option : '________'}</strong></td>
                                      <td>RR</td>
                                    </tr>
                                    <tr>
                                      <td colSpan={3}>Tire pressure set to factory recommendations</td>
                                      <td colSpan={2}>Declined Tire</td>
                                    </tr>
                                  </tbody></table>
                              </div>
                            </div>
                          </div>
                          <div className="bordered-section">
                            <div className="row">
                              <div className="col-12">
                                <div className="sub-title">Check Battery Performance</div>
                                <table>
                                  <tbody><tr>
                                    <td colSpan={2}>State of Health <strong>({batteryarray ? batteryarray?.questions.find((questionfilter: any) => questionfilter.question === 'right rear').selected_option : '________'})</strong></td>

                                    <td rowSpan={9}><img src="/assets/img/battery.png" /></td>
                                    <td rowSpan={9} style={{ width: 120, textAlign: 'end' }}>
                                      <span>Actual Cold Cranking Amps and/or attach Test results (excludes i-ELOOP equipped)</span>
                                    </td>
                                  </tr>

                                    <tr>
                                      <td className="bg-success">&nbsp;&nbsp;</td>
                                      <td>100%</td>
                                    </tr>

                                    <tr>
                                      <td className="bg-warning">&nbsp;&nbsp;</td>
                                      <td />
                                    </tr>
                                    <tr>
                                      <td className="bg-danger">&nbsp;&nbsp;</td>
                                      <td>0%</td>
                                    </tr>

                                  </tbody></table>
                              </div>
                            </div>
                          </div>
                          <div className="bordered-section">
                            <div className="row">
                              <div className="col-12">
                                <div className="sub-title">Exterior</div>
                                <img src="/assets/img/exterior.png" style={{ width: 320 }} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="page" style={{ pageBreakBefore: 'always', pageBreakInside: 'avoid' }}>
                    <div className="row">
                      <div className="col-12 header-topbar">
                        <h1 className="header-topbarh1">TERMS AND CONDITIONS</h1>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="bordered-section tnc">
                          <div className="sub-title">Warranty</div>
                          <div className="description">
                            <div className="fw-bold">Service Warranty</div>
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              One Month/ 1000 kms unconditional warranty across Periodic Service like Car Service, AC Service, etc.
                            </span>
                            <div className="fw-bold mt-1">Paint Warranty</div>
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              Mechanic Now offers a Paint Warranty of up to 3 years. This Warranty is also applicable in case of Defective Detailing Service (Coating, PPF etc.)
                            </span>
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              Warranty does not apply for Paint and Detailing service (Coating, PPF etc.) defects arising from stone or similar chipping, tree sap, hail damage, windstorm damage, chemical/industrial fallout, salt spray, bird/animal droppings, or any other environmental condition.
                            </span>
                            <div className="fw-bold mt-1">Fitment Warranty</div>
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              If the fitments such as Brake, Horn, Lights and Windshields are found to be defective, will replace them free of cost.<br />(wear and electrical fault is not warrantable)
                            </span>
                            <div className="fw-bold mt-1">Manufacturing Warranty</div>
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              We pass on the Manufacturing Warranty on all Spares Parts to our Customers.
                            </span>
                          </div>
                        </div>
                        <div className="bordered-section tnc">
                          <div className="sub-title">Terms &amp; Conditions</div>
                          <div className="description">
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              Any issues faced post service/spare part Installation done at any workshop outside Mechanic Now will not be covered.
                            </span>
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              Scratches, Dents or Paint damage caused post service will not be covered.
                            </span>
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              Short-circuit or damage due to aftermarket electrical fitting will not be covered.
                            </span>
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              Vehicle will be driven by Mechanic Now Workshop employees at the owners risk and responsibility for testing and or inspection purposes.
                            </span>
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              Mechanic Now is not responsible for loss or damage to vehicle or damage to vehicle (s) / aggregate (s) or article (s) left in vehicle arising from fire, theft or any accident or any other cause beyond.
                            </span>
                          </div>
                        </div>
                        <div className="bordered-section tnc">
                          <div className="sub-title">Disclaimer</div>
                          <div className="description">
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              Prices are inclusive of all applicable taxes.
                            </span>
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              This is only a summary of the order placed by you on Mechanic Now platform and not a tax Invoice. The Tax Invoice shall be provided to you by the workshop directly.
                            </span>
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              Colour of Engine Oil might turn black post service in Diesel Cars.
                            </span>
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              Safety and Warranty Fees is a minimal fee that covers Pickup &amp; Drop Warranty &amp; Upskilling of Workshop Partners.
                            </span>
                            <span>
                              <i className="fa-regular fa-hand-point-right" />
                              The warranty periods noted above indicate that a vehicle warranty is valid until expiry of the stated period, whichever occurs first.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default OrderSummury
