import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { resetPassword } from '../core/_requests'


const forgotPasswordSchema = Yup.object().shape({
   password:Yup.string().required('Password is required').min(8,"Minimum 8 characters required"),
   token:Yup.string().required('token is required'),
   confirm_password : Yup.string().required('confirm password is required').min(8,"Minimum 8 characters required")
})

export function ResetPassword() {
    const { token } = useParams();
const navigate = useNavigate();
    const initialValues = {
        password: '',
        token: token ? token : '',
        confirm_password : ''
    }
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(() => {
                resetPassword(values)
                    .then(({ data: { result } }) => {
                        setHasErrors(false)
                        setLoading(false)
                        navigate('/auth')
                    })
                    .catch(() => {
                        setHasErrors(true)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('The login detail is incorrect')
                    })
            }, 1000)
        },
    })

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Reset Password ?</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6'>
                    Set New Password for your email.
                </div>
                {/* end::Link */}
            </div>

            {/* begin::Title */}
            {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        Sorry, looks like there are some errors detected, please try again.
                    </div>
                </div>
            )}

            {hasErrors === false && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>Password reset successfully. Please Login <Link to={'/auth'}> here. </Link> </div>
                </div>
            )}
             {formik.touched.token && formik.errors.token && (
                    <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>{formik.errors.token}</div>
                </div>
                )}
           
            {/* end::Title */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>New password</label>
                <input
                    type='password'
                    placeholder='Enter new password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.password && formik.errors.password },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm password</label>
                <input
                    type='password'
                    placeholder='Confirm password'
                    autoComplete='off'
                    {...formik.getFieldProps('confirm_password')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password },
                        {
                            'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
                        }
                    )}
                />
                {formik.touched.confirm_password && formik.errors.confirm_password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.confirm_password}</span>
                        </div>
                    </div>
                )}
            </div>
           
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
                    <span className='indicator-label'>Submit</span>
                    {loading && (
                        <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-light'
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        Cancel
                    </button>
                </Link>{' '}
            </div>
            {/* end::Form group */}
        </form>
    )
}
