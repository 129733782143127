// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import { UserInfoLogo } from './UserInfoLogo'
import { StatusCell } from './StatusCell'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Logo' className='min-w-125px' />,
  //   accessor: 'logo',
  //   Cell: ({...props}) => <UserInfoLogo brand={props.data[props.row.index]} />,
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].name} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Email' className='min-w-125px' />,
    accessor: 'email',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].email} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Contact' className='min-w-125px' />,
    accessor: 'contact',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].contact} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    accessor: 'status',
    Cell: ({...props}) => <UserInfoLogo brand={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Reamrk' className='min-w-125px' />,
    accessor: 'remark',
    // Cell: ({...props}) => <UserInfoCell brand={props.data[props.row.index].remark} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => props.data[props.row.index].status != 4 && props.data[props.row.index].status != 3 ? <UserActionsCell user={props.data[props.row.index]} /> : null,
  },
]

export {usersColumns}
