/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from 'react'
import { isNotEmpty } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import axios from 'axios'
import { toast } from 'react-toastify'
import { ADD_REVIEWS, SERVICE_CATEGORY_DATA } from '../../../../../modules/auth/core/_requests'

type Props = {
  isUserLoading: boolean
  user: any
}

interface Option {
  id: string;
  name: string;
}
type Service = {
  id: number;
  name: string;
  key_points: string;
  price: number;
  offer: number;
  description: string;
  category_id: string;
  category_name: string;
}
const UserEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()


  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const [options, setOptions] = useState<Option[]>([]);
  const [name, setName] = useState(user.name ? user.name : '');
  const [user_id, setUserId] = useState(user.user_id ? user.user_id : '');
  const [designation, setDesignation] = useState(user.designation ? user.designation : '');
  const [image, setImage] = useState<File | null>(null);
  const [feedback, setFeedback] = useState(user.review ? user.review : '');
  const [rating, setRating] = useState(user.rating ? user.rating : '');
 
  const [previousImage, setPreviousImage] = useState(user.image ? user.image : null);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {

      const formData = new FormData();
      formData.append('name', name);
      formData.append('designation', designation);

      if (image) {
        formData.append('image', image);
      }

      formData.append('feedback', feedback);
      formData.append('rating', rating);
     
      if (isNotEmpty(user.id)) {
        formData.append('id', `${user.id}`);
      }

      const response = await axios.post(ADD_REVIEWS, formData);

      if (response.data.status == 1) {
        toast.success(response.data.message);
        cancel(true)
      }
      else {
        toast.error(response.data.message);
      }

    } catch (error) {

    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className='row justify-content-center ' method="POST" encType="multipart/form-data">

        <div className="form-group mb-4">
          <label className='fw-semibold required fs-6' htmlFor='name'>Name: </label>

          <input type="text" id='name' className='form-control' value={name} onChange={(event) => setName(event.target.value)} required />

        </div>

        <div className="form-group mb-4">
          <label className='fw-semibold fs-6' htmlFor='image'>
            Image:
          </label>
          <input id='image' type="file" className='form-control' onChange={(event) => setImage(event.target.files?.[0] || null)} />
          {previousImage && <img src={previousImage} className='rounded' height={100} alt="" />}
        </div>
        <div className="form-group mb-4">
          <label className='fw-semibold fs-6'>
           Designation:
          </label>
          <input  type="text" className='form-control' value={designation} onChange={(event) => setDesignation(event.target.value)} />
        </div>
        
        <div className="form-group mb-4 ">
          <label className='fw-semibold required fs-6'>
             Rating:
          </label>
          <select className="form-control" name="" id="" value={rating} onChange={(event) => setRating(event.target.value)}>
            <option value="Select">Select any type</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>

       

        <div className="form-group mb-4">
          <label className='fw-semibold fs-6' htmlFor="exampleFormControlTextarea1">Review</label>
          <textarea value={feedback} onChange={(event) => setFeedback(event.target.value)} className="form-control" id="exampleFormControlTextarea1" rows={3}></textarea>
        </div>
       
        <button type="submit" className='mt-4 btn btn-primary col-5'>Submit</button>
      </form>
      {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
      {/* <ToastContainer /> */}
    </>
  )
}

export { UserEditModalForm }
