import {KTIcon} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider';
import {UsersListFilter} from './UsersListFilter'
import * as XLSX from 'xlsx';

const UsersListToolbar = () => {
  const {response} = useQueryResponse();
  const data = response?.data ? response?.data.map((item : any,index:number) => (
    {
      Sr: `${index+1}`,
      Name: `${item.title}`.toUpperCase(),
      Category : item.category_name
    }
    )) : [];
  const downloadxls = (e: any, data: any) => {
    
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `ServiceListing`);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `ServiceListing.xlsx`);
  };
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <UsersListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3' onClick={(e) => downloadxls(e,data)}>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Service
      </button>
      {/* end::Add user */}
    </div>
  )
}

export {UsersListToolbar}
