import { FC, useEffect, useState } from 'react'
import { isNotEmpty } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import axios from 'axios'
import { toast } from 'react-toastify'
import {  POST_AREAS, CITIES_DATA } from '../../../../../modules/auth/core/_requests'

type Props = {
  isUserLoading: boolean
  user: any
}
interface Option {
  id: string;
  name: string;
}


const UserEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()


  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const [name, setName] = useState(user.name ? user.name : '');
  const [parentId, setParentId] = useState(user.parent_id ? user.parent_id : null);
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    axios.get(CITIES_DATA)
      .then(response => {
        setOptions(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', `${name}`);
      if (parentId) {
        formData.append('parentId', `${parentId}`);
      }

      if (isNotEmpty(user.id)) {
        formData.append('id', `${user.id}`);
      }

      const response = await axios.post(`${POST_AREAS}`, formData);

      if (response.data.status == 1) {
        toast.success(response.data.message);
        cancel(true)
      }
      else {
        toast.error(response.data.message);
      }

    } catch (ex) {
      console.error(ex)
    }

  };
  return (
    <>
      <form onSubmit={handleSubmit} className='row justify-content-center'>

       
       
        <label className='col-8'>
          City :
          <select required className='form-control' value={parentId === null ? '' : parentId} onChange={event => setParentId(event.target.value)}>
            <option className='text-center' value="">-- Select City--</option>
            {options && options.map(option => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))}
          </select>
        </label>
         <br />
         <label className='col-8 mt-5'>
          Area:
          <input type="text" className='form-control' value={name} onChange={event => setName(event.target.value)} />
        </label>
        <br />
        <button type="submit" className='mt-4 btn btn-primary col-5'>Submit</button>
      </form>
      {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
    </>
  )
}

export { UserEditModalForm }
