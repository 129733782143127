import {KTIcon} from '../../../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider';
import * as XLSX from 'xlsx';

const UsersListToolbar = () => {
  const {response} = useQueryResponse();
  const data = response?.data ? response?.data.map((item : any,index:number) => (
    {
      Sr: `${index+1}`,
      Name: `${item.name}`.toUpperCase(),
      Contact : item.primary_contact,
      Email : item.email
    }
    )) : [];
  const downloadxls = (e: any, data: any) => {
    
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `UsersList`);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `UsersList.xlsx`);
  };


  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <UsersListFilter /> */}

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3' onClick={(e) => downloadxls(e,data)}>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      {/* <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add User
      </button> */}
      {/* end::Add user */}
    </div>
  )
}

export {UsersListToolbar}
