/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {Brand} from '../../core/_models'

// type Props = {
//   brand: Brand
// }

const StatusCell: FC = ({brand}:any) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className=''>
      
        {brand.questions && brand.questions.map((ques:any,key:any)=>(
            <p key={key}>{key+1}. {ques.question}?</p>
        ))}
      
    </div>
  </div>
)

export {StatusCell}
