// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Column } from 'react-table'
import { UserActionsCell } from './UserActionsCell'
import { UserSelectionCell } from './UserSelectionCell'
import { UserCustomHeader } from './UserCustomHeader'
import { UserSelectionHeader } from './UserSelectionHeader'
import { UserInfoLogo } from './UserInfoLogo'
import { OrderPickStatus } from './OrderPickStatus'


const usersColumns: ReadonlyArray<Column<Order>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Date' className='min-w-125px' />,
    accessor: 'created_at',
    Cell: ({ ...props }) => {
      return (
        <span>{props.row.original.order_date} <br /> <span>
        </span>{props.row.original.order_time}</span>)
    },
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Customer' className='min-w-125px' />,
    accessor: 'get_user',
    Cell: ({ ...props }) => {

      if (props.row.original.get_user != null) {
        return (
          <span>{props.row.original.get_user?.name} <br /> <span>
          </span>{props.row.original.get_user?.primary_contact}</span>)
      }
      else {
        return "--"
      }
    },
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Vehicle' className='min-w-125px' />,
    accessor: 'get_brand',
    Cell: ({ ...props }) => {

      if (props.row.original.get_brand != null) {
        return (
          <span><span>{props.row.original.get_brand?.name}</span> - <span>{props.row.original.get_model?.model}</span> <br /> <span>
          </span>{props.row.original.registration_no}</span>)
        
      }
      else {
        return "--"
      }
    },
  },

  


  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Amount' className='min-w-125px' />,
    accessor: 'total_price',
    Cell: ({ ...props }) => {

      if (props.row.original.total_price != null) {
        return (<span>Total : <span>{parseFloat(props.row.original.total_price).toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR'
        })}</span> <br />
          Paid : <span>
         {parseFloat(props.row.original.amount_paid).toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR'
        })}
          </span>
          <br />
         {props.row.original.coupon_name && <span>Coupon : {props.row.original.coupon_name} <br /> Coupon discount : {parseFloat(`${props.row.original.coupon_amount}`).toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR'
        })}</span>  }
        </span>);
      }
      else {
        return 0.00;
      }
    },
  },

  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Address' className='min-w-125px' />,
    accessor: 'pick',
    Cell: ({ ...props }) => {

      if (props.row.original.pick != null) {
        return props.row.original.pick;
      }
      else {
        return '---';
      }
    },
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
  //   id: 'name',
  //   Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
  //   accessor: 'status',
  //   Cell: ({...props}) => <StatusCell brand={props.data[props.row.index]} />,
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Garage' className='min-w-125px' />,
    accessor: 'status',
    Cell: ({ ...props }) => <UserInfoLogo brand={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    accessor: 'pickup',
    Cell: ({ ...props }) => <OrderPickStatus brand={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export { usersColumns }
