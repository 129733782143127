import { FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'

import MyTest from '../pages/mytest'
import CarBrand from '../pages/cars/CarBrand'
import { UsersListWrapper } from '../pages/cars/car-management/users-list/UsersList'
import Percentage from '../pages/Percentage'
import OrdersAll from '../pages/orders/OrdersAll'
import ExcelExport from '../pages/ExcelExport'
import HealthCards from '../pages/healthCard/HealthCards'
import Users from '../pages/users-management/Users'
import ServiceListCall from '../pages/Services/ServiceListCall'
import LeadsListing from '../pages/leads/LeadsListing'
import { LeadUpdate } from '../pages/LeadUpdate'
import CitiesListing from '../pages/cities/CitiesListing'
import { useAuth } from '../modules/auth'
import Garage from '../pages/users-management/Garage'
import FAQS from '../pages/faqs/FAQS'
import Admins from '../pages/users-management/Admins'
import Reviews from '../pages/reviews/Reviews'
import { GeneratePickupOtp } from '../modules/auth/components/GeneratePickupOtp'
import Coupons from '../pages/coupons/Coupons'
import Seos from '../pages/seos/Seos'
import Blogs from '../pages/blogs/Blogs'
import Areas from '../pages/areas/Areas'
const PrivateRoutes = () => {
  const {currentUser} = useAuth();
  
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        
        <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='generate-link/:order_id' element={<GeneratePickupOtp />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='blank' element={<UsersListWrapper />} />
        <Route path='admin/cities' element={<CitiesListing />} />
        {currentUser?.permissions.find((item: any) => item.name === "view_percentage_calculations") ? <Route path='percentage-calculations' element={<Percentage />} /> : null }
        {currentUser?.permissions.find((item: any) => item.name === "view_excel_import") ? <Route path='import-excel' element={<ExcelExport />} /> : null }
        {currentUser?.permissions.find((item: any) => item.name === "update_lead") ? <Route path='/admin/lead-management/update-lead/:id' element={<LeadUpdate />} /> : null }
        <Route
          path='/admin/user-management/*'
          element={
            <SuspensedView>
              <Users /> 
            </SuspensedView>
          }
        />
        <Route
          path='/admin/admin-management/*'
          element={
            <SuspensedView>
              <Admins /> 
            </SuspensedView>
          }
        />

        <Route
          path='/admin/garage-management/*'
          element={
            <SuspensedView>
              <Garage /> 
            </SuspensedView>
          }
        />
        <Route
          path='/admin/health-cards/*'
          element={
            <SuspensedView>
               <HealthCards />
             
            </SuspensedView>
          }
        />
        <Route
          path='/admin/reviews/*'
          element={
            <SuspensedView>
               <Reviews />
             
            </SuspensedView>
          }
        />
        <Route
          path='/admin/coupons/*'
          element={
            <SuspensedView>
               <Coupons />
             
            </SuspensedView>
          }
        />
        <Route
          path='/admin/seos/*'
          element={
            <SuspensedView>
               <Seos />
            </SuspensedView>
          }
        />
        <Route
          path='/admin/areas/*'
          element={
            <SuspensedView>
               <Areas />
            </SuspensedView>
          }
        />
         <Route
          path='/admin/blogs/*'
          element={
            <SuspensedView>
               <Blogs />
            </SuspensedView>
          }
        />
        <Route
          path='/admin/faqs/*'
          element={
            <SuspensedView>
               <FAQS />
            </SuspensedView>
          }
        />
      
       
        <Route
          path='admin/cars/*'
          element={
            <SuspensedView>
               <CarBrand />
            </SuspensedView>
          }
        />
       
        <Route
          path='/admin/manage-orders/*'
          element={
            <SuspensedView>
              <OrdersAll />
            </SuspensedView>
          }
        />

        <Route
          path='/mytest'
          element={
            <SuspensedView>
              <MyTest />
            </SuspensedView>
          }
        />
        <Route
          path='/admin/service-management/*'
          element={
            <SuspensedView>
              <ServiceListCall />
            </SuspensedView>
          }
        />
        <Route
          path='/admin/lead-management/*'
          element={
            <SuspensedView>
              <LeadsListing />
            </SuspensedView>
          }
        />
        
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
