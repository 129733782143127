/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, BrandsQueryResponse, Brand} from './_models'
import { POST_BRAND_NAME } from '../../../../../modules/auth/core/_requests'
import { toast } from 'react-toastify'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${POST_BRAND_NAME}`
const GET_USERS_URL = `${process.env.REACT_APP_API_URL}/api/car-brands`

const getUsers = (query: string): Promise<BrandsQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<BrandsQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<Brand | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Brand>>) => response.data)
    .then((response: Response<Brand>) => response.data)
}

const createUser = (user: any): Promise<any> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<Brand>>) => response.data)
    .then((response: Response<Brand>) => response.data)
}

const updateUser = (user: any): Promise<any> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<Brand>>) => response.data)
    .then((response: Response<Brand>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then((response) => {
    if(response.data.status == 1)
    {
      toast.success(response.data.message);
    }
    else
    {
      toast.error(response.data.message);
    }
  })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
 return axios.all(requests).then((response) => {
    const notdeleted = response.filter((res)=> res.data.status === 2).length;
    if(notdeleted > 0)
    {
      toast.error('Items used somewhere is not deleted');
    }
    else
    {
      toast.success("Selected deleted successfully");
    }
    
  })
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser}
