/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
import React from 'react'
import { PageTitle } from '../../_metronic/layout/core'

const MyTest = () => {

    return (
        <>
            <PageTitle breadcrumbs={[]}>{'asdfasdf'}</PageTitle>

            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">

                <div className="col-md-4">

                    <div className="card card-flush h-md-100">

                        <div className="card-header">

                            <div className="card-title">
                                <h2>Administrator</h2>
                            </div>

                        </div>


                        <div className="card-body pt-1">

                            <div className="fw-bold text-gray-600 mb-5">Total users with this role: 5</div>


                            <div className="d-flex flex-column text-gray-600">
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> All Admin Controls</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View and Edit Financial Summaries</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> Enabled Bulk Reports</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View and Edit Payouts</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View and Edit Disputes</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> <em>and 7 more...</em></div>
                            </div>

                        </div>


                        <div className="card-footer flex-wrap pt-0">
                            <a href="/metronic8/demo1/apps/user-management/roles/view.html" className="btn btn-light btn-active-primary my-1 me-2">View Role</a>
                            <button type="button" className="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">Edit Role</button>
                        </div>

                    </div>

                </div>


                <div className="col-md-4">

                    <div className="card card-flush h-md-100">

                        <div className="card-header">

                            <div className="card-title">
                                <h2>Developer</h2>
                            </div>

                        </div>


                        <div className="card-body pt-1">

                            <div className="fw-bold text-gray-600 mb-5">Total users with this role: 14</div>


                            <div className="d-flex flex-column text-gray-600">
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> Some Admin Controls</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View Financial Summaries only</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View and Edit API Controls</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View Payouts only</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View and Edit Disputes</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> <em>and 3 more...</em></div>
                            </div>

                        </div>


                        <div className="card-footer flex-wrap pt-0">
                            <a href="/metronic8/demo1/apps/user-management/roles/view.html" className="btn btn-light btn-active-primary my-1 me-2">View Role</a>
                            <button type="button" className="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">Edit Role</button>
                        </div>

                    </div>

                </div>


                <div className="col-md-4">

                    <div className="card card-flush h-md-100">

                        <div className="card-header">

                            <div className="card-title">
                                <h2>Analyst</h2>
                            </div>

                        </div>


                        <div className="card-body pt-1">

                            <div className="fw-bold text-gray-600 mb-5">Total users with this role: 4</div>


                            <div className="d-flex flex-column text-gray-600">
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> No Admin Controls</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View and Edit Financial Summaries</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> Enabled Bulk Reports</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View Payouts only</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View Disputes only</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> <em>and 2 more...</em></div>
                            </div>

                        </div>


                        <div className="card-footer flex-wrap pt-0">
                            <a href="/metronic8/demo1/apps/user-management/roles/view.html" className="btn btn-light btn-active-primary my-1 me-2">View Role</a>
                            <button type="button" className="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">Edit Role</button>
                        </div>

                    </div>

                </div>


                <div className="col-md-4">

                    <div className="card card-flush h-md-100">

                        <div className="card-header">

                            <div className="card-title">
                                <h2>Support</h2>
                            </div>

                        </div>


                        <div className="card-body pt-1">

                            <div className="fw-bold text-gray-600 mb-5">Total users with this role: 23</div>


                            <div className="d-flex flex-column text-gray-600">
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> No Admin Controls</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View Financial Summaries only</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View Payouts only</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View and Edit Disputes</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> Response to Customer Feedback</div>
                            </div>

                        </div>


                        <div className="card-footer flex-wrap pt-0">
                            <a href="/metronic8/demo1/apps/user-management/roles/view.html" className="btn btn-light btn-active-primary my-1 me-2">View Role</a>
                            <button type="button" className="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">Edit Role</button>
                        </div>

                    </div>

                </div>


                <div className="col-md-4">

                    <div className="card card-flush h-md-100">

                        <div className="card-header">

                            <div className="card-title">
                                <h2>Trial</h2>
                            </div>

                        </div>


                        <div className="card-body pt-1">

                            <div className="fw-bold text-gray-600 mb-5">Total users with this role: 546</div>


                            <div className="d-flex flex-column text-gray-600">
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> No Admin Controls</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View Financial Summaries only</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View Bulk Reports only</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View Payouts only</div>
                                <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> View  Disputes only</div>
                            </div>

                        </div>


                        <div className="card-footer flex-wrap pt-0">
                            <a href="/metronic8/demo1/apps/user-management/roles/view.html" className="btn btn-light btn-active-primary my-1 me-2">View Role</a>
                            <button type="button" className="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">Edit Role</button>
                        </div>

                    </div>

                </div>


                <div className="ol-md-4">

                    <div className="card h-md-100">

                        <div className="card-body d-flex flex-center">

                            <button type="button" className="btn btn-clear d-flex flex-column flex-center" data-bs-toggle="modal" data-bs-target="#kt_modal_add_role">

                                <img src="/metronic8/demo1/assets/media/illustrations/sketchy-1/4.png" className="mw-100 mh-150px mb-7" />


                                <div className="fw-bold fs-3 text-gray-600 text-hover-primary">Add New Role</div>

                            </button>

                        </div>

                    </div>

                </div>

            </div>

        </>
    )
}

export default MyTest