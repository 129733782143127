import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { REQUEST_PICKUP_OTP, validatePickupOtp } from '../core/_requests'
import axios from 'axios'
import { toast } from 'react-toastify'


const forgotPasswordSchema = Yup.object().shape({
    
    token: Yup.string().required('token is required'),
    
})

export function GenrateOtpOrder() {

    const { token } = useParams();
    useEffect(() => {

    }, []);

    const [disabled, setDisabled] = useState(false);
    const [countdown, setCountdown] = useState(60);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (disabled) {
            timer = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [disabled]);

    useEffect(() => {
        if (countdown === 0) {
            setDisabled(false);
            setCountdown(60);
        }
    }, [countdown]);

   
    const navigate = useNavigate();
    const initialValues = {
        otp: '',
        token: token ? token : '',

    }
    const [loading, setLoading] = useState(false)
    const [otploading, setOtpLoading] = useState(false)

    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const [message, setMessage] = useState('');
    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(() => {
                validatePickupOtp(values)
                    .then((result) => {
                        if(result.data.status === '1')
                        {
                            toast.success(result.data.message);
                        }
                        setHasErrors(false)
                        setLoading(false)
                        navigate('/auth')
                    })
                    .catch(() => {
                        setHasErrors(true)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('The login detail is incorrect')
                    })
            }, 1000)
        },
    })

    const getnerateOtp = async () => {
        setOtpLoading(true);
         setDisabled(true);
        const otpres = await axios.post(REQUEST_PICKUP_OTP, { order_id: token });
        setMessage(otpres.data.message);
       
        if(otpres.data.status === '1')
        {
            toast.success(otpres.data.message);
        }
        setOtpLoading(false)
    }

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Generate otp?</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6'>
                    Generate and very otp
                </div>
                {/* end::Link */}
            </div>

            {/* begin::Title */}
            {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        Sorry, looks like there are some errors detected, please try again.
                    </div>
                </div>
            )}

            {hasErrors === false && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>{message} </div>
                </div>
            )}
            {formik.touched.token && formik.errors.token && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>{formik.errors.token}</div>
                </div>
            )}

            {/* end::Title */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <button type='button' onClick={getnerateOtp} disabled={disabled} className='btn btn-primary me-4'>
                    <span className='indicator-label'> {disabled ? `Resend OTP in ${countdown} seconds` : 'Generate OTP'}</span>
                    {otploading && (
                        <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {message != '' &&
                <div className='fv-row mb-8'>
                    <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
                    <input
                        type='text'
                        placeholder='Enter otp'
                        autoComplete='off'
                        {...formik.getFieldProps('otp')}
                        className={clsx(
                            'form-control bg-transparent',
                            { 'is-invalid': formik.touched.otp && formik.errors.otp },
                            {
                                'is-valid': formik.touched.otp && !formik.errors.otp,
                            }
                        )}
                    />
                    {formik.touched.otp && formik.errors.otp && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.otp}</span>
                            </div>
                        </div>
                    )}
                </div>
            }



            {/* end::Form group */}

            {message != '' &&
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
                        <span className='indicator-label'>Submit</span>
                        {loading && (
                            <span className='indicator-progress'>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <Link to='/auth/login'>
                        <button
                            type='button'
                            id='kt_login_password_reset_form_cancel_button'
                            className='btn btn-light'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            Cancel
                        </button>
                    </Link>{' '}
                </div>
            }

            {/* end::Form group */}
        </form>
    )
}
