import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { UsersListWrapper } from './health-card-management/users-list/UsersList'
import { useAuth } from '../../modules/auth'


const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

// const carmodelBreadcrum: Array<PageLink> = [
//   {
//     title: 'Cars Model',
//     path: '/admin/cars/models',
//     isSeparator: false,
//     isActive: false,
//   },
//   {
//     title: '',
//     path: '',
//     isSeparator: true,
//     isActive: false,
//   },
// ]

const Blogs = () => {

  const {currentUser} = useAuth();

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='lists'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>blogs</PageTitle>
              {currentUser?.permissions.find((item: any) => item.name === "view_blogs") ?
              <UsersListWrapper /> : <h1>You Are Not Allowed</h1> }
              {/* <TablesWidget10 className={''} /> */}
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/admin/blogs/lists' />} />
    </Routes>
  )
}

export default Blogs
