/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import { Link } from 'react-router-dom'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {UsersListFilter} from './UsersListFilter'
import { useAuth } from '../../../../../../modules/auth'
import * as XLSX from 'xlsx';
import { useQueryResponse } from '../../core/QueryResponseProvider'

const UsersListToolbar = () => {
  const {response} = useQueryResponse();
  const data = response?.data ? response?.data.map((item : any,index:number) => {
   return {
      Sr: `${index+1}`,
      Name:item.name,
      Email:item.email,
      Contact: item.contact,
      Car: `${item.brand_name}, ${item.model_name}, ${item.varient_name}`,
      "Registration No." :item.registration_no,
      "Odometer Reading" : item.odometer_reading,
      "Lead Type" : item.lead_type,
      Status:parseInt(item.status) === 1 ? 'Initiated' : item.status === 2 ? 'Assigned' : item.status === 3 ? 'Converted' : 'Cancelled',
      Remark: item.remark,
    }
  }) : [];
  const downloadxls = (e: any, data: any) => {
    
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "LeadListing");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "LeadListing.xlsx");
  };

  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }
  const {currentUser} = useAuth();
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <UsersListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3' onClick={(e) => downloadxls(e,data)}>
    <KTIcon iconName='exit-up' className='fs-2' /> Export export
    </button>
      {/* end::Export */}

      {/* begin::Add user */}
      {currentUser?.permissions.find((item: any) => item.name === "add_lead") ?
      <Link to={'/admin/lead-management/add-lead'} className='btn btn-primary'>
        <KTIcon iconName='plus' className='fs-2' />
        Add Lead
      </Link> : null }
      {/* end::Add user */}
    </div>
  )
}

export {UsersListToolbar}
