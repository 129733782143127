import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { currentUser } = useAuth();
  
  return ( 
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      {currentUser?.permissions.find((item: any) => item.name === "view_health_cards") ? (
        <SidebarMenuItem
          to='/admin/health-cards/lists'
          icon='bi bi-calendar-heart'
          title={'Health Cards'}
          fontIcon='bi-app-indicator'
        />
      ) : null}
 {currentUser?.permissions.find((item: any) => item.name === "view_reviews") ? (
       <SidebarMenuItem
          to='/admin/reviews/lists'
          icon='bi bi-calendar-heart'
          title={'Reviews'}
          fontIcon='bi-app-indicator'
        />
 ) : null }
 {currentUser?.permissions.find((item: any) => item.name === "view_seos") ? (
       <SidebarMenuItem
          to='/admin/seos/lists'
          icon='bi bi-calendar-heart'
          title={'Seos'}
          fontIcon='bi-app-indicator'
        />
 ):null}
 {currentUser?.permissions.find((item: any) => item.name === "view_seos") ? (
       <SidebarMenuItem
          to='/admin/areas/lists'
          icon='bi bi-calendar-heart'
          title={'Areas'}
          fontIcon='bi-app-indicator'
        />
 ):null}
 {currentUser?.permissions.find((item: any) => item.name === "view_seos") ? (
       <SidebarMenuItem
          to='/admin/blogs/lists'
          icon='bi bi-calendar-heart'
          title={'Blogs'}
          fontIcon='bi-app-indicator'
        />
 ):null}
 {currentUser?.permissions.find((item: any) => item.name === "view_coupons") ? (
       <SidebarMenuItem
          to='/admin/coupons/lists'
          icon='bi bi-calendar-heart'
          title={'Coupons'}
          fontIcon='bi-app-indicator'
        />
 ):null}
      {currentUser?.permissions.find((item: any) => item.name === "view_faqs") ? (
        <SidebarMenuItem
          to='/admin/faqs/listing'
          icon='bi bi-calendar-heart'
          title={'FAQS'}
          fontIcon='bi-app-indicator'
        />
      ) : null}

       {currentUser?.permissions.find((item: any) => item.name === "view_cities") ? (
        <SidebarMenuItem
          to='/admin/cities'
          icon='bi bi-calendar-heart'
          title={'Cities'}
          fontIcon='bi-app-indicator'
        />
       ) : null}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div> */}
      {currentUser?.permissions.find((item: any) => item.name === "view_manage_users") || currentUser?.permissions.find((item: any) => item.name === "view_permissions") ? (
        <SidebarMenuItemWithSub
          to='/crafted/pages'
          fontIcon='bi-archive'
          title='Users'
          icon='bi bi-people-fill'
        >
          {currentUser?.permissions.find((item: any) => item.name === "view_manage_users") ?
            <SidebarMenuItem to='admin/user-management/users' title='Manage Users' hasBullet={true} /> : null
          }

          {currentUser?.permissions.find((item: any) => item.name === "view_manage_users") ?
            <SidebarMenuItem to='admin/admin-management/admins' title='Manage Admin' hasBullet={true} /> : null
          }

          {currentUser?.permissions.find((item: any) => item.name === "view_manage_users") ?
            <SidebarMenuItem to='admin/garage-management/garages' title='Manage Garages' hasBullet={true} /> : null
          }
          {currentUser?.permissions.find((item: any) => item.name === "view_permissions") ?
            <SidebarMenuItem
              to='admin/user-management/permissions'
              title='Permissions'
              hasBullet={true}
            />
            : null}
        </SidebarMenuItemWithSub>
      ) : null}

      {currentUser?.permissions.find((item: any) => item.name === "view_car_brand") || currentUser?.permissions.find((item: any) => item.name === "view_car_model") || currentUser?.permissions.find((item: any) => item.name === "view_car_varient") ? (
        <SidebarMenuItemWithSub
          to='/cars'
          fontIcon='bi-archive'
          title='Manage Cars'
          icon='bi bi-car-front-fill'
        >
          {currentUser?.permissions.find((item: any) => item.name === "view_car_brand") ?
            <SidebarMenuItem to='admin/cars/brands' title='Car Brand' hasBullet={true} /> : null
          }
          {currentUser?.permissions.find((item: any) => item.name === "view_car_brand") ?
            <SidebarMenuItem to='admin/cars/models' title='Car Model' hasBullet={true} /> : null
          }
          {/* {currentUser?.permissions.find((item: any) => item.name === "view_car_brand") ?
            <SidebarMenuItem to='admin/cars/varient' title='Car Varient' hasBullet={true} /> : null
          } */}

        </SidebarMenuItemWithSub>
      ) : null}

      
      {currentUser?.permissions.find((item: any) => item.name === "view_service_category") || currentUser?.permissions.find((item: any) => item.name === "view_service") ? (
        <SidebarMenuItemWithSub
          to='/service'
          icon='bi bi-gear-fill'
          fontIcon='bi-archive'
          title='Service'
        >
          {currentUser?.permissions.find((item: any) => item.name === "view_service_category") ?
            <SidebarMenuItem to='/admin/service-management/category' title='Service Category' hasBullet={true} /> : null
          }

          {currentUser?.permissions.find((item: any) => item.name === "view_service_category") ?
            <SidebarMenuItem to='/admin/service-management/blogcategory' title='Blogs Category' hasBullet={true} /> : null
          }
          {currentUser?.permissions.find((item: any) => item.name === "view_service_category") ?
            <SidebarMenuItem to='/admin/service-management/services' title='Service' hasBullet={true} /> : null
          }
        </SidebarMenuItemWithSub>
      ) : null}
      {/* <SidebarMenuItem to='/lead-generation' title='Lead generation' hasBullet={true} /> */}
      {/* <SidebarMenuItemWithSub
        to='/products'
        fontIcon='bi-archive'
        title='Products'
        icon='item'
      >

        <SidebarMenuItem to='/admin/product-category' title='Product Category' hasBullet={true} />
        <SidebarMenuItem to='/admin/products' title='Products Service' hasBullet={true} />


      </SidebarMenuItemWithSub> */}



      {/* <SidebarMenuItem to='/crafted/pages/profile/overview' title='Manage Clients' hasBullet={false} /> */}
      {/* <SidebarMenuItem to='crafted/pages/contact' title='Contact Data' hasBullet={false} /> */}
      {currentUser?.permissions.find((item: any) => item.name === "view_lead") || currentUser?.permissions.find((item: any) => item.name === "add_lead") ?
      <SidebarMenuItemWithSub
        to='/lead-management'
        icon='bi bi-person-check-fill'  
        fontIcon='bi-archive'
        title='Leads'
      >
         {currentUser?.permissions.find((item: any) => item.name === "view_lead") ?
        <SidebarMenuItem to='/admin/lead-management/leads' title='Leads Listing' hasBullet={true} /> : null }
         {currentUser?.permissions.find((item: any) => item.name === "add_lead") ? 
        <SidebarMenuItem to='/admin/lead-management/add-lead' title='Add Leads' hasBullet={true} /> : null }


      </SidebarMenuItemWithSub>
      : null}
      {/* <SidebarMenuItem
        to='/lead-generation'
        icon='bi bi-arrow-down-square'
        title='Lead Generation'
        fontIcon='bi-app-indicator'
      /> */}

      {/* <SidebarMenuItem to='Import Excel' title='Import Excel' hasBullet={false} />
      <SidebarMenuItem to='/crafted/pages/profile/overview' title='Service queries' hasBullet={false} /> */}
       {currentUser?.permissions.find((item: any) => item.name === "view_orders") ?
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Orders'
        fontIcon='bi-archive'
        icon='bi bi-bag-check-fill'
      >
        <SidebarMenuItem to='admin/manage-orders/orders' title='View orders' hasBullet={true} />
        {/* <SidebarMenuItem to='/manage-orders' title='Manage Orders' hasBullet={true} /> */}
      </SidebarMenuItemWithSub>
      : null }
      {currentUser?.permissions.find((item: any) => item.name === "view_excel_import") ?
      <SidebarMenuItem
        to='/import-excel'
        icon='bi bi-file-earmark-spreadsheet-fill'
        title='Excel Import'
        fontIcon='bi-app-indicator'
      /> : null }
        {currentUser?.permissions.find((item: any) => item.name === "view_percentage_calculations") ?
      <SidebarMenuItem
        to='/percentage-calculations'
        icon='bi bi-percent'
        title='Percentage Calculations'
        fontIcon='bi-app-indicator'
      /> : null}
      {currentUser?.user_type === 'super_admin' ? (
        <SidebarMenuItem
          to='/admin/crafted/pages/contact'
          icon='bi bi-percent'
          title='testpage'
          fontIcon='bi-app-indicator'
        />
      ) : null}

    </>
  )
}

export { SidebarMenuMain }
