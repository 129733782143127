/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import axios from 'axios'
import { toast } from 'react-toastify'
import { SaveCustomer } from '../../../../../modules/auth/core/_requests'
import { useAuth } from '../../../../../modules/auth'

type Props = {
  isUserLoading: boolean
  user: any
}

// const editUserSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('Wrong email format')
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Email is required'),
//   name: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Name is required'),
// })
 
const UserEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  // const [userForEdit] = useState<User>({
  //   ...user,
  //   avatar: user.avatar || initialUser.avatar,
  //   role: user.role || initialUser.role,
  //   position: user.position || initialUser.position,
  //   name: user.name || initialUser.name,
  //   email: user.email || initialUser.email,
  // })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const {currentUser} = useAuth();
  const [roles,setRoles] = useState<any>([]);
  const [rolesloaded,setrolesloaded] = useState(true);
  const getRoles = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-roles`);
   
    (currentUser?.user_type === 'super_admin') ? setRoles(response.data) : setRoles(response.data.filter((el:any) => el.name !== 'admin' && el.name !== 'super_admin'));
     
}   

  useEffect(() => {
      if(rolesloaded)
      {
        getRoles();
        setrolesloaded(false);
      }
  },[rolesloaded])
  // const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  // const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)

  // const formik = useFormik({
  //   initialValues: userForEdit,
  //   validationSchema: editUserSchema,
  //   onSubmit: async (values, {setSubmitting}) => {
  //     setSubmitting(true)
  //     try {
  //       if (isNotEmpty(values.id)) {
  //         await updateUser(values)
  //       } else {
  //         await createUser(values)
  //       }
  //     } catch (ex) {
  //       console.error(ex)
  //     } finally {
  //       setSubmitting(true)
  //       cancel(true)
  //     }
  //   },
  // })
  
  const formik = useFormik({
    initialValues: {
      user_name: user.name ? user.name : '',
      role: user.role ? user.role.id : null,
      contact: user.primary_contact ? user.primary_contact : '',
      email: user.email ? user.email : '',
      password: user.password ? user.password : '',
      confirm_password: '',  
      id : user.id ? user.id : null  
    },
    validationSchema: Yup.object({
      user_name: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .required('Enter name'),
      contact: Yup.string()
        .matches(/^[6-9][0-9]{9}$/, 'Invailid contact')
        .required('Enter contact'),
      // email: Yup.string().email('Invalid email address').required('Enter email'),
     
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {

        // if(user)
        // {
        //   formik.setFieldValue('id',user.id);
        // }
        const response = await axios.post(SaveCustomer, values);
        
        if (response.data.status == 1) {
          toast.success(response.data.message);

          cancel(true)
        }
        else {
          toast.error(response.data.message);

        }
      } catch (ex) {
        console.error(ex)
      }
    },
  });



  // const [name, setName] = useState('');
  // const [logo, setLogo] = useState<File | null>(null);

  // const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setName(event.target.value);
  // };

  // const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files) {
  //     setLogo(event.target.files[0]);
  //   }
  // };
  // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();

  //   const formData = new FormData();
  //   formData.append('name', name);
  //   if (logo) {
  //     formData.append('logo', logo);
  //   }

  //   try {
  //     const response = await axios.post(`${POST_BRAND_NAME}`, formData);
  
  //     toast.success('Car brand added successfully!', {
  //     });

  //     setName('');
  //     setLogo(null);
  //   } catch (error) {
  //     console.error(error);
  //     // Show error toast
  //     toast.error('Failed to add car brand. Please try again!', {
  //       // ... (toast options)
  //     });
  //   }
  // };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className='row justify-content-center' action="">

        <div className="mb-10">
          <label className="form-label">User Name</label>
          <input
            type="text"
            className="form-control form-control-white"
            placeholder="Enter name"
            onChange={formik.handleChange}
            name="user_name"
            onBlur={formik.handleBlur}
            value={formik.values.user_name}
          />
          {formik.touched.user_name && formik.errors.user_name ? (
            <div className='text-danger'>{`${formik.errors.user_name}`}</div>
          ) : null}

        </div>

    
        <div className="mb-10">
          <label className="form-label">Email ID</label>
          <input
            type="email"
            className="form-control form-control-white"
            placeholder="username@example.com"
            onChange={formik.handleChange}
            name="email"
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className='text-danger'>{`${formik.errors.email}`}</div>
          ) : null}

        </div>
        <div className="mb-10">
          <label className="form-label">Phone Number</label>
          <input
            type="text"
            name="contact"
            className="form-control form-control-white"
            placeholder="Enter your phone number "
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contact}
          />
          {formik.touched.contact && formik.errors.contact ? (
            <div className='text-danger'>{`${formik.errors.contact}`}</div>
          ) : null}

        </div>
        {/* <div className="mb-10">
          <label className="form-label">Password</label>
          <input
            type="password"
            className="form-control form-control-white"
            placeholder="Enter your Phone number"
            name='password'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className='text-danger'>{`${formik.errors.password}`}</div>
          ) : null}

        </div>
        <div className="mb-10">
          <label className="form-label">Confirm password</label>
          <input
            type="password"
            className="form-control form-control-white"
            placeholder="Enter password again"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name='confirm_password'
            value={formik.values.confirm_password}
          />
          {formik.touched.confirm_password && formik.errors.confirm_password ? (
            <div className='text-danger'>{formik.errors.confirm_password}</div>
          ) : null}

        </div> */}
        <button type='submit' className='btn col-sm-2 btn-primary'> Submit </button>
      </form>

      {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
    </>
  )
}

export { UserEditModalForm }
