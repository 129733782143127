import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { UsersListWrapper } from './admins/users-list/UsersList'
import { useAuth } from '../../modules/auth'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const Admins = () => {

  const {currentUser} = useAuth();

  return (
    <Routes>
      <Route element={<Outlet />}>
     
        <Route
          path='admins'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>User Listing</PageTitle>
              {currentUser?.permissions.find((item: any) => item.name === "view_manage_users") ?
              <UsersListWrapper />  : <h1>You Are Not Allowed</h1> }
              {/* <ManageUser /> */}
              {/* <TablesWidget10 className={''} /> */}
            </>
          }
        />

      </Route>
      <Route index element={<Navigate to='/admin/user-management/admins' />} />
    </Routes>
  )
}

export default Admins
