import {useQueryClient, useMutation} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteSelectedUsers} from '../../core/_requests'
import { useAuth } from '../../../../../../modules/auth'

const UsersListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
    },
  })
  const {currentUser} = useAuth();
  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>
      {currentUser?.permissions.find((item: any) => item.name === "delete_car_model") ?
      <button
        type='button'
        className='btn btn-danger'
        onClick={async () => window.confirm('Do you want to delete all') ? await deleteSelectedItems.mutateAsync() : null}
      >
        Delete Selected
      </button> : null }
    </div>
  )
}

export {UsersListGrouping}
