/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { CancelPayment, CloseOrder, FetchPayments, OrderPaymentsApi } from '../../../modules/auth/core/_requests';
import { spacing } from 'react-select/dist/declarations/src/theme';
import { spawn } from 'child_process';
import { useNavigate } from 'react-router-dom';

const OrderPayments = ({ user, id, status }: any) => {

    const [upi_payment, setupi_payment] = useState('');
    const [allPayments, setallPayments] = useState<any>([]);

    const [selectedImage, setSelectedImage] = useState<any>();
    const [disabledSubmit, setdisabledSubmit] = useState(false);
    const [disabledcompleteSubmit, setdisabledCompleteSubmit] = useState(false);
    const navigate = useNavigate();

    const textToCopyRef: any = useRef(null);

    const copyTextToClipboard = () => {
        const textToCopy = textToCopyRef.current.innerText;
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                alert("Text copied to clipboard successfully!");
            })
            .catch(error => {
                console.error("Unable to copy text: ", error);
            });
    }
    // FetchPayments
    const formik = useFormik({
        initialValues: {
            amount: 0,
            order_id: id,
            payment_type: '',
            payment_to: 'Garage',
            upiimage: ''
        },
        validationSchema: Yup.object({
            amount: Yup.number()
                .required("Enter Amount").min(
                    1, 'Enter Valid Amount'
                ),

            payment_type: Yup.string() // string().required('Select screenshot of payment')
                .required("Select payment type"),
            upiimage: Yup.string().when('payment_type', {
                is: (id: String) => id == 'upi',
                then: (schema) => schema.required('UPI image is required for online payment')
            }),

        }),
        onSubmit: async (values, actions) => {
            var y = window.confirm(`Are you sure to pay or generate link for ₹${values.amount} ?`);
            if (y) {
                setdisabledSubmit(true);
                let formData = new FormData();
                formData.append('upiimage', selectedImage);
                formData.append('payment_type', values.payment_type);
                formData.append('payment_to', values.payment_to);
                formData.append('amount', `${values.amount * 100}`);
                formData.append('user_id', `${user.id}`);
                formData.append('name', `${user.first_name}`);
                formData.append('email', `${user.email}`);
                formData.append('contact', `${user.contact}`);
                formData.append('order_id', `${id}`);
                const token = localStorage.getItem("token");
                const response = await axios.post(OrderPaymentsApi,
                    formData,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                if (values.payment_type == 'online') {
                    setupi_payment(response.data);
                }
                else {
                    toast.success(response.data);
                }
                actions.resetForm({
                    values: {
                        amount: 0,
                        order_id: id,
                        payment_type: '',
                        payment_to: 'Garage',
                        upiimage: ''
                    }, // Reset to initial values
                });
                fetchPayments();
                setdisabledSubmit(false)
            }

        }
    });
    const fetchPayments = async () => {
        try {
            const response = await axios.post(`${FetchPayments}`, {
                order_id: id
            });
            setallPayments(response.data);
        } catch (error) {
            console.error(error);
            setdisabledSubmit(false)
        }
    };
    const cancelPayments = async (paymentLink: any) => {

        try {

            const response = await axios.post(`${CancelPayment}`, {
                payment_link: paymentLink
            });
            if (response.status === 200 && response.data.status == 1) {
                fetchPayments();
                toast.success(response.data.message);
            }
            else {
                toast.error('Something went wrong');
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {


        fetchPayments();
    }, []);

    function scrollToElementAbove(elementId: string) {
        const element = document.getElementById(elementId);
        if (element) {
            const offset = -100; // Adjust this value according to your preference
            const elementTop = element.getBoundingClientRect().top;
            window.scrollTo({
                top: window.pageYOffset + elementTop + offset,
                behavior: 'smooth'
            });
        } else {
            console.log(`Element with id '${elementId}' not found`);
        }
    }

    const handleScrollError = () => {
        const errorKeys = Object.keys(formik.errors);
        if (errorKeys.length > 0) {
            const firstErrorKey = errorKeys[0];
            scrollToElementAbove(firstErrorKey);
        }
    }

    const Checkpayment = async () => {
        try {
            var x = window.confirm('Are you sure to complete this order!');
            if (x) {
                setdisabledCompleteSubmit(true)
                const response = await axios.post(`${CloseOrder}`, {
                    order_id: id,
                    user_id: user.id
                });
                if (response.data.status == 1) {
                    toast.success(response.data.message);
                    navigate('/admin/manage-orders/orders');
                }
                else if (response.data.status == 2) {
                    toast.error(response.data.message);
                }
                else {
                    toast.error(response.data);
                }

                fetchPayments();
                setdisabledCompleteSubmit(false)
            }

        } catch (error) {
            console.error(error);
            setdisabledCompleteSubmit(false)
        }
    }

    const convertDate = (timestamp: any) => {
        try {
            const unixTimestamp = timestamp;

            // Create a new Date object with the Unix timestamp
            const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert to milliseconds

            // Get the components of the date
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
            const day = date.getDate();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            // Format the date as desired (e.g., YYYY-MM-DD HH:MM:SS)
            const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            return formattedDate;
        }

        catch (error) {
            console.error(error);

        }
    }



    return (
        <>

            {status != 'complete' && <form onSubmit={formik.handleSubmit}>
                <div className="card my-5 mx-3 p-2">
                    <div className="row">
                        <h3>Generate Payment Link </h3>
                    </div>
                    {upi_payment && (
                        <div className="row mt-5">
                            <div className="col-sm-6">
                                <div className="d-flex justify-content-between alert alert-success" role="alert">
                                    Payment Link :  <a ref={textToCopyRef} href={upi_payment} target='_blank'><strong> {upi_payment}</strong></a>
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" title='Copy Link' onClick={copyTextToClipboard} className="close" data-dismiss="alert" aria-label="Close">
                                        <i className="fs-1 ki-duotone ki-clipboard">
                                            <span className="path1" />
                                            <span className="path2" />
                                            <span className="path3" />
                                        </i>

                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row">

                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="payment_type">Payment to <span className='text-danger'>*</span></label>
                                <Select
                                    id="payment_to"
                                    onChange={selectedOption =>
                                        formik.setFieldValue('payment_to', selectedOption?.value)
                                    }

                                    options={[
                                        { value: 'Garage', label: 'Garage' },
                                        { value: 'Mechanicnow', label: 'Mechanicnow' }
                                    ]}
                                    value={{value:formik.values.payment_to,label:formik.values.payment_to}}

                                />


                                {formik.touched.payment_to && formik.errors.payment_to ? (
                                    <span className="text-danger">
                                        {formik.errors.payment_to}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="payment_type">Payment Type <span className='text-danger'>*</span></label>
                                <Select
                                    id="payment_type"
                                    onChange={selectedOption =>
                                        formik.setFieldValue('payment_type', selectedOption?.value)
                                    }

                                    options={[
                                        { value: 'online', label: 'Online' },
                                        { value: 'cash', label: 'cash' },
                                        { value: 'upi', label: 'UPI' }
                                    ]}

                                />


                                {formik.touched.payment_type && formik.errors.payment_type ? (
                                    <span className="text-danger">
                                        {formik.errors.payment_type}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-md-4 mt-5">
                            <div className="form-group">
                                <label htmlFor="amount">Amount <span className='text-danger'>*</span></label>

                                <input
                                    className="form-control form-control-sm"
                                    id="amount"
                                    type="text"
                                    placeholder="Enter registration no"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.amount}
                                />
                                {formik.touched.amount && formik.errors.amount ? (
                                    <span className="text-danger">
                                        {formik.errors.amount}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        {formik.values.payment_type === 'upi' && (
                            <div className="col-md-4 mt-5">
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="formFileSm" className="form-label">Upi Image</label>
                                        <input className="form-control form-control-sm" id="upiimage" type="file" onChange={(e) => {
                                            setSelectedImage(e.target.files?.[0]);
                                            formik.setFieldValue('upiimage', e.target.files?.[0]);
                                        }} />
                                    </div>

                                    {formik.touched.upiimage && formik.errors.upiimage ? (
                                        <span className="text-danger">
                                            {formik.errors.upiimage}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        )}

                    </div>
                    <div className="card-footer p-1">
                        <button type='submit' onClick={handleScrollError} disabled={disabledSubmit} className='btn btn-primary btn-sm me-3'>
                            {disabledSubmit ? (<span>
                                Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>) : <span>{formik.values.payment_type == 'online' ? 'Get Payment Link' : 'Pay amount'}</span>}
                        </button>

                    </div>
                </div>
            </form>}

            <div className="card my-5 mx-3 p-2 shadow-sm">
                <div className="card-header p-1 collapsible cursor-pointer rotate" data-bs-toggle="collapse" data-bs-target="#kt_docs_card_collapsible">
                    <h3 className="card-title">Payment History</h3>
                    <div className="card-toolbar rotate-180">
                        <i className="ki-duotone ki-down fs-1" />
                    </div>
                </div>
                <div id="kt_docs_card_collapsible" className="collapse show">
                    <div className="card-body p-1">
                        <div className="table-responsive">
                            <table className="table table-bordered gs-7 gy-7 gx-7">
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800">
                                        <th>Payment Link</th>
                                        <th>Payment To</th>
                                        <th>Name</th>
                                        <th>Contact</th>
                                        <th>Total amount</th>
                                        <th>Amount due</th>
                                        <th>Amount Paid</th>
                                        <th>Status</th>
                                        <th>Payment Details</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {allPayments.length > 0 ? allPayments.map((PaymentItem: any, payIndx: number) => {
                                        const paymentDetails = JSON.parse(PaymentItem.payment_details);

                                        return (
                                            <React.Fragment key={payIndx}>
                                                <tr>
                                                    <td> <a href={`${PaymentItem.payment_url}`} target='_blank'>{`${PaymentItem.payment_url}`}</a> </td>
                                                    <td> {PaymentItem.payment_to} </td>
                                                    <td>{PaymentItem.name?.toUpperCase()}</td>
                                                    <td>{PaymentItem.contact}</td>
                                                    <td>{(parseFloat(PaymentItem.amount) / 100).toLocaleString('en-IN', {
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}</td>
                                                    <td>{((PaymentItem.amount / 100 - PaymentItem.amount_paid / 100)).toLocaleString('en-IN', {
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}</td>
                                                    <td>{(parseFloat(PaymentItem.amount_paid) / 100).toLocaleString('en-IN', {
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}</td>
                                                    <td>{PaymentItem.status.replace('_', ' ').toUpperCase()}</td>
                                                    <td>
                                                        {paymentDetails && PaymentItem.razorpay_id ? (
                                                            <div>
                                                                <a href='#' data-bs-toggle="modal" data-bs-target={`#kt_modal_scrollable_${payIndx}`}>
                                                                    View Payments
                                                                </a>
                                                                <div className="modal fade" tabIndex={-1} id={`kt_modal_scrollable_${payIndx}`}>
                                                                    <div className="modal-dialog modal-lg modal-dialog-scrollable">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header p-1">
                                                                                <h5 className="modal-title">Payment Details</h5>
                                                                                {/*begin::Close*/}
                                                                                <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                                                                    <i className="ki-duotone ki-cross fs-2x"><span className="path1" /><span className="path2" /></i>
                                                                                </div>
                                                                                {/*end::Close*/}
                                                                            </div>
                                                                            <div className="modal-body p-1">
                                                                                <div className="table-responsive">
                                                                                    <table className="table table-bordered">
                                                                                        <thead>
                                                                                            <tr className="fw-bold fs-6 text-gray-800">
                                                                                                <th>Sr.</th>
                                                                                                <th>Amount</th>
                                                                                                <th>Method</th>
                                                                                                <th>Payment ID</th>
                                                                                                <th>Created at</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {paymentDetails && paymentDetails.map((paymentDetailsItem: any, index: number) => {


                                                                                                return (
                                                                                                    <tr>
                                                                                                        <td>{index + 1}</td>
                                                                                                        <td>{(paymentDetailsItem.amount / 100).toLocaleString('en-IN', {
                                                                                                            style: 'currency',
                                                                                                            currency: 'INR'
                                                                                                        })}</td>
                                                                                                        <td>{paymentDetailsItem.method}</td>
                                                                                                        <td>{paymentDetailsItem.payment_id}</td>
                                                                                                        <td>{convertDate(paymentDetailsItem.created_at)}</td>

                                                                                                    </tr>
                                                                                                )

                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>


                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ) : <>
                                                            {PaymentItem.razorpay_id ? <>
                                                                <strong> No payments</strong>
                                                                <a href="#" onClick={() => cancelPayments(`${PaymentItem.razorpay_id}`)}>&nbsp;( Cancel Payment )</a>
                                                            </> : <>
                                                                <strong> Method :</strong> &nbsp;{paymentDetails.method} <br /> <strong>Date :</strong> &nbsp;{convertDate(paymentDetails.created_at)} <br /> {paymentDetails.method == 'upi' && <> <a href={paymentDetails.payment_id} target='_blank'>View Screenshot</a> | <a download href={paymentDetails.payment_id} >Download Screenshot</a> </>}
                                                            </>}
                                                        </>
                                                        }
                                                        <div>

                                                        </div>

                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })

                                        :
                                        <tr>
                                            <td colSpan={5}>
                                                <h1>No Payments Yet</h1>
                                            </td>
                                        </tr>

                                    }


                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </div>
            {status != 'complete' &&
                <div className="card my-5 mx-3 p-2 ">

                    <button type='button' onClick={Checkpayment} disabled={disabledcompleteSubmit} className='btn btn-success btn-sm'>
                        {disabledSubmit ? (<span>
                            Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>) : 'Complete Order'}
                    </button>

                </div>}
        </>

    )
}

export default OrderPayments