import { useFormik } from 'formik';
import { PageLink, PageTitle } from '../../_metronic/layout/core'
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import Select from 'react-select';


const usersBreadcrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const ExcelExport = () => {
    const [selectedImage, setSelectedImage] = useState<any>();
    
   
    const formik = useFormik({
        initialValues: {
            excelfile: "",
            testvalue: "asdf",
            module : ''
        },
        validationSchema: Yup.object({
            excelfile: Yup.mixed()
                .required("Select excel file"),
            module: Yup.string().required('Select the module')
        }),
        onSubmit: async (values) => {
            
            let formData = new FormData();
            formData.append('excelfile', selectedImage);
            formData.append('module', values.module);
          
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/admin/excel-export`,
                formData
            );
            if (response.data.status == 1) {
                toast.success("Excel Imported Successfully");
                formik.resetForm();
                // setTimeout(function () {
                //     navigate("/user-listing");
                // }, 1000);
            } else {
                toast.error(response.data.message);
            }
        },
    });


    return (
        <>

            <PageTitle breadcrumbs={usersBreadcrumbs}>Excel Import</PageTitle>
            {/* <UsersListWrapper /> */}
            <div className="card">
                <form onSubmit={formik.handleSubmit}>
                    <div className="card-header py-2">
                        <div className="card-title">
                            <h3 className='p-0 m-0'>Excel Import</h3>

                        </div>
                    </div>
                    <div className="card-body">
                        <div className="card-body p-1">
                            <div className="row">
                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                      
                                            <label htmlFor="formFileSm" className="form-label">Module</label>
                                            <Select 
                                            options={[
                                                {value: 'brands',label:'Brands'},
                                                {value: 'services',label:'Services'},
                                                {value: 'models',label:'Models'},
                                                {value: 'varients',label:'Varients'},
                                                {value: 'leads',label:'Leads'},
                                                {value: 'prices',label:'Prices'}
                                            ]}
                                            onChange={(selectedOption) => {
                                              
                                                formik.setFieldValue('module', selectedOption?.value);
                                               
                                              }
                                              }
                                            />
                                        {formik.touched.module && formik.errors.module ? (
                                            <span className="text-danger">
                                                {formik.errors.module}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <div>
                                            <label htmlFor="formFileSm" className="form-label">Price Excel File</label>
                                            <input className="form-control form-control-sm" id="excelfile" type="file" onChange={(e) => {
                                                setSelectedImage(e.target.files?.[0]);
                                                formik.setFieldValue('excelfile', e.target.files?.[0]);
                                            }} />
                                        </div>

                                        {formik.touched.excelfile && formik.errors.excelfile ? (
                                            <span className="text-danger">
                                                {formik.errors.excelfile}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer py-2">
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                </form>
            </div>

        </>
    )
}

export default ExcelExport
