/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react'
import { isNotEmpty } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import axios from 'axios'
import { toast } from 'react-toastify'
import { ADD_COUPON } from '../../../../../modules/auth/core/_requests'

type Props = {
  isUserLoading: boolean
  user: any
}

interface Option {
  id: string;
  code: string;
}
type Service = {
  id: number;
  code: string;
  key_points: string;
  price: number;
  offer: number;
  description: string;
  category_id: string;
  category_code: string;
}
const UserEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()


  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const [code, setName] = useState(user.code ? user.code : '');
  const [discount, setdiscount] = useState(user.discount ? user.discount : '');
  const [coupon_description, setcoupon_description] = useState(user.coupon_description ? user.coupon_description : '');
  const [discount_type, setdiscount_type] = useState(user.discount_type ? user.discount_type : '');
  const [max_discount, setmax_discount] = useState(user.max_discount ? user.max_discount : '');
  const [min_buy, setMinBuy] = useState(user.min_buy ? user.min_buy : '');
  const [start_date, setStartDate] = useState(user.start_date_new ? user.start_date_new : '');
  const [end_date, setEndDate] = useState(user.end_date_new ? user.end_date_new : '');
  const [is_onetime, setis_onetime] = useState(user.is_onetime ? user.is_onetime : '');

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {

      const formData = new FormData();
      formData.append('code', code);
      formData.append('discount', discount);

      formData.append('coupon_description', coupon_description);
      formData.append('discount_type', discount_type);
      formData.append('min_buy', min_buy);
      formData.append('max_discount', max_discount);
      formData.append('start_date', start_date);
      formData.append('end_date', end_date);
      formData.append('is_onetime', is_onetime);

      if (isNotEmpty(user.id)) {
        formData.append('coupon_id', `${user.id}`);
      }

      const response = await axios.post(ADD_COUPON, formData);
    
      if (response.data.status == 1) {
        
        toast.success(response.data.message);
        cancel(true)
      }
      else {
        toast.error(response.data.message);
      }

    } catch (error) {

    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className='row justify-content-center ' method="POST" encType="multipart/form-data">

        <div className="form-group mb-4">
          <label className='fw-semibold required fs-6' htmlFor='code'>Code: </label>

          <input type="text" id='code' placeholder='Create coupon code' className='form-control' value={code} onChange={(event) => setName(event.target.value)} required />

        </div>
        <div className="form-group mb-4">
          <label className='fw-semibold required fs-6' htmlFor='min_buy'>Minimum buy: </label>

          <input type="text" id='min_buy' placeholder='Set minimum order price' className='form-control' value={min_buy} onChange={(event) => setMinBuy(event.target.value)} required />

        </div>
        <div className="form-group mb-4">
          <label className='fw-semibold required fs-6' htmlFor='discount'> Discount: </label>

          <input type="text" id='discount' placeholder='Set discount' className='form-control' value={discount} onChange={(event) => setdiscount(event.target.value)} required />

        </div>
        <div className="form-group mb-4">
          <label className='fw-semibold required fs-6' htmlFor='discount_type'> Discount type: </label>
          <select value={discount_type} onChange={(event) => setdiscount_type(event.target.value)} required id='discount_type' className="form-control" name="discount_type">
            <option value="amount">Amount</option>
            <option value="percent">Percent</option>
          </select>
        </div>
        <div className="form-group mb-4">
          <label className='fw-semibold required fs-6' htmlFor='max_discount'> Max discount: </label>

          <input placeholder='Set Max discount' type="text" id='max_discount' className='form-control' value={max_discount} onChange={(event) => setmax_discount(event.target.value)} required />

        </div>
       
        <div className="form-group col-sm-6 mb-4">
          <label className='fw-semibold required fs-6' htmlFor='start_date'> Start date: </label>

          <input placeholder='Set Max discount' type="date" id='start_date' className='form-control' defaultValue={start_date} onChange={(event) => setStartDate(event.target.value)} required />

        </div>

        <div className="form-group col-sm-6 mb-4">
          <label className='fw-semibold required fs-6' htmlFor='end_date'> End date: </label>

          <input placeholder='Set Max discount' type="date" id='end_date' className='form-control' defaultValue={end_date} onChange={(event) => setEndDate(event.target.value)} required />

        </div>
       

        <div className="form-group mb-4">
          <label className='fw-semibold required fs-6' htmlFor='coupon_description'> Coupon description: </label>

          <textarea rows={4} id='coupon_description' className='form-control' onChange={(event) => setcoupon_description(event.target.value)} placeholder='Enter coupon description' defaultValue={coupon_description} required></textarea>

        </div>

        <button type="submit" className='mt-4 btn btn-primary col-5'>Submit</button>
      </form>
      {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
      {/* <ToastContainer /> */}
    </>
  )
}

export { UserEditModalForm }
