import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../_metronic/layout/core'
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { GET_BRAND_NAME, GET_MODEL_NAME_BY_BRAND, GET_SERVICES_MODAL, GET_VARIENT_NAME_BY_MODEL } from '../modules/auth/core/_requests';
import React from 'react'
import Select from 'react-select'

const usersBreadcrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const Percentage = () => {


    const [services, setServices] = useState([]);
    

    const fetchServices = async () => {
        try {
            const url = GET_SERVICES_MODAL;
            const response = await axios.get(url);
            setServices(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const [brands, setBrands] = useState([]);

    const fetchBrands = async () => {
        try {
            const response = await axios.get(GET_BRAND_NAME)
            setBrands(response.data)
        } catch (error) {
            console.error(error)
        }
    };

    const [models, setModels] = useState([]);

    const fetchModels = async (id: any) => {
        try {
            const response = await axios.get(`${GET_MODEL_NAME_BY_BRAND}/${id}`);
            setModels(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const [varient, setVarient] = useState([]);

    const fetchVarient = async (id: any) => {
        try {
            const response = await axios.get(`${GET_VARIENT_NAME_BY_MODEL}/${id}`);
            setVarient(response.data);
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {

        fetchBrands();
        fetchServices();
    }, []);



    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            service: "",
            brand: "",
            model: "",
            varient: "",
            car_type: "",
            price_type: "",
            petrol_price: "",
            ev_price: "",
            diesel_price: "",
            percentage: "",
        },
        validationSchema: Yup.object({
            brand: Yup.string()
                .required("Select brand"),
            service: Yup.string()
                .required("Select service"),
            model: Yup.string()
                .required("Select model"),
            price_type: Yup.string()
                .required("Select price type"),
            car_type: Yup.string()
                .required("Enter car type"),
            fuel_type: Yup.string()
                .required("Enter car fuel type"),
            percentage: Yup.string()
                .required("Enter car percentage"),

        }),
        onSubmit: async (values) => {
            
            const token = localStorage.getItem("token");

            const response = await axios.post(
                "https://127.0.0.1:8000/api/admin/percentage",
                { ...values },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.data.status == 1) {
                toast.success("User added!");
                formik.resetForm();
                setTimeout(function () {
                    navigate("/user-listing");
                }, 1000);
            } else {
                toast.error(response.data.message);
            }
        },
    });

    const Modelref: any = useRef(null);
    const Varientref: any = useRef(null);
    const Typeref: any = useRef(null);
    return (
        <>

            <PageTitle breadcrumbs={usersBreadcrumbs}>Percentage Calculations</PageTitle>
            {/* <UsersListWrapper /> */}
            <div className="card">
                <form onSubmit={formik.handleSubmit}>
                    <div className="card-header p-5">
                        <h3 className='p-0 m-0'>Percentage Calculations</h3>
                    </div>
                    <div className="card-body">
                        <div className="card-body">
                            <div className="row">
                            <Select
                                            isClearable={true}
                                            onChange={selectedOption =>
                                                formik.setFieldValue('service', selectedOption?.value)
                                            }
                                            options={[
                                                {value:'hello',label:'chalo'},
                                                {value:'hello1',label:'chalo1'},
                                                {value:'hello2',label:'chal2'},
                                                {value:'hello3',label:'chal3'},
                                            ]} 
                                            
                                            />

                                      
                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="model">Service</label>
                                        <Select
                                            isClearable={true}
                                            onChange={selectedOption =>
                                                formik.setFieldValue('service', selectedOption?.value)
                                            }
                                            options={
                                                services && services.map((item: any) => (
                                                    { value: item?.id, label: item?.title }
                                                ))
                                            } />
                                        {formik.touched.service && formik.errors.service ? (
                                            <span className="text-danger">
                                                {formik.errors.service}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="name">Brand</label>
                                        <Select
                                            isClearable={true}
                                            onChange={(selectedOption) => {
                                                Modelref.current.clearValue();
                                                formik.setFieldValue('brand', selectedOption?.value);
                                                formik.setFieldValue('model', '');
                                                formik.setFieldValue('varient', '');
                                                fetchModels(selectedOption?.value);
                                            }
                                            }
                                            options={
                                                brands && brands.map((itemBrand: any) => (
                                                    { value: itemBrand?.id, label: itemBrand?.name }
                                                ))
                                            } />
                                        {formik.touched.brand && formik.errors.brand ? (
                                            <span className="text-danger">
                                                {formik.errors.brand}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="model">Model</label>
                                        <Select
                                            ref={Modelref}
                                            isClearable={true}
                                            onChange={(selectedOption) => {
                                                Varientref.current.clearValue();
                                                formik.setFieldValue('model', selectedOption?.value);
                                                formik.setFieldValue('varient', '');
                                                fetchVarient(selectedOption?.value);
                                            }
                                            }
                                            options={
                                                models && models.map((itemModel: any) => (
                                                    { value: itemModel?.id, label: itemModel?.model }
                                                ))
                                            } />
                                        {formik.touched.model && formik.errors.model ? (
                                            <span className="text-danger">
                                                {formik.errors.model}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="varient">Varient</label>
                                        <Select
                                            ref={Varientref}
                                            onChange={(selectedOption) => {
                                                formik.setFieldValue('varient', selectedOption?.value);
                                            }
                                            }
                                            options={
                                                varient && varient.map((itemVarient: any) => (
                                                    { value: itemVarient?.id, label: itemVarient?.varient }
                                                ))
                                            } />
                                        {formik.touched.varient && formik.errors.varient ? (
                                            <span className="text-danger">
                                                {formik.errors.varient}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="car_type">Car Type</label>
                                        <Select
                                            ref={Typeref}
                                            onChange={(selectedOption) => {
                                                formik.setFieldValue('car_type', selectedOption?.value);
                                            }
                                            }
                                            options={[
                                                { value: 'sedan', label: 'Sedan' },
                                                { value: 'suv', label: 'SUV' },
                                                { value: 'hatchback', label: 'Hatchback' },
                                                { value: 'coupe', label: 'Coupe' }
                                            ]} />
                                        {formik.touched.car_type && formik.errors.car_type ? (
                                            <span className="text-danger">
                                                {formik.errors.car_type}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="price_type">Price Type</label>
                                        <Select
                                            ref={Typeref}
                                            onChange={(selectedOption) => {
                                                formik.setFieldValue('price_type', selectedOption?.value);
                                            }
                                            }
                                            options={[
                                                { value: 'percentage', label: 'Percentage' },
                                                { value: 'amount', label: 'Amount' } 
                                            ]} />
                                        {formik.touched.price_type && formik.errors.price_type ? (
                                            <span className="text-danger">
                                                {formik.errors.price_type}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="petrol_price">Petrol Price</label>
                                        
                                             <input
                                            className="form-control"
                                            id="petrol_price"
                                            type="text"
                                            placeholder="Enter petrol_price"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.petrol_price}
                                        />
                                        {formik.touched.petrol_price && formik.errors.petrol_price ? (
                                            <span className="text-danger">
                                                {formik.errors.petrol_price}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="diesel_price">Diesel Price</label>
                                        
                                             <input
                                            className="form-control"
                                            id="diesel_price"
                                            type="text"
                                            placeholder="Enter diesel_price"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.diesel_price}
                                        />
                                        {formik.touched.diesel_price && formik.errors.diesel_price ? (
                                            <span className="text-danger">
                                                {formik.errors.diesel_price}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="ev_price">Electric vehicle Price</label>
                                        
                                             <input
                                            className="form-control"
                                            id="ev_price"
                                            type="text"
                                            placeholder="Enter ev_price"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.ev_price}
                                        />
                                        {formik.touched.ev_price && formik.errors.ev_price ? (
                                            <span className="text-danger">
                                                {formik.errors.ev_price}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 mt-5">
                                    <div className="form-group">
                                        <label htmlFor="percentage">Percentage (%)</label>
                                        <input
                                            className="form-control"
                                            id="percentage"
                                            type="text"
                                            placeholder="Enter Percentage"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.percentage}
                                        />


                                        {formik.touched.percentage && formik.errors.percentage ? (
                                            <span className="text-danger">
                                                {formik.errors.percentage}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    <div className="card-footer">
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                </form>
            </div>

        </>
    )
}

export default Percentage
