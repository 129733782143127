/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
import axios from 'axios'; 
import React, { useEffect, useRef, useState } from 'react'
import { FetchPrices, GET_SERVICES_MODAL } from '../../../modules/auth/core/_requests';


const ServiceModel = ({ handleaddservice, totaladded }: any) => {

    
    const [services, setServices] = useState([
        {
            category: '',
            created_at: '',
            description: '',
            exclusive: '',
            id: '',
            image: '',
            inclusive: '',
            key_points: '',
            offer: '',
            price: 0,
            status: '',
            tax: '',
            tax_type: '',
            title: '',
            type: '',
            updated_at: '',
        }
    ]);

    const [service,setService] = useState<any>([]);
    const [Prices,setPrices] = useState<any>([]);
   

    const fetchServices = async () => {
        try {
            const url = GET_SERVICES_MODAL;
            const response = await axios.get(url);

            setServices(response.data.map((serviceItem:any)=> 
                {   
                    const PricesArray = localStorage.getItem('Prices'); 
                    const NewPricesArray = JSON.parse(`${PricesArray}`);
                    const findproduct = NewPricesArray.find((item:any) =>  item.service.toUpperCase() == serviceItem.title.toUpperCase());
                   
                    if(findproduct)
                    return {...serviceItem,price:findproduct.price};
                    else
                    return serviceItem;
                }
            ));
            
        } catch (error) {

        }
    };
    const fetchServicesBySearch = async (e: any) => {
        try {
            const url = GET_SERVICES_MODAL;
            const response = await axios.get(`${url}?search=${e.target.value}`);
            setServices(response.data);
        } catch (error) {
        
        }
    };
    const fetchPrices = async (brand_name : any,model_name : any,setPrices : any) => {
        try {
            const url = FetchPrices;
            
            const response = await axios.get(`${url}/${brand_name}/${model_name}`);
           setPrices(response.data);
           localStorage.setItem('Prices',JSON.stringify(response.data));
             fetchServices();
        } catch (error) { 
            console.log(error)
        }   
    };
    
    useEffect(() => {
        const carbrand = localStorage.getItem('Order_Car_Brand'); 
        const carmodel = localStorage.getItem('Order_Car_Model'); 
        fetchPrices(carbrand,carmodel,setPrices);
    }, []);
   
    
    
    const [show, setshow] = useState(false);
   
    
    const serviceform = useRef(null);
    
    
    const handleOpen = () => {
        
        setshow(false);
        handleaddservice(service); 
    }

    const handleradio = (worddone:string) => {
        setService({...service,workdone:worddone});
    }
    
    

    return (
        <>
            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Select service</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body p-2 bg-secondary">

                            <div className="row mb-2">
                                <div className="col-12">
                                    <div className="card rounded-0 p-2">
                                        <div className="form" style={{ position: 'relative' }}>
                                            <i className="fa fa-search" style={{ position: 'absolute', top: '13px', left: '13px', fontSize: '20px' }} />
                                            <input type="text" onChange={fetchServicesBySearch} style={{ padding: '0.775rem 3rem' }} className="form-control form-control-solid" placeholder="Search anything..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!show ?   <div id='servicesmain'>
                                {services && services.map((service, key) => (
                                    <div key={key} className="card my-2 rounded-0">
                                        <div className="row">

                                            <div className="col-3">
                                                <img src={service.image} className="rounded" height={100} width={100} alt="" />
                                            </div>
                                            <div className="col-5 ps-0 pt-5">
                                                <h3>{service.title}</h3>
                                                <span>{service.inclusive}</span>
                                            </div>
                                            <div className="col-4 p-3">
                                                <span> Price</span>&nbsp;&nbsp;
                                                <strong>{Prices.find((priceItem : any) => priceItem.service == service.title) ? Prices.find((priceItem : any) => priceItem.service == service.title).price : parseFloat(`${service.price}`)}</strong> <br />
                                                {/* {totaladded.some((el: any) => { el.id === service.id }) ? (<InputSpinner
                                                type={'int'}
                                                precision={2}
                                                min={0}
                                                step={1}
                                                value={formik.values.head_rest}
                                                onChange={(num: number) => formik.setFieldValue('floor_mats', num)}
                                                variant={'dark'}
                                                size="sm"

                                            />) : null} */}
                                                <button className='btn mt-3 float-right btn-sm btn-primary' onClick={() => {
                                                    setshow(true);
                                                    setService(service);
                                                }}>Add</button>
                                            </div>
                                        </div>
                                    </div>

                                ))}
                            </div> : <div id='servicesform' ref={serviceform}>
                                <h4>Select Work Done</h4>
                                <label htmlFor="cleaned" className='p-3 bg-white my-1 w-100'>
                                    <input type="radio" onChange={() => handleradio('cleaned')} name='workdone' placeholder='' value={'cleaned'} id='cleaned' />
                                    <span className='mb-3 ms-2'></span> Cleaned
                                </label>
                                <label htmlFor="repaired" className='p-3 bg-white my-1 w-100'>
                                    <input type="radio" onChange={() => handleradio('repaired')} name='workdone' value={'repaired'} placeholder='' id='repaired' />
                                    <span className='mb-3 ms-2'></span> Repaired
                                </label>
                                <label htmlFor="replaced" className='p-3 bg-white my-1 w-100'>
                                    <input type="radio" onChange={() => handleradio('replaced')} name='workdone' placeholder='' value={'replaced'} id='replaced' />
                                    <span className='mb-3 ms-2'></span> Replaced
                                </label>
                                <label htmlFor="lubrication" className='p-3 bg-white my-1 w-100'>
                                    <input type="radio" onChange={() => handleradio('lubrication')} name='workdone' value={'lubrication'} placeholder='' id='lubrication' />
                                    <span className='mb-3 ms-2'></span> Lubrication
                                </label>
                                <label htmlFor="welding" className='p-3 bg-white my-1 w-100'>
                                    <input type="radio" onChange={() => handleradio('welding')} name='workdone' value={'welding'} placeholder='' id='welding' />
                                    <span className='mb-3 ms-2'></span> Welding
                                </label>
                                <label htmlFor="uninstallation" className='p-3 bg-white my-1 w-100'>
                                    <input type="radio" onChange={() => handleradio('uninstallation')} name='workdone' value={'uninstallation'} placeholder='' id='uninstallation' />
                                    <span className='mb-3 ms-2'></span> Uninstallation
                                </label>
                                <label htmlFor="labour" className='p-3 bg-white my-1 w-100'>
                                    <input type="radio" onChange={() => handleradio('labour')} name='workdone' value={'labour'} placeholder='' id='labour' />
                                    <span className='mb-3 ms-2'></span> Labour
                                </label>
                                <label htmlFor="topup" className='p-3 bg-white my-1 w-100'>
                                    <input type="radio" onChange={() => handleradio('topup')} name='workdone' value={'topup'} placeholder='' id='topup' />
                                    <span className='mb-3 ms-2'></span> Top-up
                                </label>
                                <label htmlFor="serviced" className='p-3 bg-white my-1 w-100'>
                                    <input type="radio" onChange={() => handleradio('serviced')} name='workdone' value={'serviced'} placeholder='' id='serviced' />
                                    <span className='mb-3 ms-2'></span> Serviced
                                </label>
                                <label htmlFor="checked" className='p-3 bg-white my-1 w-100'>
                                    <input type="radio" onChange={() => handleradio('checked')} name='workdone' value={'checked'} placeholder='' id='checked' />
                                    <span className='mb-3 ms-2'></span> Checked
                                </label>
                                <label htmlFor="button" className='p-3 bg-white my-1 w-100'>
                                    <input type="button" className='btn btn-primary' onClick={handleOpen} placeholder='' id='button' value={'Add Item'}/>
                                  
                                </label>

                            </div>}
                          
                            

                        </div>
                        <div className="modal-footer">
                            <div className="row">
                                <div className="col-sm-12">
                                    <strong> Items added : {totaladded.length}</strong>
                                </div>
                                <div className="col-sm-12">
                                    <strong> Total : {totaladded.reduce((sum: number, product: { total_amount: string; }) => sum + parseFloat(product.total_amount), 0)} </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ServiceModel