/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */

import {FC} from 'react'

import {Brand} from '../../core/_models'

type Props = {
  brand: Brand
}
 
const StatusCell: FC<Props> = ({brand}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {parseInt(`${brand.status}`) === 1 ? (
          <div className='symbol-label'>
            <span className='badge badge-success'>Active</span>
          </div>
        ) : (
            <div className='symbol-label'>
            <span className='badge badge-danger'>Inavtive</span>
          </div>
        )}
      </a>
    </div>
  </div>
)

export {StatusCell}
