/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {Brand} from '../../core/_models'

// type Props = {
//   brand: Brand
// }

const StatusCell: FC = ({review}:any) => 
{ 
 
    const renderStars = () => {
      
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <i
          key={i}
          className={`ki-solid ki ki-star fs-2x ${i <= parseInt(review.rating) ? 'text-warning' : 'text-dark'}`}
        ></i>
      );
    }
    return stars;
  };
  
 return (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className=''>
     {renderStars()}
    </div>
  </div>
)

}



export {StatusCell}
