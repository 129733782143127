/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from 'react'
import { isNotEmpty } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import axios from 'axios'
import { toast } from 'react-toastify'
import { ADD_BLOGS, ADD_SEOS, GET_BRAND_NAME } from '../../../../../modules/auth/core/_requests'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CustomUploadAdapterPlugin from '../../../../../../UploadAdapter'
import Tagify from '@yaireo/tagify'
import "@yaireo/tagify/src/tagify.scss";
import "./seos.css";
type Props = {
  isUserLoading: boolean
  user: any
}

const UserEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()


  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const handleImageChange = (e: any) => {
    const selectedImage = e.target.files[0];
    const id = e.target.getAttribute("id");
    if (id == 'imageog') {
      setImageog(selectedImage);
    }
   
    if (selectedImage) {
      const reader: any = new FileReader();
      reader.onloadend = () => {

        if (id == 'imageog') {
          setPreviewUrl(reader.result);
          setPreviousImageog(reader.result);
        }
      
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setPreviewUrl(null);
    }
  };

  console.log('users',user);
  const [title, settitle] = useState(user.title ? user.title : '');
  const [category_id, setcategory_id] = useState(user.category_id ? user.category_id : '');
  const [categories, setcategories] = useState<any>([]);
  const [slug, setslug] = useState(user.slug ? user.slug : '');
  const [description, setdescription] = useState(user.description ? user.description : '');
  const [shortdescription, setshortdescription] = useState(user.short_description ? user.short_description : '');
  const [og_title, setog_title] = useState(user.og_title ? user.og_title : '');
  const [og_description, setog_description] = useState(user.og_description ? user.og_description : '');
  const [keywords, setkeywords] = useState(user.meta_keywords ? user.meta_keywords : '');
  const [author, setauthor] = useState(user.author ? user.author : '');
  const [robots, setrobots] = useState(user.robots ? user.robots : '');
  const [og_url, setog_url] = useState(user.og_url ? user.og_url : '');
  const [og_type, setog_type] = useState(user.og_type ? user.og_type : '');
 
  const [previousImageog, setPreviousImageog] = useState(user.banner ? user.banner : null);
 
  const [imageog, setImageog] = useState<File | null>(null);
 
  const [previewUrl, setPreviewUrl] = useState(user.banner ? user.banner : null);
  const [mainUrl, setmainUrl] = useState('');
  const [titlewidth, settitlewidth] = useState(0);
  const h3Ref: any = useRef(null);
  const keywords_input: any = useRef(null);
  new Tagify(keywords_input.current);

  useEffect(() => {
    const mainUrltext = window.location.origin;
    setmainUrl(mainUrltext);


    // Do whatever you need with the main URL
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {

      const formData = new FormData();

      formData.append('title', title);
      formData.append('meta_title', title);
      formData.append('category_id', category_id);
      formData.append('description', description);
      formData.append('short_description', shortdescription);
      formData.append('meta_description', shortdescription);
      formData.append('slug', slug);
      formData.append('og_title', og_title);
      formData.append('og_description', og_description);
      formData.append('meta_keywords', keywords);
      formData.append('author', author);
      formData.append('robots', robots);

      formData.append('og_url', og_url);
      formData.append('og_type', og_type);
      if (imageog) {
        formData.append('banner', imageog);
      }
     

      if (isNotEmpty(user.id)) {
        formData.append('blog_id', `${user.id}`);
      }

      const response = await axios.post(ADD_BLOGS, formData);

      if (response.data.status == 1) {

        toast.success(response.data.message);
        cancel(true)
      }
      else {
        toast.error(response.data.message);
      }

    } catch (error) {

    }
  };

 useEffect(() => {
    
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/categories`)
        setcategories(response.data);
      } catch (error) {
        console.error(error)
      }
    };
   
    fetchCategories();
  }, []);
  

  return (
    <>
      <div className={"seo-general-preview"}>
        <h1>Preview</h1>
        {previewUrl && (
          <img
            src={previewUrl}
            alt="Preview"
            style={{ 'width': '100%' }}
          />
        )}
        <h5>{`https://mechanicnow.in/blogs/${slug}`}</h5> 

        <h3 style={{ 'fontSize': '20px', 'lineHeight': '1.3' }}>{title}</h3>
        <p>{shortdescription}</p>
      </div>
      <form onSubmit={handleSubmit} className='row justify-content-center' method="POST" encType="multipart/form-data">
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item" role="presentation">
            <a className="nav-link active" id="main-seo-tab" data-bs-toggle="tab" href="#main-seo-fields" role="tab" aria-controls="main-seo-fields" aria-selected="true">Main SEO Fields</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id="social-media-tab" data-bs-toggle="tab" href="#social-media-fields" role="tab" aria-controls="social-media-fields" aria-selected="false">Social Media Fields</a>
          </li>
        </ul>
        <div className="tab-content">
          {/* Tab 1: Main SEO Fields */}
          <div className="tab-pane fade show active" id="main-seo-fields" role="tabpanel">
         
           <div className="form-group mb-4">
                  <label className='fw-semibold  fs-6' htmlFor='category_id'>Category : </label>
                  <select value={category_id} onChange={(event) => {setcategory_id(event.target.value); }} id='category_id' className="form-control" name="category_id">
                    <option value="">Select Category</option>
                    {categories.length > 0 && categories.map((branditem: any) => (
                      <option key={branditem.id} value={branditem.id}>{`${branditem.name}`}</option>
                    ))}
                  </select>
                </div>

            <div className="form-group mb-4">
              <label className='fw-semibold  fs-6' htmlFor='title'> Title: </label>
              &nbsp;&nbsp; {title.length} / 60
              <input type="text" id='title' placeholder='Set title' className={`form-control ${title.length > 60 ? 'is-invalid' : ''}`} value={title} onChange={(event) => {
                 const selectedValue = event.target.value.replace(/\s+/g, '-').toLocaleLowerCase();
                setslug(selectedValue);
                settitle(event.target.value);

              }} />
              {title.length > 60 ? <span className='text-danger'>Title is greater than 60 character</span> : null}
            </div>
              
            <div className="form-group mb-4">
              <label className='fw-semibold  fs-6' htmlFor='slug'> Slug: </label>
              &nbsp;&nbsp; {slug.length} / 60
              <input type="text" id='slug' placeholder='Set slug' className={`form-control`} value={slug} onChange={(event) => {
               
                setslug(event.target.value);

              }} />
             
            </div>
              
            <div className="form-group mb-4">
              <label className='fw-semibold  fs-6' htmlFor='description'> Description: </label>
              
               <CKEditor
                editor={ClassicEditor}
                data={description}
                
                config={{
                    extraPlugins: [CustomUploadAdapterPlugin],
                    
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setdescription(data);
                }}
            />
              {/* <textarea id='description' rows={10} placeholder='Set description' className={`form-control ${shortdescription.length > 160 ? 'is-invalid' : ''}`} value={description} onChange={(event) => setdescription(event.target.value)}  > </textarea>
              {description.length > 160 ? <span className='text-danger'>Description is greater than 160 character</span> : null} */}
            </div>

            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='short_description'>Short Description: </label>
              <textarea id='short_description' rows={4} placeholder='Set short description' className={`form-control`} value={shortdescription} onChange={(event) => setshortdescription(event.target.value)}> </textarea>
              {shortdescription.length > 160 ? <span className='text-danger'>Description is greater than 160 character</span> : null} &nbsp;&nbsp; {shortdescription.length} / 160
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='keywords'> Keywords: </label>
              <input type="text" ref={keywords_input} id='keywords' placeholder='Set keywords' className='form-control' value={keywords} onChange={(event) => setkeywords(event.target.value)} />
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='author'> Author: </label>
              <input type="text" id='author' placeholder='Set author' className='form-control' value={author} onChange={(event) => setauthor(event.target.value)} />
            </div>
          </div>
          {/* Tab 2: Social Media Fields */}
          <div className="tab-pane fade" id="social-media-fields" role="tabpanel">
          
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='og_title'> Og title: </label>
              <input type="text" id='og_title' placeholder='Set og_title' className='form-control' value={og_title} onChange={(event) => setog_title(event.target.value)} />
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='og_description'> Og description: </label>
              <input type="text" id='og_description' placeholder='Set Og description' className='form-control' value={og_description} onChange={(event) => setog_description(event.target.value)} />
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='imageog'>
                Og Image:
              </label>
              <input id='imageog' type="file" className='form-control' onChange={handleImageChange} />
              {previousImageog && <img src={previousImageog} className='rounded' height={100} alt="" />}
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='og_url'> Og url: </label>
              <input type="text" id='og_url' placeholder='Set Og url' className='form-control' value={og_url} onChange={(event) => setog_url(event.target.value)} />
            </div>
            <div className="form-group mb-4">
              <label className='fw-semibold fs-6' htmlFor='og_type'> Og type: </label>
              <input type="text" id='og_type' placeholder='Set Og url' className='form-control' value={og_type} onChange={(event) => setog_type(event.target.value)} />
            </div>
          </div>
        </div>

        <button type="submit" className='mt-4 btn btn-primary col-5'>Submit</button>
      </form>

      {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
      {/* <ToastContainer /> */}
    </>
  )
}

export { UserEditModalForm }
