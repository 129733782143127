// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {Column} from 'react-table'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Code' className='min-w-125px' />,
    id: 'code',
    accessor:'code',
    Cell: ({...props}) => {return props.data[props.row.index].code},
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Details' className='min-w-125px' />,
    id: 'details',
    accessor:'details',
    Cell: ({...props}) => {return props.data[props.row.index].details},
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Discount' className='min-w-125px' />,
    id: 'discount',
    accessor:'discount',
    Cell: ({...props}) => {return props.data[props.row.index].discount},
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Discount type' className='min-w-125px' />,
    id: 'discount_type',
    accessor:'discount_type',
    Cell: ({...props}) => {return props.data[props.row.index].discount_type},
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Description' className='min-w-125px' />,
    id: 'coupon_description',
    accessor:'coupon_description',
    Cell: ({...props}) => {return props.data[props.row.index].coupon_description},
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Start date' className='min-w-125px' />,
    id: 'start_date',
    accessor:'start_date',
    Cell: ({...props}) => {return props.data[props.row.index].start_date},
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='End date' className='min-w-125px' />,
    id: 'end_date',
    accessor:'end_date',
    Cell: ({...props}) => {return props.data[props.row.index].end_date},
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
