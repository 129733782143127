// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {Column} from 'react-table'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Module Name' className='min-w-125px' />,
    id: 'module_name',
    accessor:'module_name',
    Cell: ({...props}) => {return props.data[props.row.index].module_name},
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Module Type' className='min-w-125px' />,
    id: 'module_type',
    accessor:'module_type',
    Cell: ({...props}) => {return props.data[props.row.index].module_type},
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Title' className='min-w-125px' />,
    id: 'title',
    accessor:'title',
    Cell: ({...props}) => {return props.data[props.row.index].title},
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Keywords' className='min-w-125px' />,
    id: 'keywords',
    accessor:'keywords',
    Cell: ({...props}) => {

      return props.data[props.row.index].keywords && JSON.parse(props.data[props.row.index].keywords).map(({ value,key }) => <span className='badge badge-danger me-2' key={key}> {value} </span>);
    },
  },
  
  
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Location' className='min-w-125px' />,
    id: 'location',
    accessor:'location',
    Cell: ({...props}) => {return props.data[props.row.index].location},
  },
  
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
