/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useEffect, useState } from 'react'
import { QUERIES } from '../../../../../../../_metronic/helpers'
import Select from 'react-select'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { useQueryClient } from 'react-query'

type Props = {
  brand: any
}

const UserInfoLogo: FC<Props> = ({ brand }) => {

  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const changeStatus = async (selectedoption: any) => {
    const remark = window.confirm('Are you sure to assign order to this garage!');
    if (remark) {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/update-order-by-Id`, { id: brand.id, garage_id: selectedoption });
      response.data.status == 1 ? toast.success(response.data.message) : toast.error(response.data.message);
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    }
  }

  const [leadstatus, setLeadstatus] = useState<any[]>([]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-garage`);
        setLeadstatus(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBrands();
  }, []);
  
  return (
    <>
    {brand.garage_id === null ? <Select isClearable={true}
      onChange={(selectedOption) => {
        changeStatus(selectedOption?.value1);
      }}
      options={leadstatus.map((garage:any) => ({value1:garage.id,label:garage.name}))}
    /> : <Select isClearable={true}
      onChange={(selectedOption) => {
        changeStatus(selectedOption?.value1);
      }}
      options={leadstatus.map((garage:any) => ({value1:garage.id,label:garage.name}))}
      value={{ value1: brand.garage_id, label: leadstatus.find((el: any) => el.id === brand.garage_id)?.name }}
    />}
    
    </>
   
  )
}

export { UserInfoLogo }
