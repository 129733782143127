/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { SingleOrder } from '../orders-management/users-list/core/_models';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { OrderHealthCardSave } from '../../../modules/auth/core/_requests';
import { useAuth } from '../../../modules/auth';

const OrderHealthCard = ({ id, user_id, setheathcard }: any) => {


  const [order, setOrder] = useState<any>([]);
  const [answered, setanswered] = useState<any>([]);
  const [disabledSubmit, setdisabledSubmit] = useState(false);
  const [orderloaded, setorderloaded] = useState(true);
  // const [topic,setTopic] = useState<any>([]);
  // const [startDate, setStartDate] = useState(new Date());

  // const [total,setTotal] = useState(0);
  const { currentUser } = useAuth();


  useEffect(() => {

    if (orderloaded) {
      getOrder();

      setorderloaded(false);
    }



  }, [])


  const getAnswered = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-answered-helth/${id}`);
    
    if (response.data.length > 0) {
      setOrder(response.data.map((question: any) => {
        return {

          id: question.topic_id,
          title: question.title,
          score: 100,
          questions: question.questions.map((qestionanswer: any) => {

            return {
              id: qestionanswer.question_id,
              health_card_id: question.topic_id,
              question: qestionanswer.question,
              selected_option: qestionanswer.selected_option
            }

          })

        }
      }));
      setheathcard(response.data.map((question: any) => {
        return {

          id: question.topic_id,
          title: question.title,
          score: 100,
          questions: question.questions.map((qestionanswer: any) => {

            return {
              id: qestionanswer.question_id,
              health_card_id: question.topic_id,
              question: qestionanswer.question,
              selected_option: qestionanswer.selected_option
            }

          })

        }
      }));

    }
  }


  const getOrder = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-health-cards`);
    setOrder(response.data);
    getAnswered();
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // const formData = new FormData();
    // formData.append('name', name);
    // if (logo) {
    //     formData.append('logo', logo);
    // }

    // try {
    //     const response = await axios.post(`${POST_BRAND_NAME}`, formData);

    //     toast.success('Car brand added successfully!', {
    //     });

    //     setName('');
    //     setLogo(null);
    // } catch (error) {
    //     console.error(error);
    //     // Show error toast
    //     toast.error('Failed to add car brand. Please try again!', {
    //         // ... (toast options)
    //     });
    // }
  };
  const navigate = useNavigate();
  const [topics, setTopics] = useState([]);
  const formik = useFormik({
    initialValues: {
      topic_array: order,
      order_id: id,
      user_id: user_id
    },
    enableReinitialize: true,
    // validationSchema: Yup.object({
    //     excelfile: Yup.mixed()
    //         .required("Select excel file"),
    // }),
    onSubmit: async (values) => {

      const token = localStorage.getItem("token");
      setdisabledSubmit(true)
      formik.setFieldValue('topic_array', order);
      const response = await axios.post(
        OrderHealthCardSave,
        { ...values },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.status == 1) {
        setdisabledSubmit(false)
        toast.success(response.data.message);
        setheathcard(values.topic_array);
      } else {
        toast.error(response.data.message);
        setdisabledSubmit(false)
      }
    },
  });

  const handlechangeradio = (question: any, value: any) => {

    setOrder(order.map((shape: any) => {

      return {
        ...shape,
        // score : currscore,
        questions: shape.questions.map((shapeQuestion: any) => {

          if (shapeQuestion.id != question) {
            // No change
            return shapeQuestion;
          } else {
            // Return a new circle 50px below

            return {
              ...shapeQuestion,
              selected_option: `${value}`,
            };
          }
        })
      }
    }));




  }

  function scrollToElementAbove(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      const offset = -100; // Adjust this value according to your preference
      const elementTop = element.getBoundingClientRect().top;
      window.scrollTo({
        top: window.pageYOffset + elementTop + offset,
        behavior: 'smooth'
      });
    } else {
      console.log(`Element with id '${elementId}' not found`);
    }
  }

  const handleScrollError = () => {
    const errorKeys = Object.keys(formik.errors);
    if (errorKeys.length > 0) {
      const firstErrorKey = errorKeys[0];
      scrollToElementAbove(firstErrorKey);
    }
  }

  
  return (
    <>
      <form onSubmit={formik.handleSubmit}>


        {order && order.map((item: any, key: any) => (
          <div className="accordion my-2 mx-2" key={key} id={`main-${item.id}`}>
            {/* <>{setTopic([...topic,{title:item.id,question:[]}])}</> */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#question${item.id}`} aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                  <span>{item.title} </span>   <span className='float-right'>Score {parseFloat(item.score).toLocaleString('en-IN', {
                    style: 'currency',
                    currency: 'INR'
                  })} % </span>
                </button>
              </h2>
              <div id={`question${item.id}`} className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                <div className="accordion-body">
                  {item.questions && item.questions.map((question_item: any, key: any) => (
                    <div key={key}>
                      <p><strong>{question_item.question} ? </strong>
                        {/* <>{setTopic([...(topic[key].question),question_item.id])}</> */}
                      </p>
                      <div className="row my-5">
                        <div className="col-sm-6">
                          {/* <input type="text" id="" value={} /> */}
                          {item.title != 'tyres' && item.title != 'breaks' && item.title != 'battery' ?
                            (<>
                              <div className="form-check form-check-custom form-check-danger form-check-solid">
                                <label className='form-control border-danger rounded-right' htmlFor={`${question_item.id}-attention`}>
                                  <input className='form-check-input' type="radio" onChange={() => {

                                    handlechangeradio(question_item.id, 'attention');
                                  }} name={`${item.title}[${question_item.id}]`} checked={question_item.selected_option === 'attention'} id={`${question_item.id}-attention`} value="attention" />
                                  &nbsp; Requires Immediate Attention
                                </label>
                              </div>
                              <div className="form-check form-check-custom form-check-warning form-check-solid">
                                <label className='form-control border-warning' htmlFor={`${question_item.id}-further_attention`}>
                                  <input className='form-check-input' type="radio" onChange={() => {

                                    handlechangeradio(question_item.id, 'further_attention');
                                  }} name={`${item.title}[${question_item.id}]`} checked={question_item.selected_option === 'further_attention'} id={`${question_item.id}-further_attention`} value="further_attention" />
                                  &nbsp; Will Require Further Attention
                                </label>
                              </div>

                              <div className="form-check form-check-custom form-check-success form-check-solid">
                                <label className='form-control  border-success' htmlFor={`${question_item.id}-good`}>
                                  <input className='form-check-input' type="radio" onChange={() => {

                                    handlechangeradio(question_item.id, 'good');
                                  }} name={`${item.title}[${question_item.id}]`} checked={question_item.selected_option === 'good'} id={`${question_item.id}-good`} value="good" />
                                  &nbsp; Everything Seems Good
                                </label>
                              </div>
                            </>
                            ) : null
                          }
                          <input type="text" onChange={(e) => {
                            handlechangeradio(question_item.id, e.target.value);
                          }} name={`${item.title}[${question_item.id}]`} className='form-control' value={['good', 'further_attention', 'attention'].find((el) => el === question_item.selected_option || question_item.selected_option === 'no') ? '' : question_item.selected_option} placeholder='Custom Value' />
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
            </div>
          </div>
        ))}
        {currentUser?.permissions.find((item: any) => item.name === "view_manage_users") ? <button type='submit' onClick={handleScrollError} disabled={disabledSubmit} className='btn btn-primary btn-sm'>
          {disabledSubmit ? (<span>
            Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2" />
          </span>) : 'Update'}
        </button> : null}
      </form>
    </>
  )
}

export default OrderHealthCard