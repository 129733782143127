import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import { toast } from 'react-toastify'

export const GET_USER_BY_ACCESSTOKEN_URL = `${process.env.REACT_APP_API_URL}/api/admin/verify_token`
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/api/login`
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/register`
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/api/forgot-password`
export const REQUEST_PICKUP_URL = `${process.env.REACT_APP_API_URL}/api/generate-pickup`
export const REQUEST_PICKUP_OTP = `${process.env.REACT_APP_API_URL}/api/generate-order-otp`
export const REQUEST_RESET_URL = `${process.env.REACT_APP_API_URL}/api/reset-password`
export const REQUEST_VALIDATE_PICKUP = `${process.env.REACT_APP_API_URL}/api/validate-pickup-otp`
export const POST_BRAND_NAME  = `${process.env.REACT_APP_API_URL}/api/admin/add-brand`
export const GET_BRAND_NAME  = `${process.env.REACT_APP_API_URL}/api/admin/get-brand`
export const DELETE_BRAND_NAME  = `${process.env.REACT_APP_API_URL}/api/admin/delete-brand`
export const UPDATE_BRAND_NAME  = `${process.env.REACT_APP_API_URL}/api/admin/update-brand`
export const POST_MODEL_NAME = `${process.env.REACT_APP_API_URL}/api/admin/add-model`
export const GET_MODEL_NAME = `${process.env.REACT_APP_API_URL}/api/admin/get-model`
export const GET_MODEL_NAME_BY_BRAND = `${process.env.REACT_APP_API_URL}/api/admin/get-model`
export const POST_VARIENT_NAME = `${process.env.REACT_APP_API_URL}/api/admin/add-varient`
export const GET_VARIENT_NAME = `${process.env.REACT_APP_API_URL}/api/admin/get-varient`
export const GET_VARIENT_NAME_BY_MODEL = `${process.env.REACT_APP_API_URL}/api/admin/variants`
export const POST_SERVICE_CATEGORY = `${process.env.REACT_APP_API_URL}/api/admin/submitServicForm`
export const SERVICE_CATEGORY_DATA = `${process.env.REACT_APP_API_URL}/api/admin/service-categories-data`
export const CATEGORY_TABLE_DATA = `${process.env.REACT_APP_API_URL}/api/admin/get-service`
export const POST_BLOG_CATEGORY = `${process.env.REACT_APP_API_URL}/api/admin/submitBlogForm`
export const POST_AREAS = `${process.env.REACT_APP_API_URL}/api/admin/submitAreaForm`
export const BLOG_CATEGORY_DATA = `${process.env.REACT_APP_API_URL}/api/admin/blog-categories-data`
export const CITIES_DATA = `${process.env.REACT_APP_API_URL}/api/get-cities-front`
export const AREAS_DATA = `${process.env.REACT_APP_API_URL}/api/admin/areas-data`
export const BLOG_CATEGORY_TABLE_DATA = `${process.env.REACT_APP_API_URL}/api/admin/get-blog`
export const AREA_TABLE_DATA = `${process.env.REACT_APP_API_URL}/api/admin/get-areas`
export const ADD_SERVICES = `${process.env.REACT_APP_API_URL}/api/admin/add-service`
export const ADD_REVIEWS = `${process.env.REACT_APP_API_URL}/api/admin/add-review`
export const ADD_COUPON = `${process.env.REACT_APP_API_URL}/api/admin/coupon`
export const ADD_SEOS = `${process.env.REACT_APP_API_URL}/api/admin/seos`
export const ADD_BLOGS = `${process.env.REACT_APP_API_URL}/api/admin/blogs`
export const GET_SERVICES = `${process.env.REACT_APP_API_URL}/api/admin/get-services`
export const GET_SERVICES_BY_ID = `${process.env.REACT_APP_API_URL}/api/admin/get-services`
export const GET_SERVICES_MODAL = `${process.env.REACT_APP_API_URL}/api/admin/get-service-modal`
export const UPDATE_SERVICES = `${process.env.REACT_APP_API_URL}/api/admin/update-services`
export const OrderDetailsSave = `${process.env.REACT_APP_API_URL}/api/admin/view-order-details`
export const InventorySave = `${process.env.REACT_APP_API_URL}/api/admin/inventory-details`
export const OrderHealthCardSave = `${process.env.REACT_APP_API_URL}/api/admin/order-health-card`
export const OrderEstimateSave = `${process.env.REACT_APP_API_URL}/api/admin/order-estimate`
export const ConvertToOrder = `${process.env.REACT_APP_API_URL}/api/admin/lead-to-order`
export const SaveUser = `${process.env.REACT_APP_API_URL}/api/admin/save-user`
export const SaveCustomer = `${process.env.REACT_APP_API_URL}/api/admin/save-customer`
export const SaveGarage = `${process.env.REACT_APP_API_URL}/api/admin/save-garage`
export const OrderPaymentsApi = `${process.env.REACT_APP_API_URL}/api/admin/add-payments`
export const FetchPrices = `${process.env.REACT_APP_API_URL}/api/service-filter`
export const FetchPayments = `${process.env.REACT_APP_API_URL}/api/admin/payment-history`
export const CloseOrder = `${process.env.REACT_APP_API_URL}/api/admin/close-order`
export const CancelPayment = `${process.env.REACT_APP_API_URL}/api/admin/payment-cancel`

export function login(email: string, password: string) {
  const login = axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  }).then((response)=>{
    if(response.data.error)
    {
      toast.error(response.data.error);
    }
    return response;
  });
  
  return login;
}
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function requestLink({email,name,contact,order_id,pickup_person}:any) {
  return axios.post<any>(REQUEST_PICKUP_URL, {
    email,name,contact,order_id,pickup_person
  })
}


export function resetPassword(resetvalues : any) {
  return axios.post<{result: boolean}>(REQUEST_RESET_URL, {
    token: resetvalues.token,
    password : resetvalues.password,
    password_confirmation : resetvalues.confirm_password

  })
}


export function validatePickupOtp(resetvalues : any) {
  return axios.post<any>(REQUEST_VALIDATE_PICKUP, {
    token: resetvalues.token,
    otp:resetvalues.otp
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
