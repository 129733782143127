/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from 'react'
import { SearchComponent } from '../../../assets/ts/components'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Search: FC = () => {
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)
  const [orders, setOrders] = useState<any>([]);
  const [leads, setLeads] = useState<any>([]);
  const [customers, setCustomers] = useState<any>([]);

  const navigate = useNavigate();

  const processs = async (search: SearchComponent) => {

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/global-search`, { search: search.inputElement.value });

    
    setCustomers(response.data.customers)
 
    setLeads(response.data.leads)
  
    setOrders(response.data.orders)
  

      if (response.data.customers.length === 0 && response.data.leads.length === 0 && response.data.orders.length === 0) {
        // Hide results
        
        resultsElement.current!.classList.add('d-none')
        // Show empty message
        emptyElement.current!.classList.remove('d-none')
      } else {
        // Show results
        
        resultsElement.current!.classList.remove('d-none')
        // Hide empty message
        emptyElement.current!.classList.add('d-none')
      }

      // Complete search
      search.complete()
   
  }

  const clear = (search: SearchComponent) => {
    // Show recently viewed
    suggestionsElement.current!.classList.remove('d-none')
    // Hide results
    resultsElement.current!.classList.add('d-none')
    // Hide empty message
    emptyElement.current!.classList.add('d-none')
  }
  const gotoorders = (e:any,order_id:any) => {
    e.preventDefault();
  
    navigate(`/admin/manage-orders/view-order/${order_id}`);
  }
  
      
      
  useEffect(() => {
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search')

    // Search handler
    searchObject!.on('kt.search.process', processs)

    // Clear handler
    searchObject!.on('kt.search.clear', clear)
  }, [])

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTIcon iconName='magnifier' className='fs-1' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <form
              data-kt-search-element='form'
              className='w-100 position-relative mb-3'
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <input
                type='text'
                className='form-control form-control-flush ps-10'
                name='search'
                placeholder='Search...'
                data-kt-search-element='input'
              />

              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                data-kt-search-element='spinner'
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>

              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTIcon iconName='cross' className='fs-2 text-lg-1 me-0' />
              </span>
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                {customers.length > 0 ? <>
                  <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
                    Customers
                  </h3>
                  {customers.map((customer: any, key: any) => {

                    return (
                      <a
                        key={key}
                        href='/'
                        className='d-flex text-dark text-hover-primary align-items-center mb-5'
                      >

                        <div className="symbol symbol-50px me-4"><span className="symbol-label bg-light"><i className="ki-duotone ki-profile-circle fs-2 text-primary"><span className="path1" /><span className="path2" /><span className="path3" /></i></span></div>


                        <div className='d-flex flex-column justify-content-start fw-bold'>
                          <span className='fs-6 fw-bold'>{customer.user_name}</span>
                          <span className='fs-7 fw-bold text-muted'>{customer.user_type}</span>
                          <span className='fs-7 fw-bold text-muted'>{customer.primary_contact}</span>
                        </div>
                      </a>
                    )
                  })}

                </>

                  : null}

                {leads.length > 0 ? <>
                  <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
                    Leads
                  </h3>
                  {leads.map((lead: any, key: any) => {

                    return (
                      <a
                        key={key}
                        href='/'
                        className='d-flex text-dark text-hover-primary align-items-center mb-5'
                      >
                        <div className='symbol symbol-40px me-4'>
                          <span className="symbol-label bg-light"><i className="ki-duotone ki-document fs-2 text-primary"><span className="path1" /><span className="path2" /></i></span>

                        </div>

                        <div className='d-flex flex-column justify-content-start fw-bold'>
                          <span className='fs-6 fw-bold'>{lead.name}</span>
                          <span className='fs-7 fw-bold text-muted'>{lead.contact}</span>
                          <span className='fs-7 fw-bold text-muted'>{lead.email}</span>

                        </div>
                      </a>
                    )
                  })}

                </>

                  : null}

                {orders.length > 0 ? <>
                  <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
                    Orders
                  </h3>
                  {orders.map((order: any, key: any) => {

                    return (
                      <a
                        key={key}
                        
                        onClick={(e) => gotoorders(e,order.order_id)}
                        className='d-flex text-dark text-hover-primary align-items-center mb-5'
                      >
                        <div className='symbol symbol-40px me-4'>
                          <img src={toAbsoluteUrl('/media/avatars/300-6.jpg')} alt='' />
                        </div>

                        <div className='d-flex flex-column justify-content-start fw-bold'>
                          <span className='fs-6 fw-bold'>{order.customer_name}</span>
                          <span className='fs-7 fw-bold text-muted'>{order.customer_contact}</span>
                          <span className='fs-7 fw-bold text-muted'>{order.registration_no}</span>

                        </div>
                      </a>
                    )
                  })}

                </>

                  : null}
              </div>
            </div>

            <div ref={suggestionsElement} className='mb-4' data-kt-search-element='main'>
           
            </div>

            <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <KTIcon iconName='search-list' className='fs-4x opacity-50' />
              </div>

              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
                <div className='text-muted fs-7'>Please try again with a different query</div>
              </div>
            </div>
          </div>

          <form className={`pt-1 ${menuState === 'preferences' ? '' : 'd-none'}`}>
            <h3 className='fw-bold text-dark mb-7'>Search Preferences</h3>

            <div className='pb-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Projects
                </span>

                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Targets
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Affiliate Programs
                </span>
                <input className='form-check-input' type='checkbox' value='1' />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Referrals
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>Users</span>
                <input className='form-check-input' type='checkbox' />
              </label>
            </div>

            <div className='d-flex justify-content-end pt-7'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'
              >
                Cancel
              </button>
              <button className='btn btn-sm fw-bolder btn-primary'>Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export { Search }
