/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'


type Props = {
  review: any
}

const UserInfoLogo: FC<Props> = ({review}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {review.image ? (
          <div className='symbol-label'>
            <img src={`${review.image}`} alt={review.name} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-success`,
              `text-danger`
            )}
          >
            No Image
          </div>
        )}
      </a>
    </div>
  </div>
)

export {UserInfoLogo}
