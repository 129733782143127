import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ServiceList } from './sevice-management/users-list/UsersList'
import { ServiceCategory } from './sevice-category-management/users-list/UsersList'
import { useAuth } from '../../modules/auth'
import { BlogCategory } from './blog-category-management/users-list/UsersList'



const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const ServiceListCall = () => {
  const {currentUser} = useAuth();
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='services'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Services Listing</PageTitle>
              {currentUser?.permissions.find((item: any) => item.name === "view_service") ?
              <ServiceList /> : <h1>You Are Not Allowed</h1> }
              {/* <TablesWidget10 className={''} /> */}
            </>
          }
        />
        <Route
          path='category'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Category Listing</PageTitle>
              {currentUser?.permissions.find((item: any) => item.name === "view_service_category") ?
              <ServiceCategory /> : <h1>You Are Not Allowed</h1> }
              {/* <TablesWidget10 className={''} /> */}
            </>
          }
        />
        <Route
          path='blogcategory'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Category Listing</PageTitle>
              {currentUser?.permissions.find((item: any) => item.name === "view_service_category") ?
              <BlogCategory /> : <h1>You Are Not Allowed</h1> }
              {/* <TablesWidget10 className={''} /> */}
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/admin/service-management/services' />} />
    </Routes>
  )
}

export default ServiceListCall
