/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react'
import * as Yup from 'yup'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { HealthCard, initialUser, User } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { UsersListLoading } from '../components/loading/UsersListLoading'
import { createUser, updateUser } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import axios from 'axios'
import Select from 'react-select'

type Props = {
  isUserLoading: boolean
  user: HealthCard
}

const editUserSchema = Yup.object().shape({
 
  title: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),  
    questions: Yup.array().of(
      Yup.string().required('Question is required')
    ).min(1, 'At least one question is required')
   
})

const UserEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  // const [userForEdit] = useState<HealthCard>({
  //   id:undefined,
  //   topic: '',
  //   questions: [
    
  //   ]
  // })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }


  const formik = useFormik({
    initialValues: {...user},
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      if(typeof formik.values.questions?.length === 'undefined' || formik.values.questions?.length === 0)
       { 
          window.alert('Add atleast 1 question');
          setSubmitting(false)
          return false;
        }
        else
        {
          setSubmitting(true);
        }

      
      try {
        if (isNotEmpty(values.id)) {
         
          await updateUser(values)
        } else {
         
          await createUser(values);
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <FormikProvider value={formik}>
        <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >

            {/* <div className='fv-row mb-7'>
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{backgroundImage: `url('${blankImg}')`}}
            >
            </div>
          </div> */}

            <div className="row mb-6">

              <label className="col-lg-3 col-form-label required fw-semibold fs-6">Topic</label>

              <div className="col-lg-9">

                <div className="row">

                  <div className="col-lg-12 fv-row fv-plugins-icon-container">
                    
                    <input
                      placeholder='Enter topic'
                      {...formik.getFieldProps('title')}
                      onChange={(e) => {
                          formik.setFieldValue('title',e.target.value);
                      }}
                      type='text'
                      name={`topic`}
                      className={clsx(
                        'form-control form-control-sm  mb-3 mb-lg-0',
                        { 'is-invalid': formik.touched.title && formik.errors.title },
                        {
                          'is-valid': formik.touched.title && !formik.errors.title,
                        }
                      )}
                      value={formik.values.title}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" /></div>
                </div>
              </div>

            </div>
            <FieldArray name="questions" >
              {(arrayHelpers) => {
                
                return (
                  <>
                    {formik.values.questions && formik.values.questions.map((friend :any , index) => {
                      
                      return (
                      <div key={index}>

                        <div className="row mb-6">
                          {/*begin::Label*/}
                          <label className="col-lg-3 col-form-label required fw-semibold fs-6">Question {index + 1}</label>
                          {/*end::Label*/}
                          {/*begin::Col*/}
                          <div className="col-lg-7">
                            {/*begin::Row*/}
                            <div className="row">
                              {/*begin::Col*/}
                              <div className="col-lg-12 fv-row fv-plugins-icon-container">
                              <textarea
                              placeholder='Enter Question'
                              onChange={(e) => {
                                 formik.setFieldValue(`questions[${index}]`,e.target.value);
                              }
                              }
                              
                              value={friend.question}
                              // {...formik.getFieldProps(`questions[${index}]`)}
                              rows={4}
                              name={`questions[${index}]`}
                              className={clsx(
                                'form-control form-control-sm  mb-3 mb-lg-0',

                              )}
                              autoComplete='off'
                              disabled={formik.isSubmitting || isUserLoading}
                            ></textarea>
                            {/* {`formik.touched.questions[${index}]` && `formik.errors.questions[${index}]` && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{`Enter question ${index + 1} `}</span>
                                </div>
                              </div>
                            )} */}
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" /></div>
                              {/*end::Col*/}
                            </div>
                            {/*end::Row*/}
                          </div>
                          <div className="col-lg-2">
                            {/*begin::Row*/}
                            <div className="row">
                              <button className='btn btn-danger btn-sm' type="button" onClick={() => arrayHelpers.remove(index)}>
                                Delete
                              </button>
                            </div>
                            {/*end::Row*/}
                          </div>
                          {/*end::Col*/}
                        </div>
                       
                      </div>
                    )})}
                    <button
                      type="button"
                      className='btn col-2 btn-primary btn-sm'
                      onClick={() => arrayHelpers.push({ question:'' })}
                    >
                      Add
                    </button>


                  </>
                )
              }}
            </FieldArray>
          </div>
          {/* end::Scroll */}

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting || isUserLoading}
            >
              Discard
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Submit</span>
              {(formik.isSubmitting || isUserLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </form>
        {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
      </FormikProvider>
    </>
  )
}

export { UserEditModalForm }
