/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react'
import { isNotEmpty } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import axios from 'axios'
import { toast } from 'react-toastify'
import { POST_BRAND_NAME } from '../../../../../modules/auth/core/_requests'

type Props = {
  isUserLoading: boolean
  user: any
}

 
const UserEditModalForm: FC<Props> = ({ user }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()



  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const [name, setName] = useState(user.name ? user.name : '');
  const [logo, setLogo] = useState<File | null>(null);
  const [image, setImage] = useState(user.logo ? user.logo : null);
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setLogo(event.target.files[0]);
    }
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append('name', name);
      if (logo) {
        formData.append('logo', logo);
      }

      if (isNotEmpty(user.id)) {
        formData.append('id', `${user.id}`);
      }

      const response = await axios.post(`${POST_BRAND_NAME}`, formData);

      if (response.data.status == 1) {
        toast.success(response.data.message);

        setName('');
        setLogo(null);
        cancel(true)
      }
      else {
        toast.error(response.data.message);
      }

    } catch (ex) {
      console.error(ex)
    }

  };


  return (
    <>
      <form onSubmit={handleSubmit} className='row justify-content-center' action="">

        <label className='col-7 mb-5' htmlFor="">
          Car Brand
          <input type="text" className='form-control' value={name} onChange={handleNameChange} />
        </label>
        <label htmlFor="" className='col-7 mb-5'>
          Upload Car Logo
          <input type="file" className='form-control mb-5' onChange={handleLogoChange} />
          {image && (
            <img src={image} height={100} alt="" />
          )}

        </label>
        <button className='btn btn-primary col-7 mb-5' type='submit'>Submit</button>
      </form>

      {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
    </>
  )
}

export { UserEditModalForm }
