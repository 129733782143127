import {FC} from 'react'
import { Order } from '../core/_models'

type Props = {
  isUserLoading: boolean
  user: Order
}

const UserEditModalForm: FC<Props> = ({user, isUserLoading}) => {

  return (
    <>
    <h1>{user.get_brand?.name}</h1>
    </>
  )
}

export {UserEditModalForm}
