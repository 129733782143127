import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'


import { UsersListWrapper } from './orders-management/users-list/UsersList'
import ViewOrder from './ViewOrder'
import { useAuth } from '../../modules/auth'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Order Listing',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const vieworder: Array<PageLink> = [
  {
    title: 'Orders',
    path: '/admin/manage-orders/orders',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OrdersAll = () => {
  const {currentUser} = useAuth();
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='orders'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Order Listing</PageTitle>
              {currentUser?.permissions.find((item: any) => item.name === "view_orders") ?
              <UsersListWrapper /> : <h1>You Are Not Allowed</h1> }
              
            </>
          }
        />
        <Route
          path='view-order/:id'
          element={
            <>
              <PageTitle breadcrumbs={vieworder}>View Order</PageTitle>
              {/* <UsersListWrapper /> */}
              {currentUser?.permissions.find((item: any) => item.name === "view_orders") ?
              <ViewOrder /> : <h1>You Are Not Allowed</h1> }
              {/* <TablesWidget10 className={''} /> */}
            </>
          }
        />
      </Route>
      
      <Route index element={<Navigate to='/admin/manage-orders/orders' />} />
    </Routes>
  )
}

export default OrdersAll
