/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse } from './core/QueryResponseProvider'
import { UserEditModal } from './user-edit-modal/UserEditModal'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import axios from 'axios'
import { useAuth } from '../../../../modules/auth'
import { ID, QUERIES } from '../../../../../_metronic/helpers'
import { deleteUser } from './core/_requests'

const UsersList = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView();
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  // const { refetch } = useQueryResponse()
  const [rolesloaded, setRolesloaded] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);

  const getRoles = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-roles`);

    (currentUser?.user_type === 'super_admin') ? setRoles(response.data) : setRoles(response.data.filter((el: any) => el.name !== 'admin' && el.name !== 'super_admin'));

  }

  useEffect(() => {
    if (rolesloaded) {
      getRoles();
      setRolesloaded(false);
    }

  }, [rolesloaded]);

  // const cancel = (withRefresh?: boolean) => {
  //   if (withRefresh) {
  //     refetch()
  //   }
  //   setItemIdForUpdate(undefined)
  // }


  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = (id: ID) => {
    setItemIdForUpdate(id)
  }
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  const deleteItem = useMutation((id: ID) => deleteUser(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
  })
  const { currentUser } = useAuth();

  return (
    <>

      <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
           <div className="col-md-4">
          {/*begin::Card*/}
          <div className="card h-md-100">
            {/*begin::Card body*/}
            <div className="card-body d-flex flex-center">
              {/*begin::Button*/}
              <button type="button" onClick={openAddUserModal} className="btn btn-clear d-flex flex-column flex-center">
                {/*begin::Illustration*/}

                {/*end::Illustration*/}
                {/*begin::Label*/}
                <div className="fw-bold fs-3 text-gray-600 text-hover-primary">Add New Role</div>
                {/*end::Label*/}
              </button>
              {/*begin::Button*/}
            </div>
            {/*begin::Card body*/}
          </div>
          {/*begin::Card*/}
        </div>
        {roles && roles.map((roleItem: any, index: any) => {

          return (
            <div key={index} className="col-md-4">
              <div className="card card-flush h-md-100">
                <div className="card-header">
                  <div className="card-title">
                    <h2>{roleItem.name.replace(/\b\w/g, (c: string) => c.toUpperCase())}</h2>
                  </div>

                </div>


                <div className="card-body pt-1">

                  <div className="fw-bold text-gray-600 mb-5">Permissions</div>


                  <div className="d-flex flex-column text-gray-600">
                    {roleItem.permissions && roleItem.permissions.slice(0, 4).map((permissionItem: any, indx: number) => (
                      <div key={indx} className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> {permissionItem.name}</div>
                    ))}
                    {roleItem.permissions.length > 4 && (
                      <div className="d-flex align-items-center py-2"><span className="bullet bg-primary me-3" /> <em>and {roleItem.permissions.length - 4} more...</em></div>
                    )}

                  </div>

                </div>


                <div className="card-footer flex-wrap pt-0">
                  <button onClick={() => openEditModal(roleItem.id)} type="button" className="btn btn-light btn-active-light-primary my-1 me-2" >Edit Role</button>
                  <a onClick={async () => window.confirm('Are you sure to delete?') ? await deleteItem.mutateAsync(roleItem.id) : null} href="#" className="btn btn-light-danger btn-active-danger my-1 ">Delete Role</a>
                </div>

              </div>

            </div>
          )

        })}
     

      </div>


      {itemIdForUpdate !== undefined && <UserEditModal />}
    </>
  )
}

const ModelListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { ModelListWrapper }
