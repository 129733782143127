/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik';
import { Route, Routes, Outlet, Navigate, useNavigate, useParams } from 'react-router-dom'
import { PageLink, PageTitle } from '../../_metronic/layout/core'
import * as Yup from "yup";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { GET_BRAND_NAME, GET_MODEL_NAME, GET_MODEL_NAME_BY_BRAND, GET_SERVICES, GET_VARIENT_NAME_BY_MODEL } from '../modules/auth/core/_requests';
import React from 'react'
import Select from 'react-select'
import { ListViewProvider } from './leads/leads-management/users-list/core/ListViewProvider';
import { QueryRequestProvider } from './leads/leads-management/users-list/core/QueryRequestProvider';
import { QueryResponseProvider, useQueryResponse } from './leads/leads-management/users-list/core/QueryResponseProvider';

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

interface Lead {

  id: string
  service: string
  name: string
  brand: string
  email: string
  contact: string
  model: string
  lead_type: string
  cr: string
  varient: string
  registration_no: string
  odometer_reading: string
  address: string
  source: string
  garage: string
  car_type: string
  created_at: string
  updated_at: string
  created_by: string
  updated_by: string
  brand_name: string
  model_name: string
  varient_name: string

}

const LeadUpdate = () => {

  const params = useParams();
  const [brands, setBrands] = useState<any>([]);
  const [lead, setLead] = useState<Lead>();
  const { refetch } = useQueryResponse();

  const fetchBrands = async () => {
    try {
      const response = await axios.get(GET_BRAND_NAME)
      setBrands(response.data);

    } catch (error) {
      console.error(error)
    }
  };
  const fetcholddata = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-lead-by-Id/${params.id}`)
      setLead(response.data)
      fetchModels(response.data.brand);
      fetchVarient(response.data.model);
    } catch (error) {
      console.error(error)
    }
  };


  const [cr, setcr] = useState<any>([]);

  const fetchcr = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-cr`);
      setcr(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [garage, setgarage] = useState<any>([]);

  const fetchgarage = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/get-garage`);
      setgarage(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const [models, setModels] = useState<any>([]);

  const fetchModels = async (id: any) => {
    try {
      const response = await axios.get(`${GET_MODEL_NAME_BY_BRAND}/${id}`);
      setModels(response.data);


    } catch (error) {
      console.error(error);
    }
  };

  const [varient, setVarient] = useState<any>([]);

  const fetchVarient = async (id: any) => {
    try {
      const response = await axios.get(`${GET_VARIENT_NAME_BY_MODEL}/${id}`);
      setVarient(response.data);
    } catch (error) {
      console.error(error);
    }
  };





  const navigate = useNavigate();
  const formik = useFormik({

    initialValues: { ...lead, lead_id: params.id },
    enableReinitialize: true,
    validationSchema: Yup.object({

      name: Yup.string()
        .required("Enter name"),
      contact: Yup.string()
        .matches(/^[6-9][0-9]{9}$/, 'Invailid contact')
        .required('Enter contact'),
      // registration_no: Yup.string()
      //   .required("Enter registration no"),
      // odometer_reading: Yup.string()
      //   .required("Enter odometer reading"),
      address: Yup.string()
        .required("Enter address"),
      // service: Yup.string()
      //   .required("Select service"),
      // source: Yup.string()
      //   .required("Select source"),
      // garage: Yup.string()
      //   .required("Select garage"),
      // cr: Yup.string()
      //   .required("Select cr"),
      // lead_type: Yup.string()
      //   .required("Select lead type"),
      // model: Yup.string()
      //   .required("Select model"),
      // price_type: Yup.string()
      //   .required("Select price type"),
      // car_type: Yup.string()
      //   .required("Enter car type"),
      // fuel_type: Yup.string()
      //   .required("Enter car fuel type"),
      // percentage: Yup.string()
      //   .required("Enter car percentage"),

    }),
    onSubmit: async (values) => {

      const token = localStorage.getItem("token");

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/lead-store`,
        { ...values },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.status == 1) {
        toast.success(response.data.message);
        navigate('/admin/lead-management/leads');
      } else {
        toast.error(response.data.message);
      }
    },
  });


  const lead_types = [
    { value: 'general', label: 'Genral' },
    { value: 'insurance', label: 'Insurance' },
    { value: 'commercial', label: 'Commercial' },
    { value: 'accessories_retail', label: 'Accessories Retail' },
    { value: 'sos', label: 'SOS' },
    { value: 'luxury', label: 'Luxury' },
  ];

  const crvalues = [
    { value: 'anurag_shrivastava', label: 'Anurag Shrivastava' },
    { value: 'rahul_dubey', label: 'Rahul Dubey' }
  ];

  const sourceoptions = [
    { value: 'refferal_client', label: 'Refferal Client' },
    { value: 'organic', label: 'Organic' },
    { value: 'sms', label: 'SMS' },
    { value: 'repeat', label: 'Repeat' },
    { value: 'b2b', label: 'B2B' },
    { value: 'insurance_agent', label: 'Insurance Agent' },
    { value: 'accessories', label: 'Accessories' },
    { value: 'sos', label: 'SOS' },
    { value: 'flagship', label: 'Flagship' },
    { value: 'agent_app', label: 'Agent App' },
  ];
  const Modelref: any = useRef(null);
  const Varientref: any = useRef(null);
  const Typeref: any = useRef(null);
  const Fuelref: any = useRef(null);


  useEffect(() => {

    fetcholddata();
    fetchBrands();
    fetchgarage();
    fetchcr();

  }, []);

  return (
    <>

      <PageTitle breadcrumbs={usersBreadcrumbs}>Update Lead</PageTitle>
      {/* <UsersListWrapper /> */}
      <div className="card">
        <form onSubmit={formik.handleSubmit}>
          <div className="card-header p-5">
            <h3 className='p-0 m-0'>Update Lead</h3>
          </div>

          <div className="card-body">
            <div>
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Customer details <span className='badge ms-3 badge-danger'> 1 </span></button>
                  <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Car details <span className='badge ms-3 badge-warning'> 2 </span></button>
                  <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Lead Details <span className='badge ms-3 badge-success'> 3 </span></button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div className='row'>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="contact">Contact</label>
                        <input
                          className="form-control"
                          id="contact"
                          type="text"
                          placeholder="Enter contact"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact}
                        />
                        {formik.touched.contact && formik.errors.contact ? (
                          <span className="text-danger">
                            {formik.errors.contact}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                          className="form-control"
                          id="name"
                          type="text"
                          placeholder="Enter name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <span className="text-danger">
                            {formik.errors.name}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          className="form-control"
                          id="email"
                          type="text"
                          placeholder="Enter email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <span className="text-danger">
                            {formik.errors.email}
                          </span>
                        ) : null}
                      </div>
                    </div>


                    <div className="col-md-12 mt-5">
                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <textarea
                          className="form-control"
                          id="address"
                          placeholder="Enter Address"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                        ></textarea>
                        {formik.touched.address && formik.errors.address ? (
                          <span className='text-danger' >
                            {formik.errors.address}
                          </span>
                        ) : null}
                      </div>
                    </div>

                  </div>

                </div>
                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <div className='row'>
                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="name">Brand</label>
                        {formik.values.brand ?
                          <Select
                            isClearable={true}
                            onChange={(selectedOption: any) => {
                              Modelref.current.clearValue();
                              formik.setFieldValue('brand', selectedOption?.value1);
                              formik.setFieldValue('model', '');
                              fetchModels(selectedOption?.value1);
                            }
                            }
                            options={
                              brands && brands.map((itemBrand: any) => (
                                { value1: itemBrand?.id, label: itemBrand?.name }
                              ))
                            }
                            value={{ value1: formik.values.brand, label: brands.find((x: any) => x.id === formik.values.brand)?.name }}
                          />
                          :
                          <Select
                            isClearable={true}

                            onChange={(selectedOption: any) => {
                              Modelref.current.clearValue();
                              formik.setFieldValue('brand', selectedOption?.value1);
                              formik.setFieldValue('model', '');
                              fetchModels(selectedOption?.value1);
                            }
                            }
                            options={
                              brands && brands.map((itemBrand: any) => (
                                { value1: itemBrand?.id, label: itemBrand?.name }
                              ))
                            }

                          />

                        }

                        {formik.touched.brand && formik.errors.brand ? (
                          <span className="text-danger">
                            {formik.errors.brand}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="model">Model</label>
                        {formik.values.model ? <Select
                          ref={Modelref}
                          isClearable={true}
                          onChange={(selectedOption) => {
                            formik.setFieldValue('model', selectedOption?.value);
                          }
                          }
                          options={
                            models && models.map((itemModel: any) => (
                              { value: itemModel?.id, label: itemModel?.model }
                            ))
                          }
                          value={{ value: formik.values.model, label: models.find((x: any) => x.id === formik.values.model)?.model }}
                        /> : <Select
                          ref={Modelref}
                          isClearable={true}
                          onChange={(selectedOption: any) => {
                            formik.setFieldValue('model', selectedOption?.value);

                            fetchVarient(selectedOption?.value);
                          }
                          }
                          options={
                            models && models.map((itemModel: any) => (
                              { value: itemModel?.id, label: itemModel?.model }
                            ))
                          }

                        />}

                        {formik.touched.model && formik.errors.model ? (
                          <span className="text-danger">
                            {formik.errors.model}
                          </span>
                        ) : null}
                      </div>
                    </div>



                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="registration_no">Registration No.</label>
                        <input
                          className="form-control"
                          id="registration_no"
                          type="text"
                          placeholder="Enter Registration No"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.registration_no}
                        />
                        {formik.touched.registration_no && formik.errors.registration_no ? (
                          <span className='text-danger' >
                            {formik.errors.registration_no}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="odometer_reading">Odometer Reading</label>
                        <input
                          className="form-control"
                          id="odometer_reading"
                          type="text"
                          placeholder="Enter Odometer Reading"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.odometer_reading}
                        />
                        {formik.touched.odometer_reading && formik.errors.odometer_reading ? (
                          <span className='text-danger' >
                            {formik.errors.odometer_reading}
                          </span>
                        ) : null}
                      </div>
                    </div>

                  </div>


                </div>
                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">


                  <div className='row mb-5'>
                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="lead_type">Lead Type</label>
                        {formik.values.lead_type ? <Select
                          isClearable={true}
                          onChange={selectedOption =>
                            formik.setFieldValue('lead_type', selectedOption?.value)
                          }
                          options={lead_types}
                          value={{ value: formik.values.lead_type, label: lead_types.find(x => x.value === formik.values.lead_type)?.label }}
                        /> : <Select
                          isClearable={true}
                          onChange={selectedOption =>
                            formik.setFieldValue('lead_type', selectedOption?.value)
                          }
                          options={lead_types}

                        />
                        }

                        {formik.touched.lead_type && formik.errors.lead_type ? (
                          <span className="text-danger">
                            {formik.errors.lead_type}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="cr">CR</label>
                        {formik.values.cr ? <Select
                          isClearable={true}
                          onChange={(selectedOption: any) => {
                            formik.setFieldValue('cr', selectedOption?.value);
                          }
                          }
                          options={cr && cr.map((crItem: any) => (
                            { value: crItem?.id, label: crItem?.name }
                          ))}
                          value={{ value: formik.values.cr, label: cr.find((el: any) => el.id === formik.values.cr)?.name }}
                        /> : <Select
                          isClearable={true}
                          onChange={(selectedOption: any) => {
                            formik.setFieldValue('cr', selectedOption?.value);
                          }
                          }
                          options={cr && cr.map((crItem: any) => (
                            { value: crItem?.id, label: crItem?.name }
                          ))
                          } />}

                        {formik.touched.cr && formik.errors.cr ? (
                          <span className="text-danger">
                            {formik.errors.cr}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="garage">Garage</label>
                        {formik.values.garage ? <Select
                          isClearable={true}
                          onChange={(selectedOption: any) => {
                            formik.setFieldValue('garage', selectedOption?.value);
                          }
                          }
                          options={garage && garage.map((garageItem: any) => (
                            { value: garageItem?.id, label: garageItem?.name }
                          ))}
                          value={{ value: formik.values.garage, label: garage.find((el: any) => el.id === formik.values.garage)?.name }}
                        /> :
                          <Select

                            isClearable={true}
                            onChange={(selectedOption: any) => {
                              formik.setFieldValue('garage', selectedOption?.value);
                            }
                            }
                            options={
                              garage && garage.map((garageItem: any) => (
                                { value: garageItem?.id, label: garageItem?.name }
                              ))
                            } />}
                        {formik.touched.garage && formik.errors.garage ? (
                          <span className="text-danger">
                            {formik.errors.garage}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* </div> */}
                    {/* <div className="row"> */}
                    <div className="col-md-4 mt-5">
                      <div className="form-group">
                        <label htmlFor="source">Source</label>
                        {formik.values.source ? <Select

                          onChange={(selectedOption) => {
                            formik.setFieldValue('source', selectedOption?.value);
                          }
                          }
                          options={sourceoptions}
                          value={{ value: formik.values.source, label: sourceoptions.find((el) => el.value === formik.values.source)?.label }}
                        /> :
                          <Select

                            onChange={(selectedOption) => {
                              formik.setFieldValue('source', selectedOption?.value);
                            }
                            }
                            options={sourceoptions} />}

                        {formik.touched.source && formik.errors.source ? (
                          <span className="text-danger">
                            {formik.errors.source}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </div>


          </div>

        </form>
      </div>
      {/* <ToastContainer /> */}
    </>
  )
}


export { LeadUpdate }

