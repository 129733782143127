

const UsersListToolbar = () => {
  // const {response} = useQueryResponse();
  // const data = response?.data ? response?.data.map((item : any,index:number) => {
  //  return {
  //     Sr: `${index+1}`,
  //     Name:item.name,
  //     Email:item.email,
  //     Contact: item.contact,
  //     Car: `${item.brand_name}, ${item.model_name}, ${item.varient_name}`,
  //     "Registration No." :item.registration_no,
  //     "Odometer Reading" : item.odometer_reading,
  //     "Lead Type" : item.lead_type,
  //     Status:item.status === 1 ? 'Initiated' : item.status === 2 ? 'Assigned' : item.status === 3 ? 'Converted' : 'Cancelled',
  //     Remark: item.remark,
  //   }
  // }) : [];
  // const downloadxls = (e: any, data: any) => {
    
  //   e.preventDefault();
  //   const ws = XLSX.utils.json_to_sheet(data);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  //   /* generate XLSX file and send to client */
  //   XLSX.writeFile(wb, "sheetjs.xlsx");
  // };

 
  // const openAddUserModal = () => {
  //   setItemIdForUpdate(null)
  // }
  // const {currentUser} = useAuth();
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <UsersListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3' onClick={(e) => downloadxls(e,data)}>
    <KTIcon iconName='exit-up' className='fs-2' /> Export export
    </button> */}
      {/* end::Export */}

      {/* begin::Add user */}
    
    </div>
  )
}

export {UsersListToolbar}
